import { Divider } from "@mui/material";
import moment from "moment";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { botswanapost, kazang, orangeMoney } from "../../assets/images";
import style from "../components.module.css";

const Summary = ({
  title,
  referenceNumber,
  isSummary = false,
  policyHolder,
  coverType,
  extendedFamilies,
  policyHolderSurname,
  premiumAmount,
  policyType,
  familyPolicy,
  coverAmount,
  extendedCover,
  riderBenefit,
  isSuccess = false,
  isFail = false,
  isPass = false
}) => {
  const QUQRiskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const QUQPrevRiskAttributes = useSelector(
    (state) =>
      state.prevState?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const getAttributes = (value) => {
    return (isSuccess ? QUQPrevRiskAttributes : QUQRiskAttributes)?.find(
      (x) => x?.name === value
    );
  };

  const dateOfBirth = getAttributes("Date of Birth");

  return (
    <div className={style.summary_container}>
      {isFail ? <Row className="justify-content-center">
                <Col lg={12}>
                    <h3 className="primary-color f-700 text-center">Oops!</h3>
                    <p className="primary-color f-700 text-center">We seem to struggle with the payent transaction.</p>
                </Col>
            </Row> : isPass ? <Row className="justify-content-center">
                <Col lg={12}>
                    <h3 className="primary-color f-700 text-center">All Done!</h3>
                    {/* <p className="primary-color f-700 text-center">Payment successful</p> */}
                </Col>
            </Row> : <></>}

      <Row className="justify-content-between mt-4">
        <Col lg={6} xs={6}>
          <p className="primary-color f-700 text-left">Reference number :</p>
        </Col>
        <Col lg={6} xs={6}>
          <p className="primary-color f-700 text-right w-100">
            {referenceNumber}
          </p>
        </Col>
      </Row>

      <Row className="justify-content-between mt-0">
        <Col lg={6} xs={6}>
          <p className="primary-color f-700 text-left">Applicant :</p>
        </Col>
        <Col lg={6} xs={6}>
          <p className="primary-color f-700 text-right w-100">
            {policyHolder} {policyHolderSurname}
          </p>
        </Col>
      </Row>

      <Row className="justify-content-between mt-0">
        <Col lg={6} xs={6}>
          <p className="primary-color f-700 text-left">
            {/* {moment().diff(dateOfBirth?.value?.replaceAll("/", "-"), "years") <
            60
              ? `${getAttributes("Cover Option")?.value} Cover amount :`
              : `${getAttributes("Cover Option 60 Plus")?.value ==='Individual' ? 'Member only' : 'Member and family'}  policy :`} */}
            Family policy :
          </p>
        </Col>
        <Col lg={6} xs={6}>
          {/* <p className="primary-color f-700 text-right w-100">{coverType === 0 ? 'Basic' : 'Comprehensive'}</p> */}
          <p className="primary-color f-700 text-right w-100">{familyPolicy}</p>
        </Col>
      </Row>

      {/* {moment().diff(dateOfBirth?.value?.replaceAll("/", "-"), "years") >
            60 && <Row className="justify-content-between mt-0">
        <Col lg={6}>
          <p className="primary-color f-700 text-left">Cover Amount :</p>
        </Col>
        <Col lg={6}>
          <p className="primary-color f-700 text-right w-100">{coverAmount}</p>
        </Col>
      </Row>} */}

      {extendedCover &&
        extendedFamilies?.map((x, index) => (
          <Row className="justify-content-between mt-0">
            <Col lg={6} xs={6}>
              <p className="primary-color f-700 text-left">
                Extended family cover {index + 1} :
              </p>
            </Col>
            <Col lg={6} xs={6}>
              <p className="primary-color f-700 text-right w-100">{x.cover}</p>
            </Col>
          </Row>
        ))}

      <Divider />

      <Row className="justify-content-between mt-4">
        <Col lg={6} xs={6}>
          <p className="primary-color f-700 text-left">Monthly Premium :</p>
        </Col>
        <Col lg={6} xs={6}>
          <p className="primary-color f-700 text-right w-100">
            {premiumAmount}
          </p>
        </Col>
      </Row>

      <Divider />

      <Row className="justify-content-start mt-3">
        <Col lg={12}>
          <p className="primary-color f-700 text-left m-0 w-100">
            {/* Support contact details: */}
            Contact the Sanlam Customer Care Centre at:
          </p>
          <ul> 
            <li className="primary-color f-500 text-left m-0 w-100">
              {/* (061)294-7440 */}
              <p className="justify-content-start mt-3" style={{marginBottom:"0px"}}><span className="primary-color f-500 text-left m-0 w-100">Telephone:</span>&nbsp;  <span className="primary-color f-700 text-left m-0 w-100">(061)294-7440</span></p>
            </li>
            <li className="primary-color f-500 text-left m-0 w-100">
            <p><span className="primary-color f-500 text-left m-0 w-100">Email:</span>&nbsp;  <span className="primary-color f-700 text-left m-0 w-100">namibia.client@sanlam.com.na</span></p> 
              {/* namibia.client@sanlam.com.na */}
            </li>
          </ul>
        </Col>
      </Row>

      <Divider />

      <Row className="justify-content-start mt-3">
        <Col lg={12}>
          <p className="primary-color f-700 text-left m-0 w-100">
            {/* Support contact details: */}
            Letshego contact details:
          </p>
          <ul> 
            <li className="primary-color f-500 text-left m-0 w-100">
              {/* (061)294-7440 */}
              <p className="justify-content-start mt-3" style={{marginBottom:"0px"}}><span className="primary-color f-500 text-left m-0 w-100">Telephone:</span>&nbsp;  <span className="primary-color f-700 text-left m-0 w-100">(061)202-3500</span></p>
            </li>
            <li className="primary-color f-500 text-left m-0 w-100">
            <p><span className="primary-color f-500 text-left m-0 w-100">Email:</span>&nbsp;  <span className="primary-color f-700 text-left m-0 w-100">namcustomer@letshego.com</span></p> 
              {/* namibia.client@sanlam.com.na */}
            </li>
          </ul>
        </Col>
      </Row>

      {!isSummary && (
        <>
          <Divider />
          <Row className="justify-content-start mt-3">
            <p className="primary-color f-700 text-center">
              Use the navigation bar on the left to return to the home page
            </p>
          </Row>
        </>
      )}
    </div>
  );
};

export default Summary;
