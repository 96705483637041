import React, { useCallback, useEffect, useState } from "react";
import { DateInput, NumberInput, Select, TwoButtons } from "../../../components";
import NavigationButtons from "../../../components/NavigationButtons";
import style from "../Form.module.css";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../../utils/useWindowDimension";
import classNames from "classnames";
import {
  executeCalculator,
  updateCustomAttributes,
  updatedAttributes,
} from "../../../store/actions/products";
import { validate } from "validate.js";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import Loader from "../../../components/Loader";
import moment from "moment";
import { Divider } from "@mui/material";
import ReactTooltip from "react-tooltip";
import { table } from "../../../assets/images";
import { formatCoverAmount, trackUserEvents } from "../../../utils/utils";

const Step2 = ({ handleNext, handleBack }) => {
  const MAMRiskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "MAM")?.attributes
  );

  const getAttributes = (value) => {
    return MAMRiskAttributes.find((x) => x.name === value);
  };

  const riskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "MAM")?.attributes
  );

  const customAttributes = useSelector(
    (state) => state?.products.customAttribues
  );

  const coverOption = useSelector((state) => state?.products?.customAttribues?.coverOption)
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const { width } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(false);

  const formatCoverAmount1 = (value) => {
    let a = value;
    let b = a.length === 6 ? a.slice(0, 3) :  a.slice(0, 4);
    let c = a.length === 6 ? a.slice(3,6) : a.slice(4, 7);
    return b + " "+ c
  }

  const getConstraints = () => {
    var val = {};
    if (getAttributes('Cover Option')?.value === 'Individual') {
      val = {
       
      }
     
      
    } else {
      if((coverOption === "Single Parent") || (coverOption === "Family")){
        val = {
          ...val,
          "Child Cover": {
            presence: {
              allowEmpty: false,
            },
          },
        };
      }
      if((coverOption === "Just Me") || (coverOption === "Single Parent") || (coverOption === "Family")){
        val = {
          ...val,
          "Beneficiary Cover": {
            presence: {
              allowEmpty: false,
            },
          },
          [getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.name]: {
            presence: {
              allowEmpty: false,
            },
          },
        };
      }
        if (customAttributes.child_cover === 0) {
          val = {
            ...val,
            "Number of Children": {
              presence: {
                allowEmpty: false,
                message: "^ Number of children can't be blank"
              },
              numericality: {
                onlyInteger: true,
                greaterThan: 0,
                lessThanOrEqualTo: 4,
              },
            },
          };
        }
        if(getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.value === 'YES'){
          extendedFamily?.map((ex, i) => {
            val = {
              ...val,
              [`Family Member ${i+1} cover`]: {
                presence: {
                  allowEmpty: false,
                  message: "^ Cover amount can’t be blank"
                }
              },
              [`Family Member ${i+1} date of birth`]: {
                presence: {
                  allowEmpty: false,
                  message: "^ Date of birth can’t be blank"
                }
              }
            }
          })
        }
      
    }
    return val;
  };

  const reduxState = useSelector((state) => state);
  const state = useSelector((state) => state?.products?.riskAttributes);

  const productOptions = useSelector(
    (state) => state?.products?.executeCalculator?.productOptions?.[0]
  );

  const calculatorResponse = useSelector(
    (state) => state?.products?.executeCalculator
  );

  const premiumAmountCalc = () => {
    return calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating?.total;
  };

  useEffect(() => {
    dispatch(
      updateCustomAttributes({
        type: "PREMIUM",
        payload: premiumAmountCalc(),
      })
    );
  }, []);

  const handleChangeCustom = (val, name, code) => {
    let obj = { ...customAttributes, [name]: val };

    dispatch(
      updateCustomAttributes({
        type: "CUSTOM_ATTRIBUTES",
        payload: obj,
      })
    );
  };

  const getRows = (rows) => {
    var arr = [];
    for (var i = 0; i < rows; i++) {
      arr.push(i + 1);
    }
    return arr;
  };

  const dateOfBirth = getAttributes("Date of Birth");
  const coverAmount = getAttributes("Cover Amount");
  const childAmount = parseInt(coverAmount?.value?.replace('N$','')?.replace(' ','')) / 2
  const bandsState = useSelector((state) => state?.products?.bands);

  const [bands, setBands] = useState(
    bandsState
      ? bandsState
      : [
          {
            name: "Aged from 1-15",
            value: null,
          },
          {
            name: "Aged from 16-25",
            value: null,
          },
          {
            name: "Aged from 26-35",
            value: null,
          },
          {
            name: "Aged from 36-45",
            value: null,
          },
          {
            name: "Aged from 46-55",
            value: null,
          },
          {
            name: "Aged from 56-65",
            value: null,
          },
          {
            name: "Aged from 66-75",
            value: null,
          },
          {
            name: "Aged from 76-85",
            value: null,
          },
        ]
  );

  const over60Option = () => {
    if(getAttributes('Cover Option')?.value !== 'Individual'){
      return false;
    }else{
      return true;
    }
  };

  const totalParents = () => {
    var total = riskAttributes?.find((x) => x.name === "Cover Option")?.value;
    return total?.toLowerCase()?.includes("two")
      ? 2
      : total?.toLowerCase()?.includes("four")
      ? 4
      : 0;
  };

  const checkOverOption = () => {
    if (moment().diff(dateOfBirth?.value?.replaceAll("/", "-"), "years") < 60) {
      return true;
    } else {
      if (getAttributes("Cover Option 60 Plus")?.value === "Individual") {
        return false;
      } else {
        return true;
      }
    }
  };

  const getValuations = (name) => {
    return productOptions?.valuations?.values
      .find((x) => x.name === name)
      ?.value?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getExtended = () => {
    var arr = [];
    extendedFamilies?.map((item, index) => {
      arr.push( {
        attributes: [
          ...state
            .find((x) => x.code === 'EXF')
            ?.attributes.map((y, i) => ({
              dataType: y.dataType,
              name: y.name,
              instanceId: y.instanceId,
              value:
                y.name === "Extended Family DOB"
                  ? item?.dob ?? 0
                : y.name === 'Extended Family Cover Amount' ?
                  item?.cover
                :y.value ?? null,
            })),
        ],
        riskReference: state.find((x) => x.code === 'EXF')
          ?.instanceId,
        code: state.find((x) => x.code === 'EXF')?.code,
      },)
    });
    return arr;
  }

  const checkOption = (value) => {
    if(customAttributes?.spouse_cover === 0){
      return 'Family'
    }else{
      return value
    }
  }

  const handleSubmit = async () => {
    var values = {};
    values = {
      ...values,
      "Child Cover": customAttributes?.child_cover,
      "Beneficiary Cover": customAttributes?.bene_cover,
      [getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.name]: getAttributes(
        ATTRIBUTES.EXTENDED_FAMILY
      )?.value,
      "Number of Children": Number(customAttributes?.numberOfChildren) ?? null,
      [getAttributes("Extended Family Cover Amount")?.name]: getAttributes(
        "Extended Family Cover Amount"
      )?.value,
      "Number of Extended Family": bands
        ?.filter((y) => y.value)
        ?.reduce((a, b) => a + b.value, 0),
    };

    extendedFamily?.map((ex, inde) => {
      values = {
        ...values,
        [`Family Member ${inde+1} cover`]: ex.cover,
        [`Family Member ${inde+1} date of birth`]: ex.dob
      }
    })

    const errors = validate(values, getConstraints());
    if (errors || errors !== undefined) {
      var errorArray = Object.keys(errors);
      document.getElementById(errorArray[0])?.scrollIntoView({
        behavior: "smooth",
      });
      return setErrors(errors);
    }

    setIsLoading(true);
    try {
      const res = await dispatch(
        executeCalculator({
          payload: {
            factors: [],
            productTypeReference:
              reduxState?.products?.types?.types[0]?.instanceId,
            risks: [
              {
                attributes: [
                  ...state
                    .find((x) => x.code === RISKS.MAM)
                    ?.attributes.map((y) => ({
                      dataType: y.dataType,
                      name: y.name,
                      instanceId: y.instanceId,
                      value:
                        y.name === "Number of Children"
                          ? customAttributes?.numberOfChildren ?? 0
                          : y.name === "Date of Birth"
                          ? customAttributes?.dob
                          : y.name === "Cover Option"
                          ? customAttributes?.coverOption === "Just Me"
                            ? "Individual"
                            : customAttributes?.coverOption === "Single Parent"
                            ? "Individual plus 4 children"
                            : "Family"
                          : y.name === "Cover Amount"
                          ? getAttributes("Cover Amount")?.value
                          : y.value ?? null,
                    })),
                ],
                riskReference: state.find((x) => x.code === RISKS.MAM)
                  ?.instanceId,
                code: state.find((x) => x.code === RISKS.MAM)?.code,
              },

              ...getExtended(),
            ],
          },
        })
      );
      setIsLoading(false);
      //trackUserEvents
      trackUserEvents("na_indicative_quote_next_cta", {
        Child: customAttributes?.child_cover === 0 ? "Yes" : "No",
        Extended: customAttributes?.extendedCover === 0 ? "Yes" : "No",
        NumberOfChildren: customAttributes?.numberOfChildren ?? 0,
        NumberofExtendedFamily: extendedFamilies?.length ?? 0,
        Beneficiary: customAttributes?.bene_cover === 0 ? "Yes" : "No",
      });
      handleNext(); 
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

   const handleBackTrack = () => {
     //trackUserEvents
     trackUserEvents("na_indicative_quote_back_cta", {});
     handleBack();
   };

  const extendedFamilies = useSelector((state) => state?.products?.extendedFamilies);
  const [extendedFamily, setExtendedFamily] = useState([
   {
    cover: null,
    dob: null
   }
  ]);


  useEffect(() =>  {
    if(extendedFamilies?.length){
      setExtendedFamily(extendedFamilies);
    }
  },[])


  const parseString = (string, name) => {
      const arr = string.split(",");
      let obj = {};
      arr.forEach((a) => { const val = a.split(":");obj[val[0]] = val[1];})
      return obj;
  }

  const calculateMinDate = (type) => {
    const date = new Date();
    var cover = type?.replace('N$','');
    cover = cover?.replace(' ','')
    if(parseInt(cover) === 40000){
      date.setFullYear(date.getFullYear() - 71);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    }else{
      date.setFullYear(date.getFullYear() - 85);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    }
  };

  const calculateMaxDate = (type) => {
    const date = new Date();
    var cover = type?.replace('N$','');
    cover = cover?.replace(' ','')
    if(parseInt(cover) === 40000){
      date.setFullYear(date.getFullYear() - 21);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    }else{
      date.setFullYear(date.getFullYear() - 0);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    }

  };


  const handleDelete = (index) =>{
    var arr = [...extendedFamily];
    arr.splice(index, 1);
    setExtendedFamily(arr);
    dispatch(updateCustomAttributes({
      type: "EXTENDED_FAMILIES",
      payload: arr
    }))
  }

  return (
    <div className="mt-4">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">
              Namibia Family Protection Plan
            </h3>
            <h2 className="primary-color f-800">Indicative quote</h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">
            Namibia Family Protection Plan
          </p>
          <h2 className="primary-color f-800">Indicative quote</h2>
        </div>
      )}

      <div className="d-flex justify-content-center align-items-center flex-column">
        <div className={classNames(style.step_2_info, "mt-5 mb-4")}>
          <h4 className="text-white f-700">Family Protection Plan</h4>
          <h6 className="secondary-color f-500">
            {getAttributes("Cover Option")?.value}
          </h6>
          <div className={style.step_2_info_container}>
            <h6 className="secondary-color f-700">Premium</h6>
            <div className="d-flex justify-content-between">
              <h6 className="primary-color f-700">Monthly cost</h6>
              <h6 className="primary-color f-700">
                N$
                {premiumAmountCalc()
                  ? premiumAmountCalc()?.toFixed(2) >= 60
                    ? premiumAmountCalc()
                        ?.toFixed(2)
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0
                    : "60.00"
                  : 0}
              </h6>
            </div>
            <div style={{ height: "30px" }} />
            <h6 className="secondary-color f-700">Level of cover</h6>
            <div className="d-flex justify-content-between">
              <h6 className="primary-color f-700">Main member</h6>
              <h6 className="primary-color f-700">
                {coverAmount?.value?.replace(",", "")}
              </h6>
            </div>

            {!over60Option() ? (
              <>
                {riskAttributes?.[0]?.value === 0 ? null : (
                  <>
                    {customAttributes?.coverOption === "Family" && (
                      <div className="d-flex justify-content-between">
                        <h6 className="primary-color f-700">Spouse</h6>
                        <h6 className="primary-color f-700">
                          {/* {parseString(productOptions?.risks?.[0]?.rating?.message)?.[' Spouse']} */}
                          {coverAmount?.value?.replace(",", "")}
                        </h6>
                      </div>
                    )}

                    {(customAttributes?.coverOption === "Single Parent" ||
                      customAttributes?.coverOption === "Family") && (
                      <div className="d-flex justify-content-between">
                        <h6 className="primary-color f-700">Child 15-18</h6>
                        <h6 className="primary-color f-700">
                          {coverAmount?.value?.replace(",", "")}
                        </h6>
                      </div>
                    )}
                    {(customAttributes?.coverOption === "Single Parent" ||
                      customAttributes?.coverOption === "Family") && (
                      <div className="d-flex justify-content-between">
                        <h6 className="primary-color f-700">Child 0-15</h6>
                        <h6 className="primary-color f-700">
                          {formatCoverAmount1(
                            childAmount.toString().replace("", "N$")
                          )}
                        </h6>
                      </div>
                    )}

                    {getAttributes("Extended Family")?.value === "YES" &&
                      getValuations("Extended Family") && (
                        <div className="d-flex justify-content-between">
                          <h6 className="primary-color f-700">
                            Extended Family
                          </h6>
                          <h6 className="primary-color f-700">
                            N${getValuations("Extended Family")}
                          </h6>
                        </div>
                      )}
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {!over60Option() && (
          <>
            {(coverOption === "Single Parent" || coverOption === "Family") && (
              <>
                <TwoButtons
                  id="Child Cover"
                  half={true}
                  title={"Add Children (4 Included in package)?"}
                  errors={errors?.["Child Cover"]}
                  state={customAttributes?.child_cover}
                  onClick={(value) => {
                    if (value === 1) {
                      var obj = {
                        ...customAttributes,
                        numberOfChildren: 0,
                        child_cover: value,
                      };
                      return dispatch(
                        updateCustomAttributes({
                          type: "CUSTOM_ATTRIBUTES",
                          payload: obj,
                        })
                      );
                    }
                    handleChangeCustom(value, "child_cover", "CHILD_COVER");
                  }}
                  options={[
                    {
                      name: "Yes",
                      value: 0,
                    },
                    {
                      name: "No",
                      value: 1,
                    },
                  ]}
                />
                {customAttributes?.child_cover === 0 && (
                  <div
                    className={classNames(style.twoButtonContainer, "p-3")}
                    id={"Number of Children"}
                  >
                    <p className="primary-color f-700">
                      {"How many children do you want to cover?"}
                    </p>
                    <NumberInput
                      half={true}
                      type="number"
                      hideLabel={true}
                      customWidth={200}
                      id={"Number of Children"}
                      value={customAttributes?.numberOfChildren}
                      errors={
                        errors?.["Number of Children"]?.includes(
                          "Number of children is not a number"
                        )
                          ? ["Number of children can't be blank"]
                          : errors?.["Number of Children"]
                      }
                      onChange={(value) => {
                        if (
                          /[0-9\b]+/.test(value.target.value) ||
                          value.target.value === ""
                        ) {
                          if (parseInt(value?.target?.value) < 0) return;
                          handleChangeCustom(
                            parseInt(value.target.value),
                            "numberOfChildren",
                            "CHILD_COVER"
                          );
                        }
                      }}
                      label={"How many children do you want to cover?"}
                    />
                  </div>
                )}
              </>
            )}
            <div style={{ height: "20px" }} />
            {(coverOption === "Just Me" ||
              coverOption === "Single Parent" ||
              coverOption === "Family") && (
              <>
                <TwoButtons
                  id="Extended Family"
                  half={true}
                  title={"Do you want to cover any extended family?"}
                  errors={errors?.["Extended Family Cover"]}
                  state={customAttributes?.extendedCover}
                  onClick={(value) => {
                    if (value === 1) {
                      var obj = {
                        ...customAttributes,
                        numberOfExtended: 0,
                        extendedCover: value,
                      };
                      var arr = [...riskAttributes];
                      const index = riskAttributes?.findIndex(
                        (x) => x.name === "Extended Family Cover"
                      );
                      arr[index] = {
                        ...arr[index],
                        value: value === 0 ? "YES" : "NO",
                      };
                      dispatch(updatedAttributes(arr, "MAM"));
                      dispatch(
                        updateCustomAttributes({
                          type: "EXTENDED_FAMILIES",
                          payload: [],
                        })
                      );
                      setExtendedFamily([{ dob: null, cover: null }]);
                      return dispatch(
                        updateCustomAttributes({
                          type: "CUSTOM_ATTRIBUTES",
                          payload: obj,
                        })
                      );
                    }
                    var obj = {
                      ...customAttributes,
                      extendedCover: value,
                    };
                    var arr = [...riskAttributes];
                    const index = riskAttributes?.findIndex(
                      (x) => x.name === "Extended Family Cover"
                    );
                    arr[index] = {
                      ...arr[index],
                      value: value === 0 ? "YES" : "NO",
                    };
                    dispatch(updatedAttributes(arr, "MAM"));
                    dispatch(
                      updateCustomAttributes({
                        type: "CUSTOM_ATTRIBUTES",
                        payload: obj,
                      })
                    );
                    handleChangeCustom(
                      value,
                      "extendedCover",
                      "EXTENDED_COVER"
                    );
                  }}
                  options={[
                    {
                      name: "Yes",
                      value: 0,
                    },
                    {
                      name: "No",
                      value: 1,
                    },
                  ]}
                />

                {customAttributes?.extendedCover === 0 && (
                  <div style={{ maxWidth: "460px", width: "100%" }}>
                    <div className={style.coverOptions}>
                      <p className="primary-color f-700"></p>
                      <p className="primary-color f-700 mt-3">
                        Add upto 8 extended family members
                      </p>
                      <Divider />
                      <div style={{ height: 20 }} />
                      {extendedFamily?.map((item, index) => (
                        <div>
                          <p className="primary-color f-700">
                            Family member #{index + 1}
                          </p>
                          <Select
                            half={true}
                            hideLabel={false}
                            id="Extended Family Cover Amount"
                            width={200}
                            title="Select a cover amount"
                            defaultValue={item?.cover}
                            options={JSON.parse(
                              state
                                ?.find((z) => z.code === "EXF")
                                ?.attributes?.find(
                                  (r) =>
                                    r.name === "Extended Family Cover Amount"
                                )?.settings
                            )?.items?.map((x) => ({ name: x, value: x }))}
                            errors={
                              errors?.[`Family Member ${index + 1} cover`]
                            }
                            onClick={(value) => {
                              var arr = [...extendedFamily];
                              arr[index] = { ...arr[index], cover: value };
                              setExtendedFamily(arr);
                              dispatch(
                                updateCustomAttributes({
                                  type: "EXTENDED_FAMILIES",
                                  payload: arr,
                                })
                              );
                            }}
                          />
                          <DateInput
                            label="Date of birth of family member *"
                            value={item?.dob}
                            minDate={calculateMinDate(item?.cover)}
                            maxDate={calculateMaxDate(item?.cover)}
                            errors={
                              errors?.[
                                `Family Member ${index + 1} date of birth`
                              ]
                            }
                            onChange={(e) => {
                              var arr = [...extendedFamily];
                              arr[index] = {
                                ...arr[index],
                                dob: e.target.value,
                              };
                              setExtendedFamily(arr);
                              dispatch(
                                updateCustomAttributes({
                                  type: "EXTENDED_FAMILIES",
                                  payload: arr,
                                })
                              );
                            }}
                          />
                          <div className="d-flex justify-content-between flex-row mt-3 mb-3">
                            {index >= 1 && (
                              <button
                                style={{
                                  backgroundColor: "#E33252",
                                  borderRadius: 5,
                                  color: "white",
                                  width: 100,
                                  height: 40,
                                }}
                                onClick={() => handleDelete(index)}
                              >
                                Delete
                              </button>
                            )}
                            {extendedFamily?.length - 1 === index && (
                              <button
                                style={{
                                  backgroundColor: "#2C317E",
                                  borderRadius: 5,
                                  color: "white",
                                  width: 180,
                                  height: 40,
                                }}
                                onClick={() => {
                                  if (extendedFamily?.length > 7) return;
                                  setExtendedFamily([
                                    ...extendedFamily,
                                    { dob: null, cover: null },
                                  ]);
                                  dispatch(
                                    updateCustomAttributes({
                                      type: "EXTENDED_FAMILIES",
                                      payload: [
                                        ...extendedFamily,
                                        { dob: null, cover: null },
                                      ],
                                    })
                                  );
                                }}
                              >
                                Add family member
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
            <div style={{ height: "20px" }} />
            <TwoButtons
              id="Beneficiary Cover"
              half={true}
              title={"Are you the beneficiary of the policy?"}
              errors={errors?.["Beneficiary Cover"]}
              state={customAttributes?.bene_cover}
              onClick={(value) => {
                if (value === 1) {
                  var obj = {
                    ...customAttributes,
                    bene_cover: value,
                  };
                  return dispatch(
                    updateCustomAttributes({
                      type: "CUSTOM_ATTRIBUTES",
                      payload: obj,
                    })
                  );
                }
                handleChangeCustom(value, "bene_cover", "BENE_COVER");
              }}
              options={[
                {
                  name: "Yes",
                  value: 0,
                },
                {
                  name: "No",
                  value: 1,
                },
              ]}
            />
          </>
        )}
        <div style={{ height: "20px" }} />
      </div>
      <div style={{ height: "20px" }} />
      <NavigationButtons
        back={handleBackTrack}
        isNextDisabled={
          premiumAmountCalc() === 0 || premiumAmountCalc() === 0.0
        }
        next={() => {
          if (isLoading) {
            return;
          }
          handleSubmit();
        }}
      />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.1)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Loader />
        </div>
      )}
    </div>
  );
};

export default Step2;
