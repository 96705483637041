import { Accordion, Divider } from "@mui/material";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { DateInput, TextInput, TwoButtons } from "../../../components";
import Summary from "../../../components/Forms/Summary";
import NavigationButtons from "../../../components/NavigationButtons";
import { storeState } from "../../../store/actions/prevState";
import {
  issuePolicy,
  addPolicyBankDetails,
  sendOTP,
  validateOTP,
  approvePolicy,
  getProductPayment,
  generateCheckout,
  updateCustomAttributes,
} from "../../../store/actions/products";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import useWindowDimensions from "../../../utils/useWindowDimension";
import style from "../Form.module.css";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import validate from "validate.js";
import ReactSelect from "react-select";
import { Col, Row } from 'react-bootstrap';
import { formatCoverAmount, trackUserEvents } from "../../../utils/utils";


const Step6 = ({ handleNext, handleBack }) => {
  const bankInformation = useSelector((state) => state?.products?.bank_information);
  const [attributes, setAttributes] = useState({...bankInformation});
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(false);
  const calculatorResponse = useSelector(
    (state) => state?.products?.executeCalculator
  );
  const agentOnBoardingToken = useSelector((state) => state?.auth?.agentOnboarding?.jwtToken);
  console.log("agentOnBoardingToken-->", agentOnBoardingToken);
  const agentCode = useSelector((state) => state.auth.agentCode);
  const policy = useSelector((state) => state?.products?.policy);
  const mainMember = useSelector((state) => state?.products?.mainMember);
  const poliyholderName = mainMember?.firstName
  const poliyLastName = mainMember?.lastName
  const [isForm, setIsForm] = useState(false);
  const [generateForm, setGenerateForm] = useState("");
  const extendedFamilies = useSelector(
    (state) => state?.products?.extendedFamilies
  );
  const [terms, setTerms] = useState({
    aggreement: null,
    terms: false,
    declaration: false,
  });

  const riskAttributes = useSelector(
    (state) => state?.products?.riskAttributes
  );
  const familyPolicyAmt= riskAttributes?.find((y) => y.code === RISKS.MAM)?.attributes?.find((x) => x.name === "Cover Amount")?.value

  useEffect(() => {
        const date = new Date();
        const dateMoment = moment(date).format("YYYY-MM-DD")
        const splitDate = dateMoment.split("-")
        const lastDates = ['26','27','28','29','30','31']
        if(lastDates.includes(splitDate[2])){
          setAttributes({
            ...attributes,
            coverDate: new Date(date.getFullYear(), date.getMonth() + 2, 1)
          });
        }else{
        setAttributes({
          ...attributes,
          coverDate: new Date(date.getFullYear(), date.getMonth() + 1, 1)
        });
      }
  },[])


  const getPremium = () => {
    let total = 0;
    calculatorResponse?.productOptions?.[0]?.risks?.forEach((ele) => {
      total = ele.rating.total + total;
    });
    if(total <= 59){
      return 'N$60.00'
    }
    return `N$${total
      ?.toFixed(2)
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  const handleChange = (evt) => {
    let obj = {
      ...attributes,
      [evt.target.name]: evt.target.value,
    };
    setAttributes(obj);
    dispatch(updateCustomAttributes({ type: "BANK_INFORMATION", payload:obj  }))
  };
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      //----namibia payment methods calls start-----////
      const product_Payment = await dispatch(getProductPayment());
      if (product_Payment) {
        const data = {
          paymentInstanceId:
            product_Payment?.productPayment?.[0]?.configurationKey,
          companyId: product_Payment.companyInstanceId,
          policyReferenceId: policy && policy?.policies[0]?.policyReference,
          policySaleReference: policy?.policySaleReference,
          amount: parseFloat(getPremium()?.replace("N$", "")),
          currency: "N$",
          checkoutType: 1,
          shopperSuccessUrl: `${
            window.location.origin
          }/payment-success?referenceNumber=${
            policy?.policies[0]?.policyReference
          }&applicant=${poliyholderName}&premium=${getPremium()}&saleNumber=${
            policy?.policySaleReference
          }&familyPolicy=${familyPolicyAmt}&lastName=${poliyLastName}&token1=${agentOnBoardingToken}`,
          shopperFailureUrl: `${
            window.location.origin
          }/payment-failure?referenceNumber=${
            policy?.policies[0]?.policyReference
          }&applicant=${poliyholderName}&premium=${getPremium()}&saleNumber=${
            policy?.policySaleReference
          }&familyPolicy=${familyPolicyAmt}&lastName=${poliyLastName}&token1=${agentOnBoardingToken}`,
        };
        const generate_Checkout = await dispatch(generateCheckout(data));
        if (generate_Checkout?.data?.data?.form) {
          dispatch(
            storeState({ generateCheckout: generate_Checkout?.data?.data })
          );
          setIsForm(true);
          setGenerateForm(generate_Checkout?.data?.data?.form);
        }
      }
      //---namibia payment methods calls ends -----////

      //trackUserEvents
      trackUserEvents("na_application_payment_details_confirm_cta", {});
      setIsLoading(false);
      dispatch(storeState());
    } catch (err) {
        console.log(err);
      setIsLoading(false);
    }
  };

  const paymentScript = useSelector(
    (state) => state?.prevState?.generateCheckout?.script
  );
  useEffect(() => {
    if(paymentScript){
      let myScript = paymentScript;
      myScript = myScript.replace("<script>", "");
      myScript = myScript.replace("</script>", "");
      const script = document.createElement("script");
  
      script.innerHTML = myScript;
  
      document.body.appendChild(script);
    }
  },[paymentScript])


  const getFirstDayOfNextMonth = () => {
        const date = new Date();
        const dateMoment = moment(date).format("YYYY-MM-DD")
        const splitDate = dateMoment.split("-")
        const lastDates = ['24','25','26','27','28','29','30','31']
        if(lastDates.includes(splitDate[2])){
          return new Date(date.getFullYear(), date.getMonth() + 1, 1);
        }
        return new Date(date.getFullYear(), date.getMonth(), 1);
  }


  return (
    <div className="mt-4">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">Namibia Family Protection Plan</h3>
            <h2 className="primary-color f-800">
              Application & payment details
            </h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Namibia Family Protection Plan</p>
          <h2 className="primary-color f-800">Application & payment details</h2>
        </div>
      )}

      <div className="mt-4 h-100">
        <div className="d-flex justify-content-center flex-column align-items-center mt-5 mb-5">
        { isForm ? isForm && (
          <>
          <Row className={classNames("mt-5")}>
          <div dangerouslySetInnerHTML={{ __html: generateForm }} />
          </Row>
          </>
        )
        :(
          <>
          <Summary
            isSummary={true}
            title="Namibia Family Protection Plan"
            referenceNumber={
              policy?.policies && policy?.policies[0]?.policyReference
            }
            policyHolder={mainMember?.firstName ?? "NA"}
            policyType={
              riskAttributes
                ?.find((y) => y.code === RISKS.MAM)
                ?.attributes?.find((x) => x.name === ATTRIBUTES.LIVES_COVERED)
                ?.value
            }
            coverType={
              riskAttributes
                ?.find((y) => y.code === RISKS.MAM)
                ?.attributes?.find((x) => x.name === ATTRIBUTES.COVER_OPTIONS)
                ?.value
            }
            policyHolderSurname={mainMember?.lastName ?? "NA"}
            familyPolicy={`${
              (riskAttributes
                ?.find((y) => y.code === RISKS.MAM)
                ?.attributes?.find((x) => x.name === "Cover Amount")?.value)
            }`}
            coverAmount={`${
              riskAttributes
                ?.find((y) => y.code === RISKS.MAM)
                ?.attributes?.find((x) => x.name === "Cover Amount")?.value
            }`}
            premiumAmount={getPremium()}
            extendedCover={
              riskAttributes
                ?.find((y) => y.code === RISKS.MAM)
                ?.attributes?.find((x) => x.name === "Extended Family Cover")
                ?.value === "YES"
                ? `${
                    riskAttributes
                      ?.find((y) => y.code === RISKS.MAM)
                      ?.attributes?.find((x) => x.name === "Cover Amount")
                      ?.value
                  }`
                : null
            }
            extendedFamilies={extendedFamilies}
          />
          <Accordion
            defaultExpanded={true}
            style={{ maxWidth: "460px", width: "100%" }}
          >
            <AccordionDetails className="p-0 mt-4">
              <div
                style={{
                  border: "1px solid #00000030",
                  padding: 20,
                  borderRadius: 5,
                }}
              >
                <p className="mt-3">
                  I, the undersigned authorise Sanlam to, in terms of the
                  agreement, deduct the premium for the amount as specified in
                  this form, from my account, including any applicable premium
                  increases I have selected or any increases that Sanlam may
                  apply as agreed with me, until the due premium on this policy
                  is paid.
                </p>
                <TwoButtons
                  isField={true}
                  half={true}
                  state={terms.aggreement}
                  errors={errors["aggreement"]}
                  onClick={(e) => setTerms({ ...terms, aggreement: e })}
                  options={[
                    {
                      name: "Yes",
                      value: 1,
                    },
                  ]}
                />
                <h6 className="mt-3">Declarations</h6>
                <p>
                  I have read and understood the information for the
                  policyholder & conditions of insurance made available to me/us
                  and agree to accept the insurance to the terms conditions of
                  exclusions and limitations.
                  <br />
                  <br />
                  I confirm that I/am we/are residents of Namibia
                  <br />
                  <br />I consent to provider, providing me with the information
                  for the policyholder & conditions of insurance, the policy
                  overview and the certificate of insurance via email
                </p>
                <TwoButtons
                  isField={true}
                  half={true}
                  state={terms.declaration}
                  errors={errors["declaration"]}
                  onClick={(e) => setTerms({ ...terms, declaration: e })}
                  options={[
                    {
                      name: "Yes",
                      value: 1,
                    },
                  ]}
                />

                <Divider />

                <DateInput
                  label={"Debit order date"}
                  name={"coverDate"}
                  value={moment(attributes.coverDate).format("YYYY-MM-DD")}
                  onChange={handleChange}
                  errors={errors.coverDate}
                  required={true}
                  disabled={true}
                />
                <div className=" mt-3 d-flex justify-content-between flex-row align-items-start">
                  <TwoButtons
                    isField={true}
                    half={true}
                    onClick={(e) => setTerms({ ...terms, terms: e })}
                    state={terms.terms}
                    errors={errors?.["terms"]}
                    options={[
                      {
                        name: "Yes",
                        value: 1,
                      },
                    ]}
                  />
                  <p>
                    By ticking this box. I accept that the premium for this
                    policy will be collected monthly for payment. I further
                    declare that I have read and understood the Payment Terms &
                    Conditions.
                  </p>
                </div>
                <Divider />
                <div style={{ height: "20px" }} />
                <button
                  className={style.confirm_purchase}
                  onClick={() => {
                    if (
                      !terms?.terms ||
                      !terms?.aggreement ||
                      !terms?.declaration
                    ) {
                      return setErrors({
                        ...errors,
                        declaration: !terms?.declaration
                          ? ["Please review the declaration"]
                          : [],
                        aggreement: !terms?.aggreement
                          ? ["Please review the agreement"]
                          : [],
                        terms: !terms?.terms ? ["Please review the terms"] : [],
                      });
                    } else {
                      setErrors({
                        ...errors,
                        terms: [],
                        declaration: [],
                        aggreement: [],
                      });
                      handleSubmit();
                    }
                  }}
                >
                  {isLoading ? (
                    <div class="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : (
                    "Confirm & Submit"
                  )}
                </button>
              </div>
            </AccordionDetails>
          </Accordion>
          </>
        )
      }
        </div>
      </div>

      <div style={{ height: "20px" }} />

      <div className={style.step_1_price_summary}>
        <div className={style.step_1_price_summary_sub}>
          <h5 className="text-white f-700">Total Monthly Premium</h5>
          <h5 className="text-white f-700">{getPremium()}</h5>
        </div>
      </div>

      <NavigationButtons
        back={() => {
          //trackUserEvents
          trackUserEvents("na_application_payment_details_back_cta", {});
          handleBack(3);
        }}
        payment={true}
        next={() => {
        }}
      />
    </div>
  );
};

const schema = {
  accountHolder: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[-\sa-zA-Z]+$/,
      message: "^ Only alphabetic characters, hyphen and space are allowed",
    },
  },
  accountNumber: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[0-9]*$/,
      message: "^ Only numbers are allowed",
    },
  },
  type: {
    presence: {
      allowEmpty: false,
    },
  },
  bank: {
    presence: {
      allowEmpty: false,
    },
  },
  branch: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[-\sa-zA-Z]+$/,
      message: "^ Only alphabetic characters, hyphen and space are allowed",
    },
  },
  branchCode: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[0-9]*$/,
      message: "^ Only numbers are allowed",
    },
  },
};

export default Step6;

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: "#00000080",
  }),
};