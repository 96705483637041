export const mainMemberConstraints = (inputs) => {
  var schema = {
    firstName: {
      presence: {
        allowEmpty: false,
      },
      format: {
        pattern: /^[-\sa-zA-Z]+$/,
        message: "^ Only alphabetic characters, hyphen and space are allowed",
      },
      length: {
        maximum: 30,
      },
    },
    lastName: {
      presence: {
        allowEmpty: false,
      },
      format: {
        pattern: /^[-\sa-zA-Z]+$/,
        message: "^ Only alphabetic characters, hyphen and space are allowed",
      },
    },
    title: {
      presence: {
        allowEmpty: false,
      },
    },
    nationality: {
      presence: {
        allowEmpty: false,
      },
      format: {
        pattern: /[-\sA-Za-z0-9]+$/gm,
        message:
          "^ Nationality only contains alphabets, numericals, hyphen and space",
      },
    },
   
    maritalStatus: {
      presence: {
        allowEmpty: false,
      },
    },
    mobilePhone: {
      presence: {
        allowEmpty: false,
        message: "^ Mobile number can't be blank"
      },
      format: {
        pattern: /^(\+\d{1,3}[- ]?)?\d{6,12}$/,
        message: "^ Invalid mobile number",
      },
    },
    mobilePhoneCode: {
      presence: {
        allowEmpty: false,
      },
      length: {
        maximum: 4,
        message: "^ Mobile phone code is too long (max is 4 characters)",
      },
    },
    eMail: {
      presence: {
        allowEmpty: false,
        message: "^ Email can't be blank",
      },
      email: {
        message: "^ Email is not a valid email"
      },
    },
    sourceOfFunds: {
      presence: {
        allowEmpty: false,
      },
    },
    sourceOfIncome: {
      presence: {
        allowEmpty: false,
      },
    },
    physicalAddress: {
      presence: {
        allowEmpty: false,
      },
      format: {
        pattern: /[-\sA-Za-z0-9]+$/gm,
        message:
          "^ Only alphabetic characters, numbers, hyphen and space are allowed",
      },
    },
    city: {
      presence: {
        allowEmpty: false,
      },
      format: {
        pattern: /[-\sA-Za-z0-9]+$/gm,
        message:
          "^ Only alphabetic characters, numbers, hyphen and space are allowed",
      },
    },
    region: {
      presence: {
        allowEmpty: false,
      },
    },
    mainGender: {
      presence: {
        allowEmpty: false,
      },
    },
    occupation: {
      presence: {
        allowEmpty: false,
      },
      format: {
        pattern: /[-\sA-Za-z0-9]+$/gm,
        message:
          "^ Only alphabetic characters, numbers, hyphen and space are allowed",
      },
    },
   
  };

  if(inputs?.address2){
    schema = {
      ...schema,
      address2: {
       
        format: {
          pattern: /[-\sA-Za-z0-9]+$/gm,
          message:
            "^ Only alphabetic characters, numbers, hyphen and space are allowed",
        },
      },
    }
  }
  if(inputs?.nationality === 'Namibia' && inputs?.documentType === 'Passport'){
    schema = {
      ...schema,
      idNumber: {
        presence: {
          allowEmpty: false,
          message: "^ ID number can't be blank",
        },
        format: {
          pattern: /^[0-9]+$/,
          message: "^Invalid nationality id",
        },
        length: {
          maximum: 8,
        }
      },
    }
  }


    if(inputs?.nationality === 'Namibia'){
    schema = {
      ...schema,
      idNumber: {
        presence: {
          allowEmpty: false,
          message: "^ ID number can't be blank",
        },
        format: {
          pattern: /^[0-9]\d{10}$|^[0-9]\d{12}$/,
          message: "^ID number is the wrong length (should be 11 or 13 characters)",
        },
      },
    }
  }


  if (inputs?.landlinePhoneCode) {
    schema = {
      ...schema,
      landlinePhoneCode: {
        presence: {
          allowEmpty: false,
        },
        length: {
          maximum: 4,
          message: "^ Landline phone code is too long (max is 4 characters)",
        },
      },
    };
  }
  if (inputs?.employer) {
    schema = {
      ...schema,
      employer: {
        presence: {
          allowEmpty: false,
        },
        format: {
          pattern: /^[-\sa-zA-Z&]+$/,
          message: "^ Only alphabetic characters, hyphen and space are allowed",
        },
      },
    };
  }
  if (inputs?.position) {
    schema = {
      ...schema,
      position: {
        presence: {
          allowEmpty: false,
        },
        format: {
          pattern: /[-\sA-Za-z0-9]+$/gm,
          message:
            "^ Only alphabetic characters, numbers, hyphen and space are allowed",
        },
      },
    };
  }
  if (inputs?.business) {
    schema = {
      ...schema,
      business: {
        presence: {
          allowEmpty: false,
        },
        format: {
          pattern: /[-\sA-Za-z0-9]+$/gm,
          message:
            "^ Only alphabetic characters, numbers, hyphen and space are allowed",
        },
      },
    };
  }
  if (inputs?.source) {
    schema = {
      ...schema,
      source: {
        presence: {
          allowEmpty: false,
        },
        format: {
          pattern: /^[-\sa-zA-Z]+$/,
          message:
            "^ Only alphabetic characters, hyphen and space are allowed",
        },
      },
    };
  }
  if (inputs?.religion) {
    schema = {
      ...schema,
      religion: {
        presence: {
          allowEmpty: false,
        },
        format: {
          // Alphabets and space only
          pattern: /[-\sA-Za-z]+$/gm,
          message: "^ Only alphabetic characters, hyphen and space are allowed",
        },
      },
    };
  }

  if (inputs?.postalAddress) {
    schema = {
      ...schema,
      postalAddress: {
        presence: {
          allowEmpty: false,
        },
        format: {
          pattern: /[-\sA-Za-z0-9]+$/gm,
          message:
            "^ Only alphabetic characters, numbers, hyphen and space are allowed",
        },
      },
    };
  }
  if (inputs?.postalTown) {
    schema = {
      ...schema,
      postalTown: {
        presence: {
          allowEmpty: false,
        },
        format: {
          pattern: /[-\sA-Za-z0-9]+$/gm,
          message:
            "^ Only alphabetic characters, numbers, hyphen and space are allowed",
        },
      },
    };
  }
  if (inputs?.postalCountry) {
    schema = {
      ...schema,
      postalCountry: {
        presence: {
          allowEmpty: false,
        },
        format: {
          pattern: /[-\sA-Za-z0-9]+$/gm,
          message:
            "^ Only alphabetic characters, numbers, hyphen and space are allowed",
        },
      },
    };
  }
  return schema;
};

export const spouseConstraints = ({ attributes }) => {
  var schema = {
    spouseCountryCode: {
      presence: {
        allowEmpty: false,
        message: "^ Country code can't be blank"
      },
      length: {
        maximum: 4,
        message: "^ Invalid country code"
      }
    }
  };
  if(attributes?.find((x) => x.name === 'Spouse Email Address')?.value){
    schema = {
      ...schema,
      ['Spouse Email Address']: {
        presence: {
          allowEmpty: true,
        },
        email: {
          message: "^ Email is not a valid email"
        }
      }
    }
  }
  attributes?.filter((y) => (y.name !== "Spouse Document Type") && (y.name !== "Spouse Marital Status") && (y.name !== "Spouse Country ID or Passport issued") && (y.name !== "Spouse Mobile Number") && (y.name !== "Spouse Email Address") && (y.name !== "Spouse Identification Number"))?.forEach((x) => {
    if (x.name === "Spouse First Name" || x.name === "Spouse Last Name") {
      schema = {
        ...schema,
        [x.name]: {
          presence: {
            allowEmpty: false,
            message: x.name === "Spouse First Name" ? "^First name can't be blank" : "^Last name can't be blank"
          },
          format: {
            pattern: /^[-\sa-zA-Z]+$/,
            message:
              "^ Only alphabetic characters, hyphen and space are allowed",
          },
        },
      };
    } 
     else if (x.name === "Spouse Occupation") {
      schema = {
        ...schema,
        [x.name]: {
          presence: {
            allowEmpty: false,
            message:"^Occupation can't be blank"
          },
          format: {
            pattern: /[-\sA-Za-z0-9]+$/gm,
            message:
              "^ Only alphabetic characters, numbers, hyphen and space are allowed",
          },
        },
      };
    } else if (x.name === "Spouse Employer Name") {
      schema = {
        ...schema,
        [x.name]: {
          presence: {
            allowEmpty: false,
            message: "^Employer name can't be blank"
          },
          format: {
            pattern: /[-\sA-Za-z0-9&]+$/gm,
            message:
              "^ Only alphabetic characters, numbers, hyphen and space are allowed",
          },
        },
      };
    } else {
      schema = {
        ...schema,
        [x.name]: {
          presence: {
            allowEmpty: false,
            message:`^${x.name.replace('Spouse', '')} can't be blank`
          },
        },
      };
    }
  });
  return schema;
};

export const childConstraints = ({ attributes, count }) => {
  var schema = {};
  getRows(count)?.forEach((x, i) => {
    attributes?.forEach((y) => {
      if (y.name === "Child First Name" || y.name === "Child Surname") {
        schema = {
          ...schema,
          [`${y.name} ${i + 1}`]: {
            presence: {
              allowEmpty: false,
              message: y.name === "Child First Name" ? "^First name can't be blank" : "^Last name can't be blank"
            },
            format: {
              pattern: /^[-\sa-zA-Z]+$/,
              message:
                "^ Only alphabetic characters, hyphen and space are allowed",
            },
          },
        };
      } else {
        schema = {
          ...schema,
          [`${y.name} ${i + 1}`]: {
            presence: {
              allowEmpty: false,
              message:`^ ${y.name.replace('Child', '')} can't be blank`
            },
          },
        };
      }
    });
  });
  return schema;
};

export const extendedConstraints = ({ attributes, count }) => {
  var schema = {};
  getRows(count)?.forEach((x, i) => {
    attributes?.filter((x) => (x.name !== "Extended Family ID Number") && (x.name !== "Extended Family Country ID was issued"))?.forEach((y) => {
      if(y.name === 'Extended Family Cover Amount' || y.name === 'Extended Family DOB') return;
      if (
        y.name === "Extended Family First Name" ||
        y.name === "Extended Family Last Name"
      ) {
        schema = {
          ...schema,
          [`${y.name} ${i + 1}`]: {
            presence: {
              allowEmpty: false,
              message: y.name === "Extended Family First Name" ? "^First name can't be blank" : "^Last name can't be blank"
            },
            format: {
              pattern: /^[-\sa-zA-Z]+$/,
              message:
                "^ Only alphabetic characters, hyphen and space are allowed",
            },
          },
        };
      } 
      else if(y.name === 'Extended Family Relation to Main Member'){
        schema = {
          ...schema,
          [`${y.name} ${i + 1}`]: {
            presence: {
              allowEmpty: false,
              message: "^Relation to main member can't be blank"
            },
          }
        }
      }else {
        schema = {
          ...schema,
          [`${y.name} ${i + 1}`]: {
            presence: {
              allowEmpty: false,
              message:`^ ${y.name.replace('Extended Family', '')} can't be blank`
            },
          },
        };
      }
    });
  });
  return schema;
};

export const beneficiariesSchema = (beneCount, calculateage, beneficiaries) => {
  var val = {};
  getRows(beneCount)?.map((x, i) => {
    val = {
      ...val,
      [`Beneficiary First Name ${i + 1}`]: {
        presence: {
          allowEmpty: false,
          message: "^ First name can't be blank",
        },
        format: {
          pattern: /[-\sA-Za-z]+$/gm,
          message: "^ Only alphabetic characters, hyphen and space are allowed",
        },
      },
      [`Beneficiary Last Name ${i + 1}`]: {
        presence: {
          allowEmpty: false,
          message: "^ Last name can't be blank",
        },
        format: {
          pattern: /[-\sA-Za-z]+$/gm,
          message: "^ Only alphabetic characters, hyphen and space are allowed",
        },
      },
      
      [`Beneficiaries Title ${i + 1}`]: {
        presence: {
          allowEmpty: false,
          message: "^ Title can't be blank",
        },
      },
      [`Gender ${i + 1}`]: {
        presence: {
          allowEmpty: false,
          message: "^ Gender can't be blank",
        },
      },
      [`Percentage ${i + 1}`]: {
        presence: {
          allowEmpty: false,
          message: "^ Percentage can't be blank",
        },
      },
    };

    if(beneficiaries[i]?.["email"]){
      val = {
        ...val,
        [`Email ${i + 1}`]: {
          presence: {
            allowEmpty: false,
            message: "^ Email can't be blank",
          },
          email: true,
        },
      }
    }
    if (beneficiaries[i]?.address2) {
      val = {
        ...val,
        [`Address 2 ${i + 1}`]: {
          format: {
            pattern: /[-\sA-Za-z0-9]+$/gm,
            message:
              "^ Only alphabetic characters, numbers, hyphen and space are allowed",
          },
        },
      };
    }
    if (beneficiaries[i]?.occupation) {
      val = {
        ...val,
        [`Occupation ${i + 1}`]: {
          presence: {
            allowEmpty: false,
            message: "^ Occupation can't be blank",
          },
          format: {
            pattern: /[-\sA-Za-z0-9]+$/gm,
            message:
              "^ Only alphabetic characters, numbers, hyphen and space are allowed",
          },
        },
      };
    }
    if (beneficiaries[i]?.["Telephone Number Code"]) {
      val = {
        ...val,
        [`Telephone Number Code ${i + 1}`]: {
          presence: {
            allowEmpty: false,
            message: "^ Telephone number code can't be blank",
          },
          length: {
            maximum: 4,
            message: "^Telephone number code is too long (max is 4 characters)",
          },
        },
      };
    }
    if(beneficiaries[i]?.["dob"]){
    if (calculateage(beneficiaries[i]?.["dob"])) {
      val = {
        ...val,
        [`Guardian First Name ${i + 1}`]: {
          presence: {
            allowEmpty: false,
            message: "^ First name can't be blank",
          },
          format: {
            pattern: /[-\sA-Za-z]+$/gm,
            message:
              "^ Only alphabetic characters, hyphen and space are allowed",
          },
          length: {
            maximum: 30,
          },
        },
        [`Guardian Last Name ${i + 1}`]: {
          presence: {
            allowEmpty: false,
            message: "^ Last name can't be blank",
          },
          format: {
            pattern: /[-\sA-Za-z]+$/gm,
            message:
              "^ Only alphabetic characters, hyphen and space are allowed",
          },
          length: {
            maximum: 30,
          },
        },
        [`Guardian Phone Number ${i + 1}`]: {
          presence: {
            allowEmpty: false,
            message: "^ Mobile number can't be blank",
          },
        },
        [`Guardian Phone Number Code ${i + 1}`]: {
          presence: {
            allowEmpty: false,
            message: "^ Country code can’t be blank",
          },
          length: {
            maximum: 4,
            message: "^ Invalid Country code"
          }
        },

        [`Guardian dob ${i + 1}`]: {
          presence: {
            allowEmpty: false,
            message: "^ Date of birth can't be blank",
          },
        },
      };
    }
  }
  });

  return val;
};

const getRows = (rows) => {
  var arr = [];
  for (var i = 0; i < rows; i++) {
    arr.push(i + 1);
  }
  return arr;
};
