///-----passing query parameters---------///////////
import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {Route, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Summary from "../../../components/Forms/Summary";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import useWindowDimensions from "../../../utils/useWindowDimension";
import { getAgentOnboarding, login } from "../../../store/actions/auth";
import style from "../Form.module.css";
import {liberty, sanlam} from '../../../assets/images/index';
import {getProductPayment, generateCheckout,getPaymentStatus, issuePolicy, addPolicyBankDetails, approvePolicy, rejectPolicy, sendOTP, validateOTP,} from "../../../store/actions/products";
import {formatCoverAmount} from "../../../utils/utils"


const Errorpage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { width } = useWindowDimensions();
  document.body.style = "background: #3C409610;";
  const policy = useSelector((state) => state?.prevState?.policy);
  const riskAttributes = useSelector(
    (state) => state?.prevState?.riskAttributes
  );

  const QUQRiskAttributes = useSelector(
    (state) =>
      state.prevState?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const getAttributes = (value) => {
    return QUQRiskAttributes?.find((x) => x?.name === value);
  };

  const mainMember = useSelector((state) => state?.prevState?.mainMember);
  const extendedFamilies = useSelector((state) => state?.prevState?.extendedFamilies);
  const calculatorResponse = useSelector(
    (state) => state?.prevState?.executeCalculator
  );

  const dateOfBirth = getAttributes("Date of Birth");
  const parseQuery = (queryString) => {
    var query = {};
    var pairs = (
      queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
  }

  useEffect(async() => {
    await dispatch(login());
    //  if (Object.keys(agentOnBoarding || {}).length === 0) {
    //  await dispatch(getAgentOnboarding(agentCode));
      // const token1 = parseQuery(location.search)?.token1;
      // await dispatch(getAgentOnboarding(token1));    //  }
    // console.log("real tokennn-->", token1);
    /////-------GetPaymentstatus call-----/// 
    const res = await dispatch(
      getPaymentStatus(
        parseQuery(location.search)?.referenceNumber,
        parseQuery(location.search)?.saleNumber
      )
    );
    const code = res?.genericCode;
    var splitCode = code.split("");
    if (
      (splitCode[0] === "1" && splitCode[1] === "0") ||
      (splitCode[0] === "2" && splitCode[1] === "0")
    ) {
      const approveddata = {
        policyReference: parseQuery(location.search)?.referenceNumber,
        comment: "submitted from react front end",
      };
      // await dispatch(approvePolicy(approveddata));
      const token1 = parseQuery(location.search)?.token1;
      await dispatch(approvePolicy(token1, approveddata));
    } else {
      const rejecteddata = {
        externalReference: "LETSHEGO_SALE",
        comment: "Rejected from Letshego website",
        policyReference: parseQuery(location.search)?.referenceNumber,
      };
      // await dispatch(rejectPolicy(rejecteddata));
        const token1 = parseQuery(location.search)?.token1;
        await dispatch(rejectPolicy(token1, rejecteddata));
    }
  },[])
  

  const getPremium = () => {
    let total = 0;
    calculatorResponse?.productOptions?.[0]?.risks?.forEach((ele) => {
      total = ele.rating.total+total;
    })
    if(total <= 59){
      return 'N$60.00'
    }
    return `N$${total?.toFixed(2)
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
  } 

 

  return (
    <div className="container form-container mt-lg-5 mb-5 p-0">
      <div className="d-flex justify-content-center flex-column">
        {width > 770 ? (
          <>
            {/* <------- HEADER --------> */}
            <h3 className={style.header}>
              Instant Family Protection in a few clicks
            </h3>
          </>
        ) : null}

        <div className={classNames("container", style.boxBorder)}>
          <div className="mt-4 h-100">
            {width > 770 ? (
              <>
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <h3 className="primary-color f-700">Namibia Family Protection Plan</h3>
                  <h5 className="primary-color f-700"> Policy Application</h5>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#00000020",
                  }}
                ></div>
              </>
            ) : (
              <div
                className={classNames(
                  "d-flex justify-content-start align-items-start flex-column",
                  style.headerContainer
                )}
              >
                <p className="text-white f-500 m-0 ">Namibia Family Protection Plan</p>
                <h5 className="primary-color f-700">Policy Application</h5>
              </div>
            )}
            <div className="d-flex justify-content-center flex-column align-items-center mt-5 mb-5">
              <Summary
                isFail={true}
                title="Family Protection Plan"
                referenceNumber={parseQuery(location.search)?.referenceNumber}
                policyHolder={parseQuery(location.search)?.applicant}
                policyHolderSurname={parseQuery(location.search)?.lastName}
                policyType={
                  riskAttributes
                    ?.find((y) => y.code === RISKS.UQQ)
                    ?.attributes?.find(
                      (x) => x.name === ATTRIBUTES.LIVES_COVERED
                    )?.value
                }
                coverType={
                  riskAttributes
                    ?.find((y) => y.code === RISKS.UQQ)
                    ?.attributes?.find(
                      (x) => x.name === ATTRIBUTES.COVER_OPTIONS
                    )?.value
                }
                isSuccess={true}
                extendedFamilies={extendedFamilies}
                premiumAmount={parseQuery(location.search)?.premium}
                familyPolicy={parseQuery(location.search)?.familyPolicy}
                  coverAmount={moment().diff(dateOfBirth?.value?.replaceAll("/", "-"), "years") < 60 ? "N$15,000" : `N$${
                    riskAttributes
                      ?.find((y) => y.code === RISKS.QUQ)
                      ?.attributes?.find(
                        (x) => x.name === "Cover Amount 60 Plus"
                      )?.value
                  }`}
                  extendedCover={riskAttributes
                    ?.find((y) => y.code === RISKS.MAM)
                    ?.attributes?.find(
                      (x) => x.name === "Extended Family Cover"
                    )?.value === 'YES' ? `${
                    riskAttributes
                      ?.find((y) => y.code === RISKS.MAM)
                      ?.attributes?.find(
                        (x) => x.name === "Cover Amount"
                      )?.value
                  }` : null}
                  
              />
              <div style={{maxWidth: '460px' ,width: '100%', marginTop: 20}} className="d-flex justify-content-center flex-column">
                <p style={{fontSize: 13, textAlign: 'center', marginTop: 5}}>
                           Policy underwritten by
                </p>
                <img src={sanlam} style={{width: "95px",display:"block",marginLeft:"auto",marginRight:"auto"}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Errorpage;

