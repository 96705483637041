import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { TwoButtons } from "../../../components";
import NavigationButtons from "../../../components/NavigationButtons";
import useWindowDimensions from "../../../utils/useWindowDimension";
import { useDispatch, useSelector } from "react-redux";
import style from "../Form.module.css";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import Loader from "../../../components/Loader";
import {
  executeCalculator,
  updatedAttributes,
} from "../../../store/actions/products";
import { validate } from "validate.js";
import { useNavigate } from "react-router";
import { radioChecked, radioUnchecked } from "../../../assets/images";
import moment from "moment";
import { formatCoverAmount, trackUserEvents } from "../../../utils/utils";

const Step1 = ({ handleNext }) => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const state = useSelector((state) => state.products.riskAttributes);

  const history = useNavigate();

  const riskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "MAM")?.attributes
  );

  const getAttributes = (value) => {
    return riskAttributes?.find((x) => x.name === value);
  };
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const reduxState = useSelector((state) => state);

  const customAttributes = useSelector(
    (state) => state?.products?.customAttribues
  );
  const [coverAmounts, setCoverAmounts] = useState();
  const [amounts, setAmounts] = useState();
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (getAttributes("Cover Option")?.value) {
      setSelectedOption(getAttributes("Cover Option")?.value);
    }
  }, []);

  useEffect(() => {
    if (getAttributes("Cover Amount")?.value) {
      setSelectedOption(getAttributes("Cover Amount")?.value);
    }
  }, []);

  useEffect(() => {
    if (moment().diff(dateOfBirth?.value?.replaceAll("/", "-"), "years") > 60) {
      if (getAttributes("Cover Amount 60 Plus")?.value) {
        setSelectedOption(getAttributes("Cover Amount 60 Plus")?.value);
      }
    }
  }, []);

  var filterRiskAttributes = (code) => {
    let attrByCode = state.filter((ra) => ra.code === code)[0];
    return attrByCode?.attributes.map((y) => {
      return {
        dataType: y.dataType,
        name: y.name,
        instanceId: y.instanceId,
        value:
          y.name === "Date of Birth"
            ? customAttributes?.dob
            : y.name === "Cover Option"
            ? customAttributes?.coverOption === "Just Me"
              ? "Individual"
              : customAttributes?.coverOption === "Single Parent"
              ? "Individual plus 4 children"
              : "Family"
            : y.value,
      };
    });
  };

  useEffect(async () => {
    if (
      filterRiskAttributes(RISKS.MAM)?.length === 0 ||
      selectedOption !== null
    )
      return;
    const res = await dispatch(
      executeCalculator({
        payload: {
          factors: [],
          productTypeReference:
            reduxState?.products?.types?.types[0]?.instanceId,
          risks: [
            {
              attributes: filterRiskAttributes(RISKS.MAM),
              riskReference: state.find((x) => x.code === RISKS.MAM)
                ?.instanceId,
              code: state.find((x) => x.code === RISKS.MAM)?.code,
            },
          ],
        },
      })
    );
    if (res) {
      setAmounts(
          res.data?.data?.productOptions[0]?.risks[0]?.rating?.message
      );
    }
  }, [filterRiskAttributes(RISKS.MAM)?.length, state]);
  const getConstrains = () => {
    var val = {};

    val = {
      [getAttributes("Cover Amount")?.name]: {
        presence: {
          allowEmpty: false,
          message: "^ Cover option can't be blank",
        },
      },
    };
    return val;
  };

  const handleSubmit = async () => {
    try {
      var values = {};

      values = {
        [getAttributes("Cover Option")?.name]:
          getAttributes("Cover Option")?.value,
        [getAttributes("Cover Amount")?.name]:
          getAttributes("Cover Amount")?.value,
      };

      const errors = validate(values, getConstrains());

      if (errors || errors !== undefined) {
        return setErrors(errors);
      }
      setIsLoading(true);

      const res = await dispatch(
        executeCalculator({
          payload: {
            factors: [],
            productTypeReference:
              reduxState?.products?.types?.types[0]?.instanceId,
            risks: [
              {
                attributes: [
                  ...state
                    .find((x) => x.code === RISKS.MAM)
                    ?.attributes.map((y) => ({
                      dataType: y.dataType,
                      name: y.name,
                      instanceId: y.instanceId,
                      value:
                        y.name === "Date of Birth"
                          ? customAttributes?.dob
                          : y.name === "Cover Option"
                          ? customAttributes?.coverOption === "Just Me"
                            ? "Individual"
                            : customAttributes?.coverOption === "Single Parent"
                            ? "Individual plus 4 children"
                            : "Family"
                          : y.name === "Cover Amount"
                          ? getAttributes("Cover Amount")?.value
                          : y.value,
                    })),
                ],
                riskReference: state.find((x) => x.code === RISKS.MAM)
                  ?.instanceId,
                code: state.find((x) => x.code === RISKS.MAM)?.code,
              },
            ],
          },
        })
      );

      if (getAttributes("Cover Option")?.value === "Individual") {
        return handleNext(2);
      }
      //trackUserEvents
      trackUserEvents("na_select_your_cover_cta", {coverDetails: getAttributes("Cover Amount")?.value});
      handleNext();
      return setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    history("/");
    //trackUserEvents
    trackUserEvents("na_select_your_cover_back_cta", {});
  };

  const dateOfBirth = getAttributes("Date of Birth");

  const getCoverAmounts = useCallback(() => {
    var amt = amounts;
    var splitAmounts = amt?.split(" ");
    return splitAmounts?.filter((y) => y.charAt(0) === "N");
  }, [amounts]);

  return state?.length && getCoverAmounts()?.length ? (
    <div className="mt-4 h-100">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">
              Namibia Family Protection Plan
            </h3>
            <h2 className="primary-color f-800">Select your cover</h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Family Protection Plan</p>
          <h2 className="primary-color f-800">Select your cover</h2>
        </div>
      )}

      <div className="d-flex justify-content-center align-items-center flex-column mt-5">
        <div style={{ maxWidth: "460px", width: "100%" }}>
          {width > 1024 ? (
            <p className="primary-color f-700">
              Welcome to our family protection plan
            </p>
          ) : (
            <p className="primary-color f-700 text-center">
              Welcome to our Family protection plan
            </p>
          )}
          <div className={style.coverOptions}>
            <p className="primary-color mt-3 f-700">
              Choose your cover amount option
            </p>
            {getCoverAmounts()?.length &&
              getCoverAmounts()?.map((item, index) => (
                <div
                  className={
                    selectedOption === item ||
                    getAttributes("Cover Amount")?.value?.replace(" ", "") ===
                      item
                      ? style.optionsActive
                      : style.options
                  }
                  onClick={() => {
                    setSelectedOption(item);
                    var arr = [...riskAttributes];
                    const index = riskAttributes?.findIndex(
                      (x) => x.name === "Cover Amount"
                    );
                    arr[index] = {
                      ...arr[index],
                      value: formatCoverAmount(item),
                    };
                    dispatch(updatedAttributes(arr, "MAM"));
                  }}
                >
                  <div>
                    <img
                      src={
                        selectedOption === item ||
                        getAttributes("Cover Amount")?.value?.replace(
                          " ",
                          ""
                        ) === item
                          ? radioChecked
                          : radioUnchecked
                      }
                    />
                  </div>
                  <p className="primary-color f-700">
                    {formatCoverAmount(item)} Cover
                  </p>
                </div>
              ))}
            {errors?.["Cover Amount"] ? (
              <span className="errors">{errors?.["Cover Amount"]?.[0]}</span>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "transparent",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Loader /> */}
        </div>
      )}

      <NavigationButtons
        loading={isLoading}
        back={handleBack}
        next={handleSubmit}
      />
    </div>
  ) : (
    <Loader />
  );
};

export default Step1;
