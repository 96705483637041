import React, { useState } from "react";
import NavigationButtons from "../../../components/NavigationButtons";
import style from "../Form.module.css";
import { useSelector } from "react-redux";
import useWindowDimensions from "../../../utils/useWindowDimension";
import classNames from "classnames";
import { validate } from "validate.js";
import CONSTANTS, { ATTRIBUTES, RISKS } from "../../../utils/constants";
import Loader from "../../../components/Loader";
import { trackUserEvents } from "../../../utils/utils";
import moment from "moment";
const Step2 = ({ handleNext, handleBack }) => {
  const { width } = useWindowDimensions();
  const [isLoading, setIsLoadiing] = useState(false);

  const riskAttributes = useSelector(
    (state) => state?.products?.riskAttributes
  );

  const QUQRiskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "MAM")?.attributes
  );

  const getAttributes = (value) => {
    return QUQRiskAttributes.find((x) => x.name === value);
  };

  const dateOfBirth = getAttributes("Date of Birth");

  const calculatorResponse = useSelector(
    (state) => state?.products?.executeCalculator
  );

  const productOptions = useSelector(
    (state) => state?.products?.executeCalculator?.productOptions?.[0]
  );

  const coverAmount = getAttributes("Cover Amount");
  const coverOption = useSelector((state) => state?.products?.customAttribues?.coverOption)
  const childAmount = parseInt(coverAmount?.value?.replace('N$','')?.replace(' ','')) / 2


  const checkOverOption = () => {
    if (moment().diff(dateOfBirth?.value?.replaceAll("/", "-"), "years") < 60) {
      return true;
    } else {
      if (getAttributes("Cover Option 60 Plus")?.value === "Individual") {
        return false;
      } else {
        return true;
      }
    }
  };

  const extendedFamilies = useSelector(
    (state) => state?.products?.extendedFamilies
  );

  const formatCoverAmount1 = (value) => {
    let a = value;
    let b = a.length === 6 ? a.slice(0, 3) :  a.slice(0, 4);
    let c = a.length === 6 ? a.slice(3,6) : a.slice(4, 7);
    return b + " "+ c
  }

  const getValuations = (name) => {
    return productOptions?.valuations?.values
      .find((x) => x.name === name)
      ?.value?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const parseString = (string, name) => {
    const arr = string.split(",");
    let obj = {};
    arr.forEach((a) => {
      const val = a.split(":");
      obj[val[0]] = val[1];
    });
    return obj;
  };

  const getExtendedPremiums = (index) => {
    let total =
      calculatorResponse?.productOptions?.[0]?.risks?.[index + 1]?.rating
        ?.total;
    var a = parseFloat(total)
      ?.toFixed(2)
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `N$${a}`;
  };

  const getExtendedCover = (cover) => {
    let a = cover.replace("N$", "");
    a = a.replace(" ", "");
    a = parseFloat(a);
    return formatCoverAmount1(`N$${a}`);
  };

  const getPremium = () => {
    let total = 0;
    calculatorResponse?.productOptions?.[0]?.risks?.forEach((ele) => {
      total = ele.rating.total + total;
    });
    if(total <= 59){
      return 'N$60.00'
    }
    return `N$${total
      ?.toFixed(2)
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  
  const handleSubmit = () => {
    //trackUserEvents
    trackUserEvents("na_quote_next_cta", {});
    handleNext();
  };

  const handleBackTrack = () => {
    //trackUserEvents
    trackUserEvents("'na_quote_back_cta", {});
    handleBack();
  };

  return (
    <div className="mt-4">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">Namibia Family Protection Plan</h3>
            <h2 className="primary-color f-800">Quote</h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
          <div
            className={classNames(
              "d-flex justify-content-start align-items-start flex-column",
              style.headerContainer
            )}
          >
            <p className="text-white f-500 m-0 ">Namibia Family Protection Plan</p>
            <h2 className="primary-color f-800">Quote</h2>
          </div>
        )}
      <div className="d-flex justify-content-center align-items-center flex-column mt-5">
        <div className={classNames(style.step_2_info, "mt-5 mb-4")}>
          <h4 className="text-white f-700">Family Protection Plan</h4>
          <h6 className="secondary-color f-500">
            {getAttributes("Cover Option")?.value}
          </h6>
          <div className={style.step_2_info_container}>
            <h6 className="secondary-color f-700">Premium</h6>
            <div className="d-flex justify-content-between">
              <h6 className="primary-color f-700">Monthly cost</h6>
              <h6 className="primary-color f-700">
                N$
                {calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating
                  ?.total
                  ? (calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating
                    ?.total >= 60 ? calculatorResponse?.productOptions?.[0]?.risks?.[0]?.rating?.total
                    ?.toFixed(2)
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0 : '60.00')
                  : 0}
              </h6>
            </div>
            {extendedFamilies?.length ?
                ((coverOption === "Just Me") ||(coverOption === "Single Parent") || (coverOption === "Family")) && 
              extendedFamilies?.filter((f, idx) => parseInt(getExtendedPremiums(idx)?.replace('N$', '')) > 0)?.map((item, index) => (
                <div className="d-flex justify-content-between">
                  <h6 className="primary-color f-700">
                    Extended Family {index + 1}
                  </h6>
                  <h6 className="primary-color f-700">
                    {getExtendedPremiums(index)}
                  </h6>
                </div>
              )) : null}
            <div style={{ height: "30px" }} />
            <h6 className="secondary-color f-700">Level of cover</h6>
            <div className="d-flex justify-content-between">
              <h6 className="primary-color f-700">Main member</h6>
              <h6 className="primary-color f-700">
                {" "}
                {coverAmount?.value?.replace(',','')}
              </h6>
            </div>
            {checkOverOption() && (
              <>
                {riskAttributes?.[0]?.value === 0 ? null : (
                  <>
                    {
                    coverOption === "Family" && (
                        <div className="d-flex justify-content-between">
                          <h6 className="primary-color f-700">Spouse</h6>
                          <h6 className="primary-color f-700">
                          {coverAmount?.value?.replace(',','')}
                          </h6>
                        </div>
                      )}
                    {
                    ((coverOption === "Single Parent") || (coverOption === "Family")) && (
                        <div className="d-flex justify-content-between">
                          <h6 className="primary-color f-700">Child 15-18</h6>
                          <h6 className="primary-color f-700">
                            {coverAmount?.value?.replace(',','')}
                          </h6>
                        </div>
                      )}

                    {
                      ((coverOption === "Single Parent") || (coverOption === "Family")) && (
                        <div className="d-flex justify-content-between">
                          <h6 className="primary-color f-700">Child 0-15</h6>
                          <h6 className="primary-color f-700">
                            {formatCoverAmount1(childAmount.toString().replace('','N$'))}
                          </h6>
                        </div>
                      )}
                    {extendedFamilies?.length ?
                        ((coverOption === "Just Me") || (coverOption === "Single Parent") || (coverOption === "Family")) &&
                      extendedFamilies?.filter((f, idx) => parseInt(getExtendedPremiums(idx)?.replace('N$', '')) > 0)?.map((item, index) => (
                        <div className="d-flex justify-content-between">
                          <h6 className="primary-color f-700">
                            Extended Family {index + 1}
                          </h6>
                          <h6 className="primary-color f-700">
                            {getExtendedCover(item.cover)}
                          </h6>
                        </div>
                      )) : null}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div style={{ height: "50px" }} />

      <div className={style.step_1_price_summary}>
        <div className={style.step_1_price_summary_sub}>
          <h5 className="text-white f-700">Total Monthly Premium </h5>
          <h5 className="text-white f-700">{getPremium() ?? 0}</h5>
        </div>
      </div>

      <NavigationButtons
        back={() => {
          if (getAttributes("Cover Option")?.value === "Individual") {
            return handleBack(0);
          } else {
            handleBackTrack();
          }
        }}
        next={() => {
          handleSubmit();
        }}
      />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.1)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Loader />
        </div>
      )}
    </div>
  );
};

const schema = {
  firstName: {
    presence: {
      allowEmpty: false,
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
    },
  },
  nationalityId: {
    presence: {
      allowEmpty: false,
    },
  },
  dob: {
    presence: {
      allowEmpty: false,
    },
  },
  nationality: {
    presence: {
      allowEmpty: false,
    },
  },
  maritalStatus: {
    presence: {
      allowEmpty: false,
    },
  },
  mobilePhone: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^(\+\d{1,3}[- ]?)?\d{6,12}$/,
      message: "^ Invalid mobile number",
    },
  },
  mobilePhoneCode: {
    presence: {
      allowEmpty: false,
    },
  },
  eMail: {
    presence: {
      allowEmpty: false,
    },
    email: true,
  },
  physicalAddress: {
    presence: {
      allowEmpty: false,
    },
  },
  mainGender: {
    presence: {
      allowEmpty: false,
    },
  },
};

const percentageArr = [
  {
    name: "10%",
    value: 10,
  },
  {
    name: "20%",
    value: 20,
  },
  {
    name: "30%",
    value: 30,
  },
  {
    name: "40%",
    value: 40,
  },
  {
    name: "50%",
    value: 50,
  },
  {
    name: "60%",
    value: 60,
  },
  {
    name: "70%",
    value: 70,
  },
  {
    name: "80%",
    value: 80,
  },
  {
    name: "90%",
    value: 90,
  },
  {
    name: "100%",
    value: 100,
  },
];

export default Step2;
