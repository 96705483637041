import { MenuItem, FormControl, InputLabel } from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { radioChecked, radioUnchecked } from "../assets/images";
import style from "./components.module.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

export const TwoButtons = ({
  title,
  state,
  errors,
  isField = false,
  id,
  disabled = false,
  options,
  half = false,
  onClick,
  required,
}) => {

  return isField ? (
    <div id={id}>
      <label className="form-label">
        {title}
        {required ? "*" : null}
      </label>
      <div
        className={`d-flex flex-row ${
          half ? "justify-content-start" : "justify-content-around"
        }`}
      >
        {options.map((x) => (
          <div
            className={
              state === x.value ? style.twoButton : style.twoButton_inactive
            }
            style={{ width: half ? "140px" : "200px", opacity: disabled ? 0.5 : 1 }}
            onClick={() => disabled ? null : onClick(x.value)}
          >
            <img src={state === x.value ? radioChecked : radioUnchecked} />
            <p className="f-700 p-0 m-0">{x.name}</p>
          </div>
        ))}
      </div>
      {errors?.length ? (
        <span className="text-danger errors">{errors ? errors[0] : ""}</span>
      ) : null}
    </div>
  ) : (
    <div className={style.twoButtonContainer} id={id}>
      <p className="primary-color f-700">{title}</p>
      <div
        className={`d-flex flex-row ${
          half ? "justify-content-start" : "justify-content-around"
        }`}
      >
        {options.map((x) => (
          <div
            className={
              state === x.value ? style.twoButton : style.twoButton_inactive
            }
            style={{ width: half ? "140px" : "200px" }}
            onClick={() => onClick(x.value)}
          >
            <img src={state === x.value ? radioChecked : radioUnchecked} />
            <p className="f-700 p-0 m-0">{x.name}</p>
          </div>
        ))}
      </div>
      {errors?.length ? (
        <span className="text-danger errors" style={{ marginLeft: 10 }}>
          {errors ? errors[0] : ""}
        </span>
      ) : null}
    </div>
  );
};

export const Dropdown = ({
  title,
  state,
  id,
  customWidth,
  options,
  half = false,
  value = null,
  errors,
  outerErrors,
  onClick,
}) => {
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const [selected, setSelected] = useState(value ? +value : null);
  const [mouseOver, setMouseOver] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!mouseOver) {
        setIsDropDownVisible(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isDropDownVisible, mouseOver]);

  return (
    <>
      <div
        className={style.twoButtonContainer}
        id={id}
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
      >
        <p className="primary-color f-700">{title}</p>
        <div
          className={`d-flex flex-row ${
            half ? "justify-content-start" : "justify-content-around"
          }`}
        >
          <div className="dropdown" style={{ width: customWidth }}>
            <div
              className={
                "dropdown-selection " + (isDropDownVisible ? "visible" : "")
              }
              onClick={(e) => {
                setIsDropDownVisible(!isDropDownVisible);
              }}
            >
              {selected !== null ? options[selected]?.name ?? 0 : 0}
            </div>
            {isDropDownVisible ? (
              <div className="list-holder" style={{ width: customWidth - 25 }}>
                {options?.length
                  ? options?.map((lis, index) => (
                      <div
                        key={lis?.value}
                        className="dropdown-list"
                        onClick={(e) => {
                          setSelected(index);
                          setIsDropDownVisible(false);
                          onClick(options[index].value);
                        }}
                      >
                        {lis?.name}
                      </div>
                    ))
                  : null}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {errors?.length && (
          <span className="text-danger errors" style={{ marginLeft: 10 }}>
            {errors && errors[0]}
          </span>
        )}
      </div>
      <div
        className={style.twoButtonContainer}
        style={{ border: "none", padding: 0, marginTop: 10 }}
      >
        {outerErrors?.length ? (
          <span
            className="text-danger errors"
            style={{ marginLeft: 0, marginTop: 20 }}
          >
            {outerErrors && outerErrors[0]}
          </span>
        ) : null}
      </div>
    </>
  );
};

export const Select = ({
  title,
  state,
  index,
  id,
  hideLabel = false,
  options,
  required,
  name,
  half = false,
  defaultValue = "None",
  value = null,
  width = 100,
  errors,
  onClick,
}) => {
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const [selected, setSelected] = useState(value ? value : null);
  const [mouseOver, setMouseOver] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!mouseOver) {
        setIsDropDownVisible(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [isDropDownVisible, mouseOver]);
  return (
    <div
      className={`form-group ${hideLabel ? "" : "mt-3"}`}
      id={id}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      {!hideLabel && (
        <label className="form-label">
          {title}
          {required ? "*" : null}
        </label>
      )}
      <div
        className={`d-flex flex-row ${
          half ? "justify-content-start" : "justify-content-around"
        }`}
      >
        <div
          style={{ width: `${width}px`, padding: "0px" }}
          className="dropdown"
        >
          <div
            className={
              "dropdown-selection " + (isDropDownVisible ? "visible" : "")
            }
            style={{ minHeight: 40 }}
            onClick={(e) => {
              setIsDropDownVisible(!isDropDownVisible);
            }}
          >
            {selected !== null || index !== null
              ? options[index ?? selected]?.name ?? defaultValue
              : defaultValue ?? 0}
          </div>
          {isDropDownVisible ? (
            <div className="list-holder" style={{ width: width }}>
              {options?.length
                ? options?.map((lis, index) => (
                    <div
                      key={lis?.value}
                      className="dropdown-list"
                      onClick={(e) => {
                        setSelected(index);
                        setIsDropDownVisible(false);
                        onClick(options[index].value);
                      }}
                    >
                      {lis?.name}
                    </div>
                  ))
                : null}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {errors?.length && (
        <span className="text-danger errors" style={{ marginLeft: 0 }}>
          {errors && errors[0]}
        </span>
      )}
    </div>
  );
};

export const TabBar = ({
  options,
  state,
  onClick,
  data,
  errors,
  disabled,
  id,
}) => {
  return (
    <div className={style.tabBar} id={id}>
      <div className="d-flex flex-row w-100 justify-content-between p-0 m-0 align-items-center">
        {options.map((x, i) => (
          <div
            onClick={() => {
              if (!disabled) {
                return null;
              }
              onClick(x.value);
            }}
            className={
              state === x.value
                ? style.tabBar_tab + " text-white"
                : style.tabBar_tab_inactive
            }
          >
            <img src={state === x.value ? radioChecked : radioUnchecked} />
            <p className=" f-700 m-0" style={{ fontSize: 12 }}>
              {x.label}
            </p>
            <p className=" f-700 m-0 pb-4" style={{ fontSize: 12 }}>
              KSh {x?.price ?? ""}
            </p>
          </div>
        ))}
      </div>
      <div className={style.tabBar_containers}>
        {state && (
          <div className={style.tabBar_container}>
            {data.map((x) => (
              <>
                <h6 className="secondary-color mt-3 f-700">{x.title}</h6>
                {x.content.map((y) => (
                  <Row>
                    <Col>
                      <h6 className="primary-color f-700">{y.title}</h6>
                    </Col>
                    <Col>
                      <h6 className="primary-color f-700">{y.value}</h6>
                    </Col>
                  </Row>
                ))}
              </>
            ))}
          </div>
        )}
      </div>
      {errors?.length && (
        <span
          className="text-danger errors"
          style={{ marginLeft: 15, width: "100%", marginTop: 10 }}
        >
          {errors && errors[0]}
        </span>
      )}
    </div>
  );
};

export const CoverOptions = ({
  options,
  state,
  onClick,
  data,
  errors,
  disabled,
  id,
}) => {
  return (
    <div className={style.tabBar} id={id}>
      <div className="d-flex flex-row w-100 justify-content-between p-0 m-0 align-items-center">
        {options.map((x, i) => (
          <div
            onClick={() => {
              if (!disabled) {
                return null;
              }
              onClick(x.value);
            }}
            className={
              state === x.value
                ? style.tabBar_tab + " text-white"
                : style.tabBar_tab_inactive
            }
          >
            <img src={state === x.value ? radioChecked : radioUnchecked} />
            <p className=" f-700 mt-3 mb-4" style={{ fontSize: 16 }}>
              {x.label}
            </p>
          </div>
        ))}
      </div>
      <div className={style.tabBar_containers}>
        {state && (
          <div className={style.tabBar_container}>
            {data.map((x) => (
              <>
                <h6 className="secondary-color mt-3 f-700">{x.title}</h6>
                {x.content.map((y) => (
                  <Row>
                    <Col>
                      <h6 className="primary-color f-700">{y.title}</h6>
                    </Col>
                    <Col>
                      <h6 className="primary-color f-700">{y.value}</h6>
                    </Col>
                  </Row>
                ))}
              </>
            ))}
          </div>
        )}
      </div>
      {errors?.length && (
        <span
          className="text-danger errors"
          style={{ marginLeft: 15, width: "100%", marginTop: 10 }}
        >
          {errors && errors[0]}
        </span>
      )}
    </div>
  );
};

export const Info = ({ children }) => {
  return <div className={style.infoContainer}>{children}</div>;
};

export const ConfirmationBox = ({ children, onChange, value }) => {
  return (
    <div className={style.infoContainer}>
      <Row>
        <Col lg={1}>
          <input
            type="checkbox"
            style={{ transform: "scale(1.8)" }}
            checked={value}
            onChange={onChange}
          />
        </Col>
        <Col lg={11}>{children}</Col>
      </Row>
    </div>
  );
};

export const TextInput = ({
  label,
  required,
  onChange,
  textarea = false,
  id,
  value,
  type,
  errors,
  placeholder,
  disabled = false,
  name,
  maxLength,
  hideLabel = false,
}) => {
  return (
    <>
      <div className={`form-group ${hideLabel ? "mt-0" : "mt-3"}`} id={id}>
        {!hideLabel && (
          <label className="form-label">
            {label}
            {required ? "*" : null}
          </label>
        )}
        {textarea ? (
          <textarea
            type="text"
            name={name}
            disabled={disabled}
            style={{ height: "100%" }}
            rows={3}
            className="form-control"
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            maxlength={maxLength}
          />
        ) : (
          <input
            type={type ?? "text"}
            onKeyDown={type === "number" ? (e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault() : null}
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            className="form-control"
            value={value}
            onChange={onChange}
            maxlength={maxLength}
            onWheel={(e) => e.target.blur()}
          />
        )}
      </div>
      {errors && <span className="text-danger errors">{errors[0]}</span>}
    </>
  );
};

export const NumberInput = ({
  label,
  required,
  onChange,
  placeholder,
  disabled = false,
  customWidth,
  value,
  id,
  errors,
  type = "text",
  name,
  country = false,
  mobile = false,
  hideLabel = false,
  style,
}) => {
  var inputBox = document.getElementById("number");
  
  var invalidChars = [
    "-",
    "+",
    "e",
  ];
  
  inputBox?.addEventListener("keypress", function(e) {
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  });
  
  const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
  return (
    <>
      <div
        className={`form-group ${hideLabel ? "mt-0" : "mt-3"}`}
        id={id}
        style={{ width: customWidth ?? "auto" }}
      >
        {!hideLabel && (
          <label className="form-label">
            {label}
            {required ? "*" : null}
          </label>
        )}
        <input
          type={type}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          onKeyDown={blockInvalidChar}
          className={
            country === true
              ? "form-control-country form-numnber"
              : mobile === true
              ? "form-control-mobile form-numnber"
              : "form-control form-numnber"
          }
          value={value}
          onChange={onChange}
          onWheel={(e) => e.target.blur()}
        />
      </div>
      {errors && <span className="text-danger errors">{errors[0]}</span>}
    </>
  );
};

export const FileUpload = ({
  title,
  errors,
  onChange,
  value,
  multiple,
  accept,
  name,
  required,
  id,
}) => {
  const handleOpen = (id) => {
    document.getElementById(id).click();
  };
  return (
    <div className="file-upload">
      {/*	eslint-disable-next-line */}
      <label className="primary-color f-700">
        {title}
        {required ? "*" : ""}
      </label>

      <div className="file-upload-select">
        <button
          type="button"
          className="file-select-button"
          onClick={() => {
            handleOpen(`file-upload-input-${name}`);
          }}
        >
          Choose File
        </button>
        <div className="file-select-name">
          {value ? value.name : `No file chosen...`}
        </div>
        <input
          type="file"
          className="form-control mt-3"
          multiple={multiple}
          onChange={(e) => onChange(e)}
          accept={accept}
          name={name}
          id={`file-upload-input-${name}`}
        />
      </div>
      {errors?.length && (
        <span className="text-danger errors">{errors && errors[0]}</span>
      )}
    </div>
  );
};

export const CountryInput = ({
  label,
  required,
  onChange,
  placeholder,
  value,
  width,
  id,
  errors,
  name,
  country = false,
  mobile = false,
}) => {
  var inputBox = document.getElementById("number");
  
  var invalidChars = [
    "-",
    "+",
    "e",
  ];
  
  inputBox?.addEventListener("keypress", function(e) {
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  });
  
  const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
  return (
   
    <>
      <div className="form-group mt-3" id={id}>
        <label className="form-label">
          {label}
          {required ? "*" : null}
        </label>
        <PhoneInput
          country={"na"}
          className="form-numnber"
          inputProps={{
            readOnly: true
          }}
          inputStyle={{
            width: width ? width : "95%",
            height: "38px",
          }}
          enableSearch={true}
          value={value}
          onChange={onChange}
        />
      </div>
      {errors && <span className="text-danger errors">{errors[0]}</span>}
    </>
  );
};

export const DateInput = ({
  label,
  required,
  onChange,
  minDate = null,
  disabled=false,
  id,
  maxDate = null,
  value,
  errors,
  name,
}) => {
  return (
    <>
      <div className="form-group mt-3" id={id}>
        <label className="form-label">
          {label}
          {required ? "*" : null}
        </label>
        <input
          type="date"
          name={name}
          disabled={disabled}
          min={moment(minDate).format("YYYY-MM-DD")}
          max={moment(maxDate).format("YYYY-MM-DD")}
          className="form-control"
          value={value}
          onKeyDown={(e) => e.preventDefault()}
          onChange={onChange}
        />
      </div>
      {errors && <span className="text-danger errors">{errors[0]}</span>}
    </>
  );
};
