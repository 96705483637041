/* eslint-disable */

// <----- API URL  -------> 
export const APIUrl = {
    login: '/lg/authorisation/login',
};

// <----- Environment types  -------> 
export const ENV_TYPE = {
    DEV: 'dev',
    PROD: 'prod',
    UAT: 'uat'
};

// <----- Environments  -------> 
export const ENV = {
    ['dev']: 'https://briisk-i-dev-api.azure-api.net',
    ['uat']: 'https://briisk-i-uat-api.azure-api.net',
    ['prod']: 'https://briisk-i-prod-api.azure-api.net',
};


export const OCP_APIM_SUBSCRIPTION_KEY = {
    ['dev']: 'ec2659d3859d421d801776e4efad5780',
    ['uat']: '3296a582dbb540b8ae4843332c638a3e',
    ['prod']: 'd6cd16e3f619492bbc3f75fccf0aba6c'
}

export const PAYMENT_SUBSCRIPTION_KEY = {
  ["dev"]: "20ecf8f763c14d7b94f89300c44d1fee",
  ["uat"]: "718f9d21436e4c759b4571c5e0358944",
  ["prod"]: "e3df319f368e446d9cfde10e8661acc3",
};

export const PRODUCT_INSTANCE_ID = {
    ['dev']: '66c6fd08-ba25-45d5-a76c-d6220946f720',
    ['uat']: '67572a10-fdba-4ae2-9e74-bd5654b0ef4d', 
    ['prod']: 'a0e70681-813b-4da3-8b63-ae9c809d1379'
}



export const BANK_BRANCH_GUID = {
    ['dev']: 'F5D22457-30FC-8FC4-90DB-BC43DC258464',
    ['uat']: '201413A3-4410-B6C1-498E-7FD5D7100874',
    ['prod']: '7b9d8f0d-62fe-cf02-d6cf-0e5dafccef01'
}

export const MIDDLEWARE_URL = {
  ["dev"]: "https://react-middleware-dev.azurewebsites.net",
//   ['dev']: 'http://localhost:5000',
  ["uat"]: "https://react-middleware-uat.azurewebsites.net",
  //['uat']: 'http://localhost:8000',
  ["prod"]: "https://react-middleware-prod.azurewebsites.net",
//   ['prod']: 'http://localhost:8000',
};

/* eslint-disable */

