/* eslint-disable jsx-a11y/anchor-is-valid */
// default imports for react project
import React, { useState } from "react";

// import default style component from react-bootstrap package
import { Col, Container, Row, Button } from "react-bootstrap";

// Import image from assets folder
import {
  comingSoon,
  main,
  FullMain,
  liberty,
  dateIcon,
  sanlam,
  box,
} from "../../assets/images";

//   import custom component
import BodyButton from "../../components/Buttons/BodyButton";
import Card2 from "../../components/Card/Card2";
import { DateInput } from "../../components";

// import util to get current window dimensions
import useWindowDimensions from "../../utils/useWindowDimension";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  getProductRisks,
  getProductRisksAttributes,
  updatedAttributes,
  getProductTypeDetails,
  getProductAttachments,
  updateCustomAttributes,
} from "../../store/actions/products";
import { getAgentOnboarding, login } from "../../store/actions/auth";
import { ATTRIBUTES, RISKS } from "../../utils/constants";
import { calculator } from "../../components/calculator";
import Loader from "../../components/Loader";
import moment from "moment";
import { toast } from "react-toastify";
import { TwoButtons } from "../../components";
import style from "../Forms/Form.module.css";
import { radioChecked, radioUnchecked } from "../../assets/images";
import {trackUserEvents } from "../../utils/utils";

// React functional component starts here..
function Homepage({ isHomeLoading }) {
  // scroll window to top of page on navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const history = useNavigate();

  const agentCode = useSelector((state) => state.auth.agentCode);

  const [errors, setErrors] = useState({});

  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);

  const [coverOption, setCoverOption] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [homeLoading, setHomeLoading] = useState(false);

  const state = useSelector((state) => state.products.riskAttributes);
  const [selectedOption, setSelectedOption] = useState(null);

  const getAttributes = (value) => {
    return riskAttributes?.find((x) => x.name === value);
  };
  // Risk from the Product
  const risks = useSelector((state) =>
    state.products?.risks?.risks?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  // Risk attributes from the Product i.e. BUD, CEU, ICU, etc.
  const risksAttributes = useSelector((state) =>
    state.products?.riskAttributes?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );
  const countDown =
    useSelector((state) => state?.products?.countDown?.count) ?? 0;

  useEffect(() => {
    dispatch({ type: "PAYMENT_SUCCESS", paymentSuccess: false });
  }, []);

  const getInfo = () => {
    return new Promise(async (resolve, reject) => {
      try {
        await dispatch(login());
        if (Object.keys(agentOnBoarding || {}).length === 0) {
          await dispatch(getAgentOnboarding(agentCode));
        }
        await dispatch(getProductRisks());
        await dispatch(getProductAttachments());
        if (risksAttributes.length <= 3) {
          await dispatch(getProductTypeDetails());
          risks?.forEach(async (risk, index) => {
            await dispatch(getProductRisksAttributes(risk.instanceId));
          });
        } else {
          setIsLoading(false);
        }
        resolve();
      } catch (err) {
        reject();
      }
    });
  };

  
  const riskAttributes = useSelector(
    (state) =>
      state?.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const getProductAttributes = () => {
    return new Promise(async (resolve, reject) => {
      try {
        risks?.forEach(async (risk, index) => {
          await dispatch(getProductRisksAttributes(risk.instanceId));
          if (risks?.length - 1 === index) {
            resolve();
          }
        });
      } catch (err) {
        reject();
      }
    });
  };

  const customAttributes = useSelector(
    (state) => state?.products?.customAttributes
  );

  const handleSubmit = async () => {
    try {
      if (!coverOption && !dob) {
        return setErrors({ dob: ["Date of birth is required"], coverOption: ["Cover Option is required"] });
      }
      if (!coverOption) {
        return setErrors({ coverOption: ["Cover Option is required"] });
      }
      if (!dob) {
        return setErrors({ dob: ["Date of birth is required"] });
      }
      setIsLoading(true);
      // if (Object.keys(agentOnBoarding || {}).length === 0) {
      //   await dispatch(getAgentOnboarding(agentCode));
      // }

      await dispatch(login());
      if (Object.keys(agentOnBoarding || {}).length === 0) {
        await dispatch(getAgentOnboarding(agentCode));
      }
      const riskRes = await dispatch(getProductRisks());
      await dispatch(getProductTypeDetails());
      riskRes?.risks?.forEach(async (risk, index) => {
        await dispatch(getProductRisksAttributes(risk.instanceId));
        if (riskRes?.risks?.length - 1 === index) {
          history("/forms");
          //trackUserEvents
          trackUserEvents("na_insurnace_get_a_quote_cta", {});
          setIsLoading(false);
        }
      });
      dispatch(
        updateCustomAttributes({
          type: "CUSTOM_ATTRIBUTES",
          payload: {
            ...customAttributes,
            coverOption,
            dob,
          },
        })
      );
      return;
      var arr = [...riskAttributes];
      var index = riskAttributes.findIndex((x) => x.name === "Date of Birth");
      arr[index] = { ...arr[index], value: dob };
      dispatch(updatedAttributes(arr, "QUQ"));

      history("/forms");
    } catch (err) {
      console.log(err);
    }
  };

  const [dob, setDOB] = useState(null);

  const calculateMinDate = (type) => {
    let date = new Date();
    date.setFullYear(date.getFullYear() - 65);
    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const calculateaMaxDate = (type) => {
    let date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const renderCoverOptions = () => {
    return (
      <div style={{width: '100%'}}>
        <div
          className={
            coverOption === "Just Me" ? style.optionsActive1 : style.options1
          }
          onClick={() => {
            setCoverOption("Just Me");
          }}
        >
          <div>
            <img
              src={
                coverOption === "Just Me" ? radioChecked : radioUnchecked
              }
            />
          </div>

          <p className="primary-color f-700">Just Me</p>
        </div>
        <div
          className={
            coverOption === "Single Parent"
              ? style.optionsActive1
              : style.options1
          }
          onClick={() => {
            setCoverOption("Single Parent");
          }}
        >
          <div>
            <img
              src={
                coverOption === "Single Parent" ? radioChecked : radioUnchecked
              }
            />
          </div>
          <p className="primary-color f-700">Single Parent</p>
        </div>
        <div
          className={
            coverOption === "Family" ? style.optionsActive1 : style.options1
          }
          onClick={() => {
            setCoverOption("Family");
          }}
        >
          <div>
            <img
              src={coverOption === "Family" ? radioChecked : radioUnchecked}
            />
          </div>
          <p className="primary-color f-700">Family</p>
        </div>
      </div>
    );
  };

  return (
    <div>
      {width > 1024 ? (
        <Container>
          <div className="homepage-banner-pattern">
            <Row className="py-3 ">
              <Col lg={5} xs={12} className="text-center main__img_container">
                <img className="main__img" src={FullMain} alt="main" />
              </Col>
              <Col lg={7} xs={12} className="homepage__main__text">
                <Row className="homepage1__heading1 app__header__one m-0 justify-content-center">
                Family Protection Plan
                </Row>
                <Row
                  className="homepage1__heading2 mt-3 ml-0 mr-0 mb-0 pt-1 justify-content-center"
                >
                  <p style={{ textAlign: "center" }}>
                    Funerals often leave families in debt. Have you ever thought
                    of how your <br /> family will be able to afford your
                    funeral?
                  </p>
                </Row>
                <div className="d-flex justify-content-center mt-4">
                  <span style={{ color: "#FBD105" }}> Get a quote now!</span>
                </div>
              </Col>
            </Row>
          </div>
          <Row className="mt-0">
            <Col sm={12}>
              <Col lg xs={12}>
                <Row className="m-0 pb-5 card__homepage">
                  <div
                    style={{
                      backgroundColor: "#E5E5F5",
                      padding: width < 430 ? "0px" : "",
                    }}
                  >
                    <Row className="m-4">
                      <Col lg={10}>
                        <h3 style={{ fontSize: "26pt", color: "#2F2E80" }}>
                          It's never been easier to get insurance cover.{" "}
                        </h3>
                        <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>
                          if something was to happen to you, you can make sure
                          your family is protected
                        </h6>
                      </Col>
                      <Col lg={2}>
                        <Row>
                          <h6
                            style={{
                              color: "#56616C",
                              fontSize: "10pt",
                              fontWeight: "bold",
                              textAlign: "center",
                              marginTop: "15px",
                            }}
                          >
                            Policy underwritten by
                          </h6>
                        </Row>
                        <Row>
                          <div
                            className={`d-flex ${
                              width > 770
                                ? "justify-content-start"
                                : "justify-content-center"
                            }`}
                          >
                            <img
                              src={sanlam}
                              style={{
                                marginLeft: 36.2,
                                width: "95px",
                              }}
                              alt="Sanlam Logo"
                            />
                          </div>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <hr
                        style={{
                          color: "white",
                          height: 2,
                          width: "97%",
                          margin: "auto",
                        }}
                      />
                    </Row>
                    <Row className="secondary-container p-3 ">
                      <Row className="secondary-container p-3 ">
                        <Col lg={7}>
                          <Row>
                            <Col lg={3} style={{ textAlign: "right" }}>
                              <img
                                src={box}
                                style={{
                                  width: "60%",
                                }}
                                alt="box"
                              />
                            </Col>
                            <Col lg={9}>
                              <h4
                                style={{
                                  fontSize: "20px",
                                  color: "#2F2E80",
                                  fontWeight: "bold",
                                  marginBottom: "15px",
                                }}
                              >
                                Family Protection Plan
                              </h4>
                              <span
                                style={{ color: "#2F2E80", fontWeight: "bold" }}
                              >
                                Optional covers:
                              </span>
                              <ul>
                                {data2.map((d) => (
                                  <li
                                    style={{
                                      color: "#2F2E80",
                                      paddingRight: "13%",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <span>{d}</span>
                                  </li>
                                ))}
                              </ul>
                            </Col>
                          </Row>
                        </Col>

                        <Col
                          lg={5}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <div
                            className="whiteoverlay_card pl-5 pr-5 pt-4 pb-4 d-flex align-items-center flex-column"
                            style={{ height: "100%" }}
                          >
                            <Row
                              style={{
                                paddingLeft: "0px",
                                paddingRight: "0px",
                              }}
                            >
                              <h4
                                style={{
                                  fontSize: "20px",
                                  color: "white",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  paddingLeft: "0px",
                                  paddingRight: "0px",
                                }}
                              >
                                Family Protection Plan
                              </h4>
                            </Row>
                            <Row>
                              <h4
                                style={{
                                  fontSize: "11pt",
                                  textAlign: "center",
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                              >
                                Cover options for Individuals & Families
                                <br />
                              </h4>
                            </Row>
                            <Row>
                              <div className="homepage_dob_container">
                                {renderCoverOptions()}
                                {errors?.coverOption ? (
                                  <span
                                    className="errors"
                                    style={{ color: "#fff" }}
                                  >
                                    {errors?.coverOption?.[0]}
                                  </span>
                                ) : (
                                  <></>
                                )}
                                <div style={{height: 20}} />
                                <p
                                  className="text-white f-700 m-0"
                                  style={{ fontSize: "11pt" }}
                                >
                                  When were you born?*
                                </p>
                                <input
                                  type="date"
                                  max={moment(calculateaMaxDate()).format(
                                    "YYYY-MM-DD"
                                  )}
                                  min={moment(calculateMinDate()).format(
                                    "YYYY-MM-DD"
                                  )}
                                  style={{
                                    margin: 5,
                                    width: "250px",
                                    height: "34px",
                                  }}
                                  onKeyDown={(e) => e.preventDefault()}
                                  onChange={(e) => {
                                    dispatch(
                                      updateCustomAttributes({
                                        type: "CUSTOM_ATTRIBUTES",
                                        payload: {
                                          ...customAttributes,
                                          dateOfBirth: e.target.value,
                                        },
                                      })
                                    );
                                    setDOB(e.target.value);
                                  }}
                                  value={dob}
                                />
                                {errors?.dob ? (
                                  <span
                                    className="errors"
                                    style={{ color: "#fff" }}
                                  >
                                    {errors?.dob?.[0]}
                                  </span>
                                ) : (
                                  <></>
                                )}
                                <div style={{height: 20}} />
                                <button onClick={handleSubmit} className="mt-3">
                                  {isLoading ? "Quoting..." : "Get a quote"}
                                </button>
                              </div>
                            </Row>
                            <Row
                              className="justify-content-center"
                              style={{
                                fontSize: "11pt",
                                color: "white",
                                textAlign: "center",
                                marginTop: "8px",
                                fontWeight: "bold",
                                marginBottom: "25px",
                              }}
                            >
                              From: N$60 / monthly
                            </Row>
                            <hr
                              style={{
                                color: "white",
                                height: 1,
                                width: "97%",
                                margin: "auto",
                                marginTop: "20px",
                              }}
                            />
                            <Row
                              className="justify-content-center"
                              style={{
                                fontSize: "8pt",
                                color: "white",
                                textAlign: "center",
                                marginBottom: "5px",
                                padding: "14px",
                                fontWeight: "bold",
                              }}
                            >
                              By opting to buy insurance, Letshego will share
                              the required information with the insurer to
                              facilitate your policy purchase.
                            </Row>
                            <Row>
                              <h6
                                style={{
                                  color: "white",
                                  fontSize: "10pt",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                }}
                              >
                                Policy underwritten by
                              </h6>
                            </Row>
                            <Row>
                              <div
                                className={`d-flex ${
                                  width > 770
                                    ? "justify-content-start"
                                    : "justify-content-center"
                                }`}
                              >
                                <img
                                  src={sanlam}
                                  style={{
                                    width: "95px",
                                  }}
                                  alt="sanlam"
                                />
                              </div>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Row>
                  </div>
                </Row>
              </Col>
            </Col>
          </Row>
        </Container>
      ) : (
        <div className="homepage-banner-pattern">
          <Row
            className="py-3 justify-content-center"
            style={{ margin: "15px" }}
          >
            <Col lg={7} xs={12} className="homepage__main__text">
              <Row className="homepage1__heading1 app__header__one m-0 justify-content-center">
                Namibia Family Protection Plan
              </Row>
              <Row
                className="homepage1__heading2 m-0 pt-1 justify-content-center"
                style={{ marginLeft: "16px" }}
              >
                The loss of a loved one is a difficult and emotional time for
                family members left behind
              </Row>
            </Col>
            <Col
              lg={5}
              xs={12}
              className="text-center main__img_container"
              style={{ marginBottom: "30px" }}
            >
              <img className="main__img" src={FullMain} alt="main" />
            </Col>
            <Row
              className="justify-content-center"
              style={{ backgroundColor: "#59599A", borderRadius: "5px" }}
            >
              <Row
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingTop: "15px",
                }}
              >
                <h4
                  style={{
                    fontSize: "20px",
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "center",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  Namibia Family Funeral
                </h4>
              </Row>
              <Row>
                <h6
                  style={{
                    fontSize: "10pt",
                    textAlign: "center",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Cover options for Individuals & Families
                  <br />
                </h6>
              </Row>
              <Row>
                <div className="white_formbox d-flex justify-content-center flex-column">
                  <div className="mt-3">
                  {renderCoverOptions()}
                  </div>
                  <div className="text-center text-white mt-2">
                    When were you born?*
                  </div>
                  <div className="d-flex  justify-content-center align-items-center flex-column mt-0 w-100">
                    <input
                      type="date"
                      max={moment(calculateaMaxDate()).format("YYYY-MM-DD")}
                      min={moment(calculateMinDate()).format("YYYY-MM-DD")}
                      onKeyDown={(e) => e.preventDefault()}
                      style={{
                        margin: 5,
                        width: "250px",
                        height: "34px",
                        backgroundColor: "white",
                      }}
                      onChange={(e) => {
                        dispatch(
                          updateCustomAttributes({
                            type: "CUSTOM_ATTRIBUTES",
                            payload: {
                              ...customAttributes,
                              dateOfBirth: e.target.value,
                            },
                          })
                        );
                        setDOB(e.target.value);
                      }}
                      value={dob}
                      className="mt-3"
                    />
                    {errors?.dob ? (
                      <span className="errors" style={{ color: "#fff" }}>
                        {errors?.dob?.[0]}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      onClick={handleSubmit}
                      className="btn btn-warning quote_button"
                      style={{ color: "#2F2E80", fontWeight: "bold" }}
                    >
                      Get a quote
                    </Button>
                  </div>
                </div>
              </Row>
              <Row
                className="justify-content-center"
                style={{
                  fontSize: "11pt",
                  color: "white",
                  textAlign: "center",
                  marginTop: "20px",
                  fontWeight: "bold",
                }}
              >
                From: N$60 / monthly
              </Row>
              <Row className="justify-content-center mt-3 text-white">
                <h4
                  style={{
                    fontSize: "11pt",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "20px",
                    marginBottom: "8px",
                  }}
                >
                  Optional covers:
                </h4>
              </Row>
              <Row className="justify-content-center text-center higlight_text">
                <h4
                  style={{
                    fontSize: "11pt",
                    fontWeight: "bold",
                    marginBottom: "15px",
                  }}
                >
                  Policyholder, spouse and a maximum of 4 children with a choice
                  to add extended family members.
                </h4>
              </Row>
              <Row className="justify-content-center mt-3 text-center text-white">
                <h4
                  style={{
                    fontSize: "11pt",
                    fontWeight: "bold",
                    marginBottom: "25px",
                  }}
                >
                  All valid claims are paid in less than 48 hours after all the
                  required claim documents have been submitted.
                </h4>
              </Row>

              <hr
                style={{
                  color: "white",
                  height: 1,
                  width: "97%",
                  margin: "auto",
                  marginTop: "20px",
                }}
              />
              <Row
                className="justify-content-center"
                style={{
                  fontSize: "8pt",
                  color: "white",
                  textAlign: "center",
                  marginBottom: "5px",
                  padding: "14px",
                  fontWeight: "bold",
                }}
              >
                By opting to buy insurance, Letshego will share the required
                information with the insurer to facilitate your policy purchase.
              </Row>
              <Row>
                <h6
                  style={{
                    color: "white",
                    fontSize: "10pt",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Policy underwritten by
                </h6>
              </Row>
              <Row>
                <div
                  className={`d-flex ${
                    width > 770
                      ? "justify-content-start"
                      : "justify-content-center"
                  }`}
                >
                  <img
                    src={sanlam}
                    style={{
                      marginBottom: 20,
                      width: "95px",
                    }}
                    alt="liberty"
                  />
                </div>
              </Row>
            </Row>
          </Row>
        </div>
      )}
      {isLoading && (
        <div
          style={{
            height: "99vh",
            width: "100%",
            zIndex: 100,
            backgroundColor: "#00000050",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "0px",
          }}
        >
          <div style={{ height: 100, width: 100 }}>
            <CircularProgressbar
              styles={buildStyles({
                textColor: "#fbcf2c",
                pathColor: "#fbcf2c",
              })}
              value={countDown}
              text={`${countDown}%`}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Homepage;

const data2 = [
  "Policyholder, spouse and a maximum of 4 children with a choice to add extended family members.",
  "All valid claims are paid in less than 48 hours after all the required claim documents have been submitted.",
];
