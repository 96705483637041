import moment from "moment";
import queryString from "query-string";
import { storeQueryParameters } from "../store/actions/auth";
import { store } from "../store/store";
import { CONFIG } from "./constants";

export const dateFormatter = (date) => {
  return moment(
    `${date.slice(7, 11)}-${date.slice(4, 6)}-${date.slice(1, 3)}`
  ).format("YYYY-MM-DD");
};

export const formatCoverAmount = (value) => {
  let a = value?.replace(",", "");
  let b = a.length === 6 ? a.slice(0, 3) : a.slice(0, 4);
  let c = a.length === 6 ? a.slice(3, 6) : a.slice(3, 6);
  return b + " " + c;
};

export const parseQuery = (queryString) => {
  var query = {};
  var pairs = (
    queryString[0] === "?" ? queryString.substr(1) : queryString
  ).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
};

const parseWithoutToken = (obj) => {
  let a = obj;
  delete a.token;
  return a;
};

function parseQueryString(queryString) {
  const urlSearchParams = new URLSearchParams(queryString);
  const queryObject = {};
  for (const [key, value] of urlSearchParams) {
    queryObject[key] = value;
  }
  return queryObject;
}

export const setParam = (history, token, dispatch) => {
  const exist =  window.location.search?.split('&')?.filter((x) => !x.includes('token'));
  if(exist?.length > 1){
    localStorage.setItem('redirect_uri', window.location.search );
  }
  const existingQueries = localStorage.getItem('redirect_uri')
  const result = existingQueries?.split('&').filter(str => !str.includes('token')).join('&');

  history({
    search: `?${queryString.stringify({
      token: token
        ? token
        : process.env.REACT_APP_ENV_TYPE === "UAT" ||
          process.env.REACT_APP_ENV_TYPE === "DEV"
        ? "spana-1"
        : "letsgona-2",
    
    })}&${result}`,
    state: history.state,
  });
};


export const trackUserEvents = (eventName, eventAttributes) => {
  //trackUserEvents
  console.log("Tracking function called", eventName, eventAttributes);
  // Prepare event object
  const dataObject = {
    eventType: "BRIISK_EVENT_POST_MSG",
    eventName: eventName,
    attributes: eventAttributes,
  };

  // Post event data to the parent application where the insurance application runs as an iFrame.
  if (window.parent) {
    // Provide the correct origin of the parent window
    // const targetOrigin = "*";

    // Determine the environment based on the current hostname
    const currentHostname = window.location.hostname;
    let environment;
    if (currentHostname === "localhost") {
      environment = "development";
    } else if (currentHostname === "app.uat.letshego.com") {
      environment = "uat";
    } else {
      environment = "production";
    }
    // Use the determined environment to get the target origin
    const targetOrigin = CONFIG[environment];
    const currentURL = new URL(window.location.href);
    if (currentURL.origin === targetOrigin) {
      // The origins match, you are on the target origin
      console.log("You are on the target URL:");
      window.parent.postMessage(JSON.stringify(dataObject), targetOrigin);
      console.log("Tracking function called endss");
    } else {
      // The origins don't match, you are on a different origin
      console.log("You are on a different URL");
      window.parent.postMessage(JSON.stringify(dataObject), "*");
      console.log("Tracking function called endss");
    }
  }

  return;
};
