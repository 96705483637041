import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import ReactSelect from "react-select";
import {
  DateInput,
  Info,
  NumberInput,
  CountryInput,
  TextInput,
  TwoButtons,
  FileUpload,
} from "../../../components";
import NavigationButtons from "../../../components/NavigationButtons";
import style from "../Form.module.css";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../../utils/useWindowDimension";
import classNames from "classnames";
import {
  CreatePolicy,
  policySaleAttachments,
  updateAccordion,
  updateCustomAttributes,
  updatedAttributes,
} from "../../../store/actions/products";
import { validate } from "validate.js";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import Loader from "../../../components/Loader";
import moment from "moment";
import axios from "axios";
import localforage from "localforage";
import {
  beneficiariesSchema,
  childConstraints,
  extendedConstraints,
  mainMemberConstraints,
  spouseConstraints,
} from "../components/constraints";
import { formatCoverAmount, trackUserEvents } from "../../../utils/utils";

const Step4 = ({ handleNext, handleBack, handleFileUpload, files }) => {

  const mainMember = useSelector((state) => state?.products?.mainMember);
  const [inputs, setInputs] = useState({
    ...mainMember,
    mobilePhoneCode: "+264",
  });

  const dispatch = useDispatch();

  const childState = useSelector((state) => state.products?.childAttributes);

  const parentState = useSelector((state) => state.products?.parentAttributes);

  const extendedFamilyState = useSelector(
    (state) => state.products?.extendedAttributes
  );
  const [childAttributes, setChildAttributes] = useState(childState);

  const [parentAttributes, setParentAttributes] = useState(parentState);

  const [extendedFamilyAttributes, setextendedFamilytAttributes] =
    useState(extendedFamilyState);

  const [beneCount, setBeneCount] = useState(1);

  const riskAttributes = useSelector((state) =>
    state.products?.riskAttributes?.map((x) => ({
      ...x,
      attributes: x.attributes.map((y) => ({ ...y, code: x.code })),
    }))
  );

  const customAttributes = useSelector(
    (state) => state?.products.customAttribues
  );

  useEffect(() => {
    if (customAttributes?.beneCount >= 1) {
      setBeneCount(customAttributes?.beneCount);
    }
  }, []);

  const totalParents = () => {
    var total = riskAttributes
      ?.find((z) => z.code === RISKS.QUQ)
      ?.attributes?.find((x) => x.name === "Cover Option")?.value;
    return total?.toLowerCase()?.includes("two")
      ? 2
      : total?.toLowerCase()?.includes("four")
        ? 4
        : 0;
  };

  const handleChange = (evt, instanceId, code) => {
    var attribute = riskAttributes.find((x) => x.code === code)?.attributes;
    var arr = [...attribute];
    var index = attribute.findIndex((x) => x.instanceId === instanceId);
    arr[index] = { ...arr[index], value: evt };
    dispatch(updatedAttributes(arr, code));
  };

  const handleChangePolicy = (e, name) => {
    let obj = { ...inputs };
    if (name) {
      obj = {
        ...inputs,
        [name]: `+${e}`,
      };
    } else {
      obj = {
        ...inputs,
        [e.target.name]: e.target.value,
      };
    }
    setInputs(obj);
    dispatch(
      updateCustomAttributes({
        type: "MAIN_MEMBER_POLICY",
        payload: obj,
      })
    );
  };

  const handleCustomChange = (evt, name, index) => {
    var arr = [...childAttributes];
    arr[index] = { ...arr[index], [name]: evt };
    setChildAttributes(arr);
    dispatch(
      updateCustomAttributes({
        type: "CHILD_ATTRIBUTES",
        payload: arr,
      })
    );
  };

  const handleCustomParentChange = (evt, name, index) => {
    var arr = [...parentAttributes];
    arr[index] = { ...arr[index], [name]: evt };
    setParentAttributes(arr);
    dispatch(
      updateCustomAttributes({
        type: "PARENT_ATTRIBUTES",
        payload: arr,
      })
    );
  };

  const handleCustomExtendedChange = (evt, name, index) => {
    var arr = [...extendedFamilyAttributes];
    arr[index] = { ...arr[index], [name]: evt };
    setextendedFamilytAttributes(arr);
    dispatch(
      updateCustomAttributes({
        type: "EXTENDED_FAMILY_ATTRIBUTES",
        payload: arr,
      })
    );
  };

  const handleSpouseChange = (e) => {
    let obj = { ...customAttribues, spouseCountryCode: e };
    dispatch(
      updateCustomAttributes({
        type: "CUSTOM_ATTRIBUTES",
        payload: obj,
      })
    );
  };
  const calculateMinDate = (type, value) => {
    const date = new Date();
    if (type === "parent") {
      date.setFullYear(date.getFullYear() - 60);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === undefined) {
      date.setFullYear(date.getFullYear() - 122);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === "spouse") {
      date.setFullYear(date.getFullYear() - 65);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === "extended") {
      date.setFullYear(date.getFullYear() - value ?? 122);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === "guardian") {
      date.setFullYear(date.getFullYear() - 85);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === "bene") {
      date.setFullYear(date.getFullYear() - 122);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    }

    if (type === "child") {
      date.setFullYear(date.getFullYear() - 18);
    } else {
      date.setFullYear(date.getFullYear() - 80);
    }

    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const calculateBeneMinDate = (type) => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 36);
    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const [beneficiaries, setBeneficiaries] = useState([
    {
      firstName: "",
      lastName: "",
      email: "",
      number: "",
      percentage: "",
      dob: new Date(),
      postalAddress: "",
      gender: "",
      country: "",
      telephoneNumber: "",
      idNumber: "",
      "Telephone Number Code": "+264",
      "Phone Number Code": "+264",
    },
  ]);

  const calculateBeneMaxDate = () => {
    const date = new Date();
    return new Date();
  };

  const calculateMaxDate = (type, value) => {
    const date = new Date();
    if (type === "parent") {
      date.setFullYear(date.getFullYear() - 18);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === undefined) {
      // Spouse -> undefined
      date.setFullYear(date.getFullYear() - 18);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === "spouse") {
      date.setFullYear(date.getFullYear() - 18);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === "extended") {
      date.setFullYear(date.getFullYear() - value ?? 18);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === "guardian") {
      date.setFullYear(date.getFullYear() - 18);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    } else if (type === "bene") {
      date.setFullYear(date.getFullYear() - 18);
      return new Date(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    }

    if (type === "child") {
      return new Date();
    } else {
      date.setFullYear(date.getFullYear() - 18);
    }

    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  const [errors, setErrors] = useState({});
  const renderComponents = useCallback(
    (item, custom, index, item2) => {
      switch (item.dataType) {
        case 1:
          var type = ""
          if(item.name === "Extended Family ID Number" || item.name === "Spouse Identification Number"){
            type = "number"
          }
          return (
            <TextInput
              id={item.name}
              label={item.description}
              type={type}
              errors={
                custom
                  ? errors?.[`${item?.name} ${index + 1}`] ??
                  errors?.[`${item?.name}`]
                  : errors?.[item?.name]
              }
              name={item.name}
              value={
                custom === "child"
                  ? childState?.[index]
                    ? childState?.[index]?.[item?.name]
                    : null
                  : custom === "parent"
                    ? parentState?.[index]
                      ? parentState?.[index]?.[item?.name]
                      : null
                    : custom === "extended"
                      ? extendedFamilyState?.[index]
                        ? extendedFamilyState?.[index]?.[item?.name]
                        : null
                      : custom === "bene"
                        ? beneficiaries?.[index]
                          ? beneficiaries[index]?.[item?.name]
                          : null
                        : item?.value
              }
              onChange={(e) => {
                if (e.target?.value?.length <= 30) {
                  custom === "child"
                    ? handleCustomChange(e.target.value, item.name, index)
                    : custom === "parent"
                      ? handleCustomParentChange(e.target.value, item.name, index)
                      : custom === "extended"
                        ? handleCustomExtendedChange(
                          e.target.value,
                          item.name,
                          index
                        )
                        : custom === "bene"
                          ? handleChangeBene(e, index)
                          : handleChange(e.target.value, item.instanceId, item.code);
                }
              }}
              required={item.isRequired}
            />
          );
        case 2:
          return item?.name?.toLowerCase()?.includes("mobile") ? (
            <div className="row">
              {width > 1024 ? (
                <>
                  <div className="col-4">
                    <CountryInput
                      id={"mobilePhoneCode"}
                      label={"Country code"}
                      name={"mobilePhoneCode"}
                      errors={
                        custom === "spouse" ? errors?.["spouseCountryCode"] : []
                      }
                      value={
                        custom === "spouse"
                          ? customAttributes?.spouseCountryCode
                          : inputs?.mobilePhoneCode ?? "+264"
                      }
                      placeholder={"+264"}
                      onChange={(e) =>
                        custom === "child"
                          ? handleCustomChange(e, "mobilePhoneCode", index)
                          : custom === "parent"
                            ? handleCustomParentChange(
                              e,
                              "mobilePhoneCode",
                              index
                            )
                            : custom === "extended"
                              ? handleCustomExtendedChange(
                                e,
                                "mobilePhoneCode",
                                index
                              )
                              : custom === "spouse"
                                ? handleSpouseChange(e, null, item.code)
                                : handleChange(e, null, item.code)
                      }
                      required={false}
                      country={true}
                    />
                  </div>
                  <div className="col-8">
                    <NumberInput
                      id={item.name}
                      label={item.description}
                      name={item.name}
                      errors={
                        custom === "spouse"
                          ? errors?.[`${item?.name}`]
                          : errors?.["mobilePhone"]
                      }
                      type="number"
                      value={
                        custom === "child"
                          ? childState?.[index]
                            ? childState?.[index][item?.name]
                            : null
                          : custom === "parent"
                            ? parentState?.[index]
                              ? parentState?.[index]?.[item?.name]
                              : null
                            : custom === "extended"
                              ? extendedFamilyState?.[index]
                                ? extendedFamilyState?.[index]?.[item?.name]
                                : null
                              : item?.value
                      }
                      onChange={(e) => {
                        if (e?.target?.value?.length <= 12) {
                          custom === "child"
                            ? handleCustomChange(
                              e.target.value,
                              item.name,
                              index
                            )
                            : custom === "parent"
                              ? handleCustomParentChange(
                                e.target.value,
                                item.name,
                                index
                              )
                              : custom === "extended"
                                ? handleCustomExtendedChange(
                                  e.target.value,
                                  item.name,
                                  index
                                )
                                : handleChange(
                                  e.target.value,
                                  item.instanceId,
                                  item.code
                                );
                        }
                      }}
                      required={true}
                      mobile={true}
                    />
                  </div>
                </>
              ) : (
                  <>
                    <div className="col-12">
                      <CountryInput
                        id={"mobilePhoneCode"}
                        label={"Country code"}
                        name={"mobilePhoneCode"}
                        errors={
                          custom === "spouse" ? errors?.["spouseCountryCode"] : []
                        }
                        value={
                          custom === "spouse"
                            ? customAttributes?.spouseCountryCode
                            : inputs?.mobilePhoneCode ?? "+264"
                        }
                        placeholder={"+264"}
                        onChange={(e) =>
                          custom === "child"
                            ? handleCustomChange(e, "mobilePhoneCode", index)
                            : custom === "parent"
                              ? handleCustomParentChange(
                                e,
                                "mobilePhoneCode",
                                index
                              )
                              : custom === "extended"
                                ? handleCustomExtendedChange(
                                  e,
                                  "mobilePhoneCode",
                                  index
                                )
                                : custom === "spouse"
                                  ? handleSpouseChange(e, null, item.code)
                                  : handleChange(e, null, item.code)
                        }
                        required={false}
                        country={true}
                      />
                    </div>
                    <div className="col-12">
                      <NumberInput
                        id={item.name}
                        label={item.description}
                        name={item.name}
                        type="number"
                        errors={
                          custom === "spouse"
                            ? errors?.[`${item?.name}`]
                            : errors?.["mobilePhone"]
                        }
                        value={
                          custom === "child"
                            ? childState?.[index]
                              ? childState?.[index][item?.name]
                              : null
                            : custom === "parent"
                              ? parentState?.[index]
                                ? parentState?.[index]?.[item?.name]
                                : null
                              : custom === "extended"
                                ? extendedFamilyState?.[index]
                                  ? extendedFamilyState?.[index]?.[item?.name]
                                  : null
                                : item?.value
                        }
                        onChange={(e) => {
                          if (e?.target?.value?.length <= 12) {
                            custom === "child"
                              ? handleCustomChange(
                                e.target.value,
                                item.name,
                                index
                              )
                              : custom === "parent"
                                ? handleCustomParentChange(
                                  e.target.value,
                                  item.name,
                                  index
                                )
                                : custom === "extended"
                                  ? handleCustomExtendedChange(
                                    e.target.value,
                                    item.name,
                                    index
                                  )
                                  : handleChange(
                                    e.target.value,
                                    item.instanceId,
                                    item.code
                                  );
                          }
                        }}
                        required={true}
                        mobile={true}
                      />
                    </div>
                  </>
                )}
            </div>
          ) : (
              <NumberInput
                id={item.name}
                label={item.description}
                errors={custom ? "" : errors[item.name]}
                name={item.name}
                placeholder={"+254123456789"}
                type="number"
                value={
                  custom === "child"
                    ? childState?.[index]
                      ? childState?.[index][item?.name]
                      : null
                    : custom === "parent"
                      ? parentState?.[index]
                        ? parentState?.[index]?.[item?.name]
                        : null
                      : custom === "extended"
                        ? extendedFamilyState?.[index]
                          ? extendedFamilyState?.[index]?.[item?.name]
                          : null
                        : item?.value
                }
                onChange={(e) =>
                  custom === "child"
                    ? handleCustomChange(e.target.value, item.name, index)
                    : custom === "parent"
                      ? handleCustomParentChange(e.target.value, item.name, index)
                      : custom === "extended"
                        ? handleCustomExtendedChange(e.target.value, item.name, index)
                        : handleChange(e.target.value, item.instanceId, item.code)
                }
                required={item.isRequired}
              />
            );
        case 5:
          return (
            <DateInput
              id={item.name}
              label={item.description}
              errors={
                custom
                  ? custom === "child"
                    ? errors[`Child DOB ${index + 1}`]
                    : custom === "spouse"
                      ? errors?.["Spouse DOB"]
                      : errors[`${item.name} ${index + 1}`]
                  : errors[item.name]
              }
              name={item.name}
              minDate={calculateMinDate(custom, item2?.max)}
              maxDate={calculateMaxDate(custom, item2?.min)}
              value={
                custom === "child"
                  ? childState?.[index]
                    ? childState?.[index]?.[item?.name]
                    : null
                  : custom === "parent"
                    ? parentState?.[index]
                      ? parentState?.[index]?.[item?.name]
                      : null
                    : custom === "extended"
                      ? extendedFamilyState?.[index]
                        ? extendedFamilyState?.[index]?.[item?.name]
                        : null
                      : custom === "bene"
                        ? beneficiariesState?.[index]
                          ? beneficiariesState?.[index]?.[item?.name]
                          : null
                        : item?.value
              }
              onChange={(e) =>
                custom === "child"
                  ? handleCustomChange(e.target.value, item.name, index)
                  : custom === "parent"
                    ? handleCustomParentChange(e.target.value, item.name, index)
                    : custom === "extended"
                      ? handleCustomExtendedChange(e.target.value, item.name, index)
                      : custom === "bene"
                        ? handleChangeBene(e, index)
                        : handleChange(e.target.value, item.instanceId, item.code)
              }
              required={item.isRequired}
            />
          );
        case 7:
          if (
            item.settings.includes('["Yes","No"]') ||
            item.settings.includes('["NO","YES"]')
          ) {
            return <></>;
          }
          return item?.name?.toLowerCase().includes("gender") ||
            item?.name?.toLowerCase().includes("document") ? (
              <>
                <div style={{ height: "10px" }} />
                <TwoButtons
                  id={item.name}
                  title={item.description}
                  isField={true}
                  required={item.isRequired}
                  half={true}
                  errors={[]}
                  state={
                    custom === "child"
                      ? childState?.[index]
                        ? childState?.[index]?.[item?.name]
                        : null
                      : custom === "parent"
                        ? parentState?.[index]
                          ? parentState?.[index]?.[item?.name]
                          : null
                        : custom === "extended"
                          ? extendedFamilyState?.[index]
                            ? extendedFamilyState?.[index]?.[item?.name]
                            : null
                          : custom === "bene"
                            ? beneficiariesState?.[index]
                              ? beneficiariesState?.[index]?.[item?.name]
                              : null
                            : item?.value
                  }
                  onClick={(e) =>
                    custom === "child"
                      ? handleCustomChange(e, item.name, index)
                      : custom === "parent"
                        ? handleCustomParentChange(e, item.name, index)
                        : custom === "extended"
                          ? handleCustomExtendedChange(e, item.name, index)
                          : custom === "bene"
                            ? handleChangeBene(e, index)
                            : handleChange(e, item.instanceId, item.code)
                  }
                  options={JSON.parse(item.settings)?.items?.map((x) => ({
                    name: x,
                    value: x,
                  }))}
                />
                {(custom
                  ? custom === "spouse"
                    ? errors?.[item.name]
                    : errors[`${item.name} ${index + 1}`]
                  : errors[item.name]
                )?.length && (
                    <span className="text-danger errors" style={{ marginLeft: 0 }}>
                      {(custom
                        ? custom === "spouse"
                          ? errors[item.name]
                          : errors[`${item.name} ${index + 1}`]
                        : errors[item.name]) &&
                        (custom
                          ? custom === "spouse"
                            ? errors[item.name]
                            : errors[`${item.name} ${index + 1}`]
                          : errors[item.name])[0]}
                    </span>
                  )}
              </>
            ) : (
              <div className="d-flex flex-column mt-2">
                <label className="form-label mt-1">
                  {item.description}
                  {item?.isRequired ? "*" : null}
                </label>
                <ReactSelect
                  id={item.name}
                  styles={customStyles}
                  placeholder={""}
                  value={
                    custom === "child"
                      ? childState?.[index]
                        ? {
                          label: childState?.[index]?.[item?.name],
                          value: childState?.[index]?.[item?.name],
                        }
                        : null
                      : custom === "parent"
                        ? parentState?.[index]
                          ? {
                            label: parentState?.[index]?.[item?.name],
                            value: parentState?.[index]?.[item?.name],
                          }
                          : null
                        : custom === "extended"
                          ? extendedFamilyState?.[index]
                            ? {
                              label: extendedFamilyState?.[index]?.[item?.name],
                              value: extendedFamilyState?.[index]?.[item?.name],
                            }
                            : null
                          : custom === "bene"
                            ? beneficiariesState?.[index]
                              ? {
                                label: beneficiariesState?.[index]?.[item?.name],
                                value: beneficiariesState?.[index]?.[item?.name],
                              }
                              : null
                            : { label: item?.value, value: item?.value }
                  }
                  options={
                    item?.name.toLowerCase()?.includes("passport") ||
                      item?.name.toLowerCase()?.includes("id")
                      ? countries
                      : JSON.parse(item.settings)?.items?.map((x) => ({
                        label: x,
                        value: x,
                      }))
                  }
                  defaultValue={{
                    label: JSON.parse(item.settings)?.items?.[0],
                    value: JSON.parse(item.settings)?.items?.[0],
                  }}
                  onChange={(e) =>
                    custom === "child"
                      ? handleCustomChange(e.value, item.name, index)
                      : custom === "parent"
                        ? handleCustomParentChange(e.value, item.name, index)
                        : custom === "extended"
                          ? handleCustomExtendedChange(e.value, item.name, index)
                          : custom === "bene"
                            ? handleChangeBene(e.value, index)
                            : handleChange(e.value, item.instanceId, item.code)
                  }
                />
                {(custom
                  ? custom === "spouse"
                    ? errors?.[item?.name]
                    : errors[`${item?.name} ${index + 1}`]
                  : errors[item.name]
                )?.length && (
                    <span className="text-danger errors" style={{ marginLeft: 0 }}>
                      {(custom
                        ? custom === "spouse"
                          ? errors?.[item?.name]
                          : errors[`${item?.name} ${index + 1}`]
                        : errors[item.name]) &&
                        (custom
                          ? custom === "spouse"
                            ? errors?.[item?.name]
                            : errors[`${item.name} ${index + 1}`]
                          : errors[item.name])[0]}
                    </span>
                  )}
              </div>
            );

        case 8:
          if (
            item.settings.includes('["Yes","No"]') ||
            item.settings.includes('["NO","YES"]')
          ) {
            return <></>;
          }
          return item?.name?.toLowerCase().includes("gender") ||
            item?.name?.toLowerCase().includes("document") ? (
              <>
                <div style={{ height: "10px" }} />
                <TwoButtons
                  id={item.name}
                  title={item.description}
                  isField={true}
                  required={item.isRequired}
                  half={true}
                  errors={[]}
                  state={
                    custom === "child"
                      ? childState?.[index]
                        ? childState?.[index]?.[item?.name]
                        : null
                      : custom === "parent"
                        ? parentState?.[index]
                          ? parentState?.[index]?.[item?.name]
                          : null
                        : custom === "extended"
                          ? extendedFamilyState?.[index]
                            ? extendedFamilyState?.[index]?.[item?.name]
                            : null
                          : custom === "bene"
                            ? beneficiariesState?.[index]
                              ? beneficiariesState?.[index]?.[item?.name]
                              : null
                            : item?.value
                  }
                  onClick={(e) =>
                    custom === "child"
                      ? handleCustomChange(e, item.name, index)
                      : custom === "parent"
                        ? handleCustomParentChange(e, item.name, index)
                        : custom === "extended"
                          ? handleCustomExtendedChange(e, item.name, index)
                          : custom === "bene"
                            ? handleChangeBene(e, index)
                            : handleChange(e, item.instanceId, item.code)
                  }
                  options={JSON.parse(item.settings)?.items?.map((x) => ({
                    name: x,
                    value: x,
                  }))}
                />
                {(custom
                  ? custom === "spouse"
                    ? errors?.[item.name]
                    : errors[`${item.name} ${index + 1}`]
                  : errors[item.name]
                )?.length && (
                    <span className="text-danger errors" style={{ marginLeft: 0 }}>
                      {(custom
                        ? custom === "spouse"
                          ? errors[item.name]
                          : errors[`${item.name} ${index + 1}`]
                        : errors[item.name]) &&
                        (custom
                          ? custom === "spouse"
                            ? errors[item.name]
                            : errors[`${item.name} ${index + 1}`]
                          : errors[item.name])[0]}
                    </span>
                  )}
              </>
            ) : (
              <div className="d-flex flex-column mt-2">
                <label className="form-label mt-1">
                  {item.description}
                  {item?.isRequired ? "*" : null}
                </label>
                <ReactSelect
                  id={item.name}
                  styles={customStyles}
                  placeholder={""}
                  value={
                    custom === "child"
                      ? childState?.[index]
                        ? {
                          label: childState?.[index]?.[item?.name],
                          value: childState?.[index]?.[item?.name],
                        }
                        : null
                      : custom === "parent"
                        ? parentState?.[index]
                          ? {
                            label: parentState?.[index]?.[item?.name],
                            value: parentState?.[index]?.[item?.name],
                          }
                          : null
                        : custom === "extended"
                          ? extendedFamilyState?.[index]
                            ? {
                              label: extendedFamilyState?.[index]?.[item?.name],
                              value: extendedFamilyState?.[index]?.[item?.name],
                            }
                            : null
                          : custom === "bene"
                            ? beneficiariesState?.[index]
                              ? {
                                label: beneficiariesState?.[index]?.[item?.name],
                                value: beneficiariesState?.[index]?.[item?.name],
                              }
                              : null
                            : { label: item?.value, value: item?.value }
                  }
                  options={
                    item?.name.toLowerCase()?.includes("passport") ||
                      item?.name.toLowerCase()?.includes("id")
                      ? countries
                      : JSON.parse(item.settings)?.items?.map((x) => ({
                        label: x,
                        value: x,
                      }))
                  }
                  defaultValue={{
                    label: JSON.parse(item.settings)?.items?.[0],
                    value: JSON.parse(item.settings)?.items?.[0],
                  }}
                  onChange={(e) =>
                    custom === "child"
                      ? handleCustomChange(e.value, item.name, index)
                      : custom === "parent"
                        ? handleCustomParentChange(e.value, item.name, index)
                        : custom === "extended"
                          ? handleCustomExtendedChange(e.value, item.name, index)
                          : custom === "bene"
                            ? handleChangeBene(e.value, index)
                            : handleChange(e.value, item.instanceId, item.code)
                  }
                />
                {(custom
                  ? custom === "spouse"
                    ? errors?.[item?.name]
                    : errors[`${item?.name} ${index + 1}`]
                  : errors[item.name]
                )?.length && (
                    <span className="text-danger errors" style={{ marginLeft: 0 }}>
                      {(custom
                        ? custom === "spouse"
                          ? errors?.[item?.name]
                          : errors[`${item?.name} ${index + 1}`]
                        : errors[item.name]) &&
                        (custom
                          ? custom === "spouse"
                            ? errors?.[item?.name]
                            : errors[`${item.name} ${index + 1}`]
                          : errors[item.name])[0]}
                    </span>
                  )}
              </div>
            );
      }
    },
    [errors, riskAttributes]
  );
  const { width } = useWindowDimensions();

  const accordionState = useSelector((state) => state?.products?.accordion);

  const [expand, setExpand] = useState({});

  const agentOnBoarding = useSelector((state) => state?.auth?.agentOnboarding);

  const quqRiskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "MAM")?.attributes
  );
const countryAttribute = JSON.parse(quqRiskAttributes.find((x) => x.name === "Country")?.settings).items
// const countryOptions = 

  const premiumAmount = useSelector(
    (state) =>
      state?.products?.executeCalculator?.productOptions?.[0]?.risks?.[0]
        ?.rating?.total
  );

  const premiumExtendedAmount = useSelector(
    (state) =>
      state?.products?.executeCalculator?.productOptions?.[0]?.risks);
  const filterExtendedAmount = premiumExtendedAmount?.filter((x) => x.code === "EXF")    

  const executeCalculatorResponse = useSelector(
    (state) => state?.products?.executeCalculator
  );

  const MAMRiskAttributes = useSelector(
    (state) =>
      state.products?.riskAttributes?.find((x) => x.code === "MAM")?.attributes
  );

  const getAttributesMAM = (value) => {
    return MAMRiskAttributes.find((x) => x.name === value);
  };


  const getAttributes = (value) => {
    return quqRiskAttributes?.find((x) => x.name === value);
  };

  const reducerState = useSelector((state) => state?.products);

  const getRows = (rows) => {
    var arr = [];
    for (var i = 0; i < rows; i++) {
      arr.push(i + 1);
    }
    return arr;
  };

  const [isLoading, setIsLoading] = useState(false);
  const dateOfBirth = getAttributes("Date of Birth");
  const over60Option = getAttributes("Cover Option");
  const [policyExpand, setPolicyExpand] = useState(true);
  const [spouseExpand, setSpouseExpand] = useState(false);
  const [childExpand, setChildExpand] = useState(false);
  const [extendedExpand, setExtendedExpand] = useState(false);
  const [parentExpand, setParentExpand] = useState(false);
  const [beneExpand, setBeneExpand] = useState(false);
  const [debitDate, setDebitDate] = useState();
  const [firstCollectamount, setFirstCollectAmount] = useState();
  useEffect(() => {
    const date = new Date();
    const dateMoment = moment(date).format("YYYY-MM-DD")
    const splitDate = dateMoment.split("-")
    const lastDates = ['26','27','28','29','30','31']
    if(lastDates.includes(splitDate[2])){
      setDebitDate(new Date(date.getFullYear(), date.getMonth() + 2, 1));
    }else{
      setDebitDate(new Date(date.getFullYear(), date.getMonth() + 1, 1));
  }
},[])
  const getUpdatedAttributes = (count) => {
    return new Promise((resolve, reject) => {
      if (customAttributes?.coverOption === "Single Parent" ) {
        //BITP check -- singleParent
        var arr =
          getExtended()?.length && customAttributes?.child_cover !== 0 //extended
            ? [
                ...extendedFamilyAttributes.map((x, i) => ({
                  attributes: [
                    ...Object.keys(x).map((y) => ({ name: y, value: x[y] })),
                    {
                      name: "Extended Family DOB",
                      value: extendedFamilies?.[i]?.dob,
                    },
                    {
                      name: "Extended Family Cover Amount",
                      value: extendedFamilies?.[i]?.cover,
                    },
                    {
                      name: "Extended Family Country ID was issued",
                      value: "Namibia",
                    },
                    {
                      name: "Extended Family ID Number",
                      value: null,
                    },
                  ],
                  externalReference: uuidv4(),
                  valuations: [],
                  productRiskReference: reducerState.riskAttributes.find(
                    (x) => x.code === "EXF"
                  )?.instanceId,
                  price: filterExtendedAmount[i]?.rating?.price,
                  discount: 0.0,
                  tax: 0.0,
                  total: filterExtendedAmount[i]?.rating?.total,
                })),
              ]
            : customAttributes?.child_cover === 0 && getExtended()?.length === 0 //child
            ? [
                ...childAttributes.map((x, i) => ({
                  attributes: [
                    ...Object.keys(x).map((y) => ({
                      name: y,
                      value: x[y],
                    })),
                  ],
                  externalReference: uuidv4(),
                  valuations: [],
                  productRiskReference: reducerState.riskAttributes.find(
                    (x) => x.code === "CHI"
                  )?.instanceId,
                  price: 0.0,
                  discount: 0.0,
                  tax: 0.0,
                  total: 0.0,
                })),
              ]
            : customAttributes?.child_cover === 0 && getExtended()?.length !== 0 // child+extended
            ? [
                ...childAttributes.map((x, i) => ({
                  attributes: [
                    ...Object.keys(x).map((y) => ({
                      name: y,
                      value: x[y],
                    })),
                  ],
                  externalReference: uuidv4(),
                  valuations: [],
                  productRiskReference: reducerState.riskAttributes.find(
                    (x) => x.code === "CHI"
                  )?.instanceId,
                  price: 0.0,
                  discount: 0.0,
                  tax: 0.0,
                  total: 0.0,
                })),
                ...extendedFamilyAttributes.map((x, i) => ({
                  attributes: [
                    ...Object.keys(x).map((y) => ({ name: y, value: x[y] })),
                    {
                      name: "Extended Family DOB",
                      value: extendedFamilies?.[i]?.dob,
                    },
                    {
                      name: "Extended Family Cover Amount",
                      value: extendedFamilies?.[i]?.cover,
                    },
                    {
                      name: "Extended Family Country ID was issued",
                      value: "Namibia",
                    },
                    {
                      name: "Extended Family ID Number",
                      value: null,
                    },
                  ],
                  externalReference: uuidv4(),
                  valuations: [],
                  productRiskReference: reducerState.riskAttributes.find(
                    (x) => x.code === "EXF"
                  )?.instanceId,
                  price: filterExtendedAmount[i]?.rating?.price,
                  discount: 0.0,
                  tax: 0.0,
                  total: filterExtendedAmount[i]?.rating?.total,
                })),
              ]
            : [];
      } else if (customAttributes?.coverOption === "Just Me") {
       //BITP check -- just me
        var arr = getExtended()?.length
          ? [
              ...extendedFamilyAttributes.map((x, i) => ({
                attributes: [
                  ...Object.keys(x).map((y) => ({ name: y, value: x[y] })),
                  {
                    name: "Extended Family DOB",
                    value: extendedFamilies?.[i]?.dob,
                  },
                  {
                    name: "Extended Family Cover Amount",
                    value: extendedFamilies?.[i]?.cover,
                  },
                  {
                    name: "Extended Family Country ID was issued",
                    value: "Namibia",
                  },
                  {
                    name: "Extended Family ID Number",
                    value: null,
                  },
                ],
                externalReference: uuidv4(),
                valuations: [],
                productRiskReference: reducerState.riskAttributes.find(
                  (x) => x.code === "EXF"
                )?.instanceId,
                price: filterExtendedAmount[i]?.rating?.price,
                discount: 0.0,
                tax: 0.0,
                total: filterExtendedAmount[i]?.rating?.total,
              })),
            ]
          : [];
      } else {
        //BITP check -- family
        var arr =
          getExtended()?.length && customAttributes?.child_cover !== 0 //extended+spouse
            ? [
                {
                  externalReference: uuidv4(),
                  code: "SPO",
                  attributes: [
                    ...reducerState.riskAttributes
                      .find((x) => x.code === "SPO")
                      ?.attributes?.filter(
                        (x) =>
                          x.name !== "Spouse Country ID or Passport issued" &&
                          x.name !== "Spouse Identification Number"
                      )
                      ?.map((i) => ({
                        ...i,
                        value: i.value,
                      })),
                    {
                      name: "Spouse Country ID or Passport issued",
                      value: "Namibia",
                    },
                    {
                      name: "Spouse Identification Number",
                      value: null,
                    },
                  ],
                  valuations: [],
                  productRiskReference: reducerState.riskAttributes.find(
                    (x) => x.code === "SPO"
                  )?.instanceId,
                  price: 0.0,
                  discount: 0.0,
                  tax: 0.0,
                  total: 0.0,
                },
                ...extendedFamilyAttributes.map((x, i) => ({
                  attributes: [
                    ...Object.keys(x).map((y) => ({ name: y, value: x[y] })),
                    {
                      name: "Extended Family DOB",
                      value: extendedFamilies?.[i]?.dob,
                    },
                    {
                      name: "Extended Family Cover Amount",
                      value: extendedFamilies?.[i]?.cover,
                    },
                    {
                      name: "Extended Family Country ID was issued",
                      value: "Namibia",
                    },
                    {
                      name: "Extended Family ID Number",
                      value: null,
                    },
                  ],
                  externalReference: uuidv4(),
                  valuations: [],
                  productRiskReference: reducerState.riskAttributes.find(
                    (x) => x.code === "EXF"
                  )?.instanceId,
                  price: filterExtendedAmount[i]?.rating?.price,
                  discount: 0.0,
                  tax: 0.0,
                  total: filterExtendedAmount[i]?.rating?.total,
                })),
              ]
            : customAttributes?.child_cover === 0 && getExtended()?.length === 0 //child+spouse
            ? [
                {
                  externalReference: uuidv4(),
                  code: "SPO",
                  attributes: [
                    ...reducerState.riskAttributes
                      .find((x) => x.code === "SPO")
                      ?.attributes?.filter(
                        (x) =>
                          x.name !== "Spouse Country ID or Passport issued" &&
                          x.name !== "Spouse Identification Number"
                      )
                      ?.map((i) => ({
                        ...i,
                        value: i.value,
                      })),
                    {
                      name: "Spouse Country ID or Passport issued",
                      value: "Namibia",
                    },
                    {
                      name: "Spouse Identification Number",
                      value: null,
                    },
                  ],
                  valuations: [],
                  productRiskReference: reducerState.riskAttributes.find(
                    (x) => x.code === "SPO"
                  )?.instanceId,
                  price: 0.0,
                  discount: 0.0,
                  tax: 0.0,
                  total: 0.0,
                },
                ...childAttributes.map((x, i) => ({
                  attributes: [
                    ...Object.keys(x).map((y) => ({
                      name: y,
                      value: x[y],
                    })),
                  ],
                  externalReference: uuidv4(),
                  valuations: [],
                  productRiskReference: reducerState.riskAttributes.find(
                    (x) => x.code === "CHI"
                  )?.instanceId,
                  price: 0.0,
                  discount: 0.0,
                  tax: 0.0,
                  total: 0.0,
                })),
              ]
            : customAttributes?.child_cover === 0 && getExtended()?.length !== 0 //child+extended+spouse
            ? [
                {
                  externalReference: uuidv4(),
                  code: "SPO",
                  attributes: [
                    ...reducerState.riskAttributes
                      .find((x) => x.code === "SPO")
                      ?.attributes?.filter(
                        (x) =>
                          x.name !== "Spouse Country ID or Passport issued" &&
                          x.name !== "Spouse Identification Number"
                      )
                      ?.map((i) => ({
                        ...i,
                        value: i.value,
                      })),
                    {
                      name: "Spouse Country ID or Passport issued",
                      value: "Namibia",
                    },
                    {
                      name: "Spouse Identification Number",
                      value: null,
                    },
                  ],
                  valuations: [],
                  productRiskReference: reducerState.riskAttributes.find(
                    (x) => x.code === "SPO"
                  )?.instanceId,
                  price: 0.0,
                  discount: 0.0,
                  tax: 0.0,
                  total: 0.0,
                },
                ...childAttributes.map((x, i) => ({
                  attributes: [
                    ...Object.keys(x).map((y) => ({
                      name: y,
                      value: x[y],
                    })),
                  ],
                  externalReference: uuidv4(),
                  valuations: [],
                  productRiskReference: reducerState.riskAttributes.find(
                    (x) => x.code === "CHI"
                  )?.instanceId,
                  price: 0.0,
                  discount: 0.0,
                  tax: 0.0,
                  total: 0.0,
                })),

                ...extendedFamilyAttributes.map((x, i) => ({
                  attributes: [
                    ...Object.keys(x).map((y) => ({ name: y, value: x[y] })),
                    {
                      name: "Extended Family DOB",
                      value: extendedFamilies?.[i]?.dob,
                    },
                    {
                      name: "Extended Family Cover Amount",
                      value: extendedFamilies?.[i]?.cover,
                    },
                    {
                      name: "Extended Family Country ID was issued",
                      value: "Namibia",
                    },
                    {
                      name: "Extended Family ID Number",
                      value: null,
                    },
                  ],
                  externalReference: uuidv4(),
                  valuations: [],
                  productRiskReference: reducerState.riskAttributes.find(
                    (x) => x.code === "EXF"
                  )?.instanceId,
                  price: filterExtendedAmount[i]?.rating?.price,
                  discount: 0.0,
                  tax: 0.0,
                  total: filterExtendedAmount[i]?.rating?.total,
                })),
              ]
            : [
                {
                  externalReference: uuidv4(),
                  code: "SPO",
                  attributes: [
                    ...reducerState.riskAttributes
                      .find((x) => x.code === "SPO")
                      ?.attributes?.filter(
                        (x) =>
                          x.name !== "Spouse Country ID or Passport issued" &&
                          x.name !== "Spouse Identification Number"
                      )
                      ?.map((i) => ({
                        ...i,
                        value: i.value,
                      })),
                    {
                      name: "Spouse Country ID or Passport issued",
                      value: "Namibia",
                    },
                    {
                      name: "Spouse Identification Number",
                      value: null,
                    },
                  ],
                  valuations: [],
                  productRiskReference: reducerState.riskAttributes.find(
                    (x) => x.code === "SPO"
                  )?.instanceId,
                  price: 0.0,
                  discount: 0.0,
                  tax: 0.0,
                  total: 0.0,
                },
              ];
      }

      resolve(arr);
    });
  };

  const beneState = useSelector((state) => state?.products?.beneficiaries);

  const handleSubmit = async () => {
    var values = {
      ...inputs,
      spouseCountryCode: customAttributes?.spouseCountryCode,
    };

    let spouseAtt = [];

    riskAttributes
      ?.find((x) => x.code === "SPO")
      ?.attributes?.map((y) => {
        spouseAtt.push(y.name);
        values = {
          ...values,
          [y.name]: y.value,
        };
      });

    let childAtt = [];

    getRows(customAttribues?.numberOfChildren)?.map((x, i) => {
      riskAttributes
        ?.find((e) => e.code === "CHI")
        ?.attributes?.map((y) => {
          childAtt.push(`${y.name} ${i + 1}`);
          values = {
            ...values,
            [`${y.name} ${i + 1}`]: childState?.[i]?.[y.name],
          };
        });
    });

    let extendedAtt = [];
    getRows(extendedFamilies?.length)?.map((x, i) => {
      riskAttributes
        ?.find((e) => e.code === "EXF")
        ?.attributes?.map((y) => {
          extendedAtt.push(`${y.name} ${i + 1}`);
          values = {
            ...values,
            [`${y.name} ${i + 1}`]: extendedFamilyState?.[i]?.[y?.name],
          };
        });
    });

    let beneAttributes = [];
    if (beneState?.length) {
      getRows(beneCount).map((x, i) => {
        beneAttributes.push(`Beneficiary First Name ${i + 1}`);
        beneAttributes.push(`Last Name ${i + 1}`);
        beneAttributes.push(`Email ${i + 1}`);
        beneAttributes.push(`Percentage ${i + 1}`);
        values = {
          ...values,
          [`Beneficiary First Name ${i + 1}`]: Object.keys(beneState[i] ?? {})
            ?.length
            ? beneState[i]?.["firstName"]
            : null,
          [`Beneficiary Last Name ${i + 1}`]: Object.keys(beneState[i] ?? {})
            ?.length
            ? beneState[i]?.["lastName"]
            : null,
          [`Beneficiaries Title ${i + 1}`]: Object.keys(beneState[i] ?? {})
            ?.length
            ? beneState[i]?.["title"]
            : null,
          [`ID Number ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
            ? beneState[i]?.["idNumber"]
            : null,
          [`Email ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
            ? beneState[i]["email"]
            : null,

          [`Phone Number ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
            ? beneState[i]["number"]
            : null,
          [`Phone Number Code ${i + 1}`]: Object.keys(beneState[i] ?? {})
            ?.length
            ? beneState[i]["Phone Number Code"]
            : null,
          [`Percentage ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
            ? beneState[i]["percentage"]
            : null,
          [`Telephone number ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
            ? beneState[i]["telephoneNumber"]
            : null,
          [`Beneficiary Date of Birth ${i + 1}`]: Object.keys(
            beneState[i] ?? {}
          )?.length
            ? beneState[i]?.["dob"]
            : null,
          [`Telephone Number Code ${i + 1}`]: Object.keys(beneState[i] ?? {})
            ?.length
            ? beneState[i]["Telephone Number Code"]
            : null,
          [`Occupation ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
            ? beneState[i]["occupation"]
            : null,
          [`Address 1 ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
            ? beneState[i]["address1"]
            : null,
          [`Address 2 ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
            ? beneState[i]["address2"]
            : null,
          [`Gender ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
            ? beneState[i]["gender"]
            : null,
          [`city ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
            ? beneState[i]["city"]
            : null,
          [`state ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
            ? beneState[i]["state"]
            : null,
          [`Guardian First Name ${i + 1}`]: Object.keys(beneState[i] ?? {})
            ?.length
            ? beneState[i]["guardianName"]
            : null,
          [`Guardian Last Name ${i + 1}`]: Object.keys(beneState[i] ?? {})
            ?.length
            ? beneState[i]["guardianLastName"]
            : null,
          [`Guardian Phone Number ${i + 1}`]: Object.keys(beneState[i] ?? {})
            ?.length
            ? beneState[i]["guardianNumber"]
            : null,
          [`Guardian Phone Number Code ${i + 1}`]: Object.keys(
            beneState[i] ?? {}
          )?.length
            ? beneState[i]["Guardian Phone Number Code"]
            : null,
          [`Guardian dob ${i + 1}`]: Object.keys(beneState[i] ?? {})?.length
            ? beneState[i]["guardianDob"]
            : null,
        };
      });
    }

    var cons = {};

    if (getAttributes("Cover Option")?.value === "Individual") {
      cons = {
        ...mainMemberConstraints(inputs),
      };
    } else {
      cons = {
        ...mainMemberConstraints(inputs),

        ...childConstraints({
          attributes: riskAttributes?.find((x) => x.code === "CHI")?.attributes,
          count: customAttributes?.numberOfChildren,
        }),
        ...extendedConstraints({
          attributes: riskAttributes?.find((x) => x.code === "EXF")?.attributes,
          count: extendedFamilies?.length,
        }),
      };
      if (customAttributes?.coverOption === "Family") {
        cons = {
          ...cons,
          ...spouseConstraints({
            attributes: riskAttributes?.find((x) => x.code === "SPO")
              ?.attributes,
          }),
        };
      }
        if (customAttributes?.bene_cover === 1) {
        cons = {
          ...cons,
          ...beneficiariesSchema(beneCount, calculateage, beneficiaries),
        };
      }
    }

    var error = validate(values, cons);
    ///---Spouse ID number validation -----//////////
    var spouseId = riskAttributes?.find((x) => x.code === "SPO")?.attributes?.find((x) => x.name === "Spouse Identification Number")?.value
    var spouseDob = riskAttributes?.find((x) => x.code === "SPO")?.attributes?.find((x) => x.name === "Spouse DOB")?.value
    if(spouseId?.length){
      var format = /^[0-9]\d{10}$|^[0-9]\d{12}$/;
     if(!format.test(spouseId)){ 
      error = {
        ...error,
        [`Spouse Identification Number`]: ["ID number is the wrong length (should be 11 or 13 characters)"],
      };
    }
  }
      if((spouseId) && (spouseId.length === 11 || spouseId.length === 13) && (spouseDob === undefined || spouseDob === null)){
        error = {
          ...error,
          [`Spouse Identification Number`]: ["Please fill date of birth"], 
        };
      }
      if((spouseId) && (spouseId.length === 11 || spouseId.length === 13) &&(spouseDob)){
            var dobSplit = spouseDob.split("-")
            var idDate = spouseId.substring(4,6)
            var idMonth = spouseId.substring(2,4)
            var idYear =  spouseId.substring(0,2)
            var dobYear = dobSplit[0].substring(2,4)
            if((dobYear !== idYear) || (dobSplit[1] !== idMonth) || (dobSplit[2] !== idDate)){
              error = {
                ...error,
                [`Spouse Identification Number`]: ["Invalid ID number"],
              };
            }
           
      }
    ///---Spouse ID number validation[Ends] -----//////////

    ///---Beneficiary ID number validation -----//////////
    if (customAttributes?.bene_cover === 1) {
    getRows(beneCount)?.map((x, i) => {
      if (beneState[i]?.["idNumber"] && (beneState[i]?.["idNumber"] === inputs?.idNumber) && inputs?.idNumber) { 
        error = {
          ...error,
          [`ID Number ${i+1}`]: ["ID number needs to be different from Policy Holder"], 
        };
      }
      if((beneState[i]?.["idNumber"]) && (beneState[i]?.["idNumber"].length === 11 || beneState[i]?.["idNumber"].length === 13) && (beneficiaries[i]?.["dob"] === undefined || beneficiaries[i]?.["dob"] === null) ){
        error = {
          ...error,
          [`ID Number ${i+1}`]: ["Please fill date of birth"], 
        };
      }     
      if ((beneState[i]?.["idNumber"]) && (beneState[i]?.["idNumber"].length === 11 || beneState[i]?.["idNumber"].length === 13) &&(beneficiaries[i]?.["dob"]) && (beneState[i]?.["idNumber"] !== inputs?.idNumber)) {      
          var beneId = beneState[i]?.["idNumber"]
          var dobSplit = beneficiaries[i]?.["dob"].split("-")
          var idDate = beneId.substring(4,6)
          var idMonth = beneId.substring(2,4)
          var idYear = beneId.substring(0,2)
          var dobYear = dobSplit[0].substring(2,4)
          if((dobYear !== idYear) || (dobSplit[1] !== idMonth) || (dobSplit[2] !== idDate)){
              error = {
                ...error,
                [`ID Number ${i+1}`]: ["Invalid ID number"],
              };
            }
        }
    });
  }
    ///---Beneficiary ID number validation [Ends]-----//////////

    ///---Extended ID number validation -----//////////
    getRows(extendedFamilies?.length)?.map((x, i) => {
      if(extendedFamilyState?.[i]?.['Extended Family ID Number']?.length){
        var format = /^[0-9]\d{10}$|^[0-9]\d{12}$/;
       if(!format.test(extendedFamilyState?.[i]?.['Extended Family ID Number'])){ 
        error = {
          ...error,
          [`Extended Family ID Number ${i+1}`]: ["ID number is the wrong length (should be 11 or 13 characters)"],
        };
      }
    }
      if ((extendedFamilyState?.[i]?.['Extended Family ID Number']) && (extendedFamilyState?.[i]?.['Extended Family ID Number']?.length === 11 || extendedFamilyState?.[i]?.['Extended Family ID Number']?.length === 13) ) {
          var extendedId = extendedFamilyState?.[i]?.['Extended Family ID Number']
          var dobSplit = extendedFamilies?.[i]?.dob.split("-")
          var idDate = extendedId.substring(4,6)
          var idMonth = extendedId.substring(2,4)
          var idYear = extendedId.substring(0,2)
          var dobYear = dobSplit[0].substring(2,4)
          if((dobYear !== idYear) || (dobSplit[1] !== idMonth) || (dobSplit[2] !== idDate)){
            error = {
              ...error,
              [`Extended Family ID Number ${i+1}`]: ["Invalid ID number"],
            };
          }        
      } 
    });
     ///---Extended ID number validation [Ends]-----//////////
    if (inputs?.idNumber) {
      if (inputs?.nationality?.toLowerCase().includes("namibia") && inputs.documentType === 'Passport') {
        if (['T', 't', 'P', 'p'].includes(inputs?.idNumber[0])) {
        } else {
          error = {
            ...error,
            idNumber: error?.idNumber
              ? [...error?.idNumber, "Invalid nationality id"]
              : ["Invalid nationality id"],
          };
        }
      }
    }
    ///---Policy ID number validation -----//////////
    if((inputs?.idNumber) && (inputs.idNumber.length === 11 || inputs.idNumber.length === 13)){
      var dobSplit = inputs?.dob.split("-")
      var idDate = inputs?.idNumber.substring(4,6)
      var idMonth = inputs?.idNumber.substring(2,4)
      var idYear = inputs?.idNumber.substring(0,2)
      var dobYear = dobSplit[0].substring(2,4)
      if((dobYear !== idYear) || (dobSplit[1] !== idMonth) || (dobSplit[2] !== idDate)){
        error = {
          ...error,
          idNumber: error?.idNumber
            ? [...error?.idNumber, "Invalid nationality id"]
            : ["Invalid nationality id"],
        };
      }
    }
    
     ///---Policy ID number validation [Ends]-----//////////



    var mainMemberAttributes = [
      "firstName",
      "lastName",
      "nationalityId",
      "dob",
      "nationality",
      "omang_card_front",
      "omang_card_back",
      "mainGender",
      "maritalStatus",
      "mobilePhone",
      "physicalAddress",
      "region",
      "eMail",
      "city",
    ];

    if (error || error !== undefined) {
      if (Object.keys(inputs)?.length) {
        Object.keys(error)?.map((x) => {
          if (mainMemberAttributes?.includes(x)) {
            setPolicyExpand(true);
            setExpand({ ...expand, policy: true });

            error = {
              ...error,
              policy: ["Please fill policy holder details "],
            };
          }
        });
      }
    }

    if (error || error !== undefined) {
      if (customAttribues?.numberOfChildren) {
        Object.keys(error)?.map((x) => {
          if (childAtt?.includes(x)) {
            setExpand({ ...expand, child: true });
            setChildExpand(true);
            error = {
              ...error,
              child: ["Please fill child details"],
            };
          }
        });
      }
    }

    if (error || error !== undefined) {
      if (customAttributes?.coverOption === "Family") {
        Object.keys(error)?.map((x) => {
          if (spouseAtt?.includes(x)) {
            setSpouseExpand(true);
            setExpand({ ...expand, spouse: true });
            error = {
              ...error,
              spouse: ["Please fill spouse details "],
            };
          }
        });
      }
    }

    if (error || error !== undefined) {
      if (customAttributes?.bene_cover === 1) {
      if (beneficiaries?.length) {
        Object.keys(error)?.map((x) => {
          if (beneAttributes?.includes(x)) {
            setBeneExpand(true);
            setExpand({ ...expand, beneficiaries: true });
            error = {
              ...error,
              beneficiaries: ["Please fill beneficiaries details "],
            };
          }
        });
      }
    }
  }
    if (error || error !== undefined) {
      if (getExtended()?.length) {
        Object.keys(error)?.map((x) => {
          if (extendedAtt?.includes(x)) {
            setExtendedExpand(true);
            setExpand({ ...expand, extended: true });
            error = {
              ...error,
              extended: ["Please fill extended family details "],
            };
          }
        });
      }
    }

    const beneficiariesCount = beneficiaries.reduce(
      (a, b) => a + b.percentage,
      0
    );

    if (customAttributes?.bene_cover === 1) {
    if (beneficiariesCount !== 100) {
     error = {
        ...error,
        ["beneficiariesSplit"]: [
          `Benefit split for all beneficiaries must be total to 100%`,
        ],
      };
    }
  }

    if (error || error !== undefined) {
      var errorArray = Object.keys(error);
      document.getElementById(errorArray[0])?.scrollIntoView({
        behavior: "smooth",
      });
      return setErrors(error);
    }
    
    const getAttries = await getUpdatedAttributes();

    var policyRisks =
      customAttributes?.coverOption === "Just Me"
        ? [
          {
            externalReference: uuidv4(),
            attributes: [
              ...reducerState.riskAttributes
                .find((x) => x.code === "MAM")
                ?.attributes.map((y) => ({
                  dataType: y.dataType,
                  name: y.name,
                  instanceId: y.instanceId,
                  value:
                  y.name === "Number of Children"
                    ? customAttributes?.numberOfChildren ?? 0
                    : y.name === "Document Type"
                      ? inputs?.documentType
                      : y.name === "If ID was selected"
                        ? inputs?.documentType === "ID"
                          ? inputs?.nationality
                          : null
                        : y.name === "If passport was selected"
                          ? inputs?.documentType === "Passport"
                            ? inputs?.nationality
                            : null
                          : y.name === "Cover Option"
                            ? customAttributes?.coverOption === "Just Me" ? "Individual"
                              : customAttributes?.coverOption === "Single Parent" ? "Individual plus 4 children"
                                : "Family"
                            : y.name === "Namibian ID Number"
                              ? inputs?.idNumber
                              : y.name === "Country"
                                ? inputs?.nationality
                                : y.name === "Date of Birth"
                                  ? customAttributes?.dob
                                  : y.name === "Source of income"
                                    ? inputs.sourceOfIncome?.value
                                    : y.name === "Region"
                                      ? inputs.region?.value
                                      : y.name === 'Source of Funds for Transaction'
                                        ? inputs?.sourceOfFunds?.value 
                                      : y.name === 'Add Beneficiary'
                                        ? customAttributes?.bene_cover === 1 ? "NO" : "YES"
                                        :  y.name === "Cover Amount" ? (getAttributesMAM("Cover Amount")?.value) : y.value ?? null,
                })),
            ],
            valuations: [],
            productRiskReference: reducerState.riskAttributes.find(
              (x) => x.code === "MAM"
            )?.instanceId,
            price: premiumAmount,
            discount: 0,

            tax: 0,
            total: premiumAmount,
          },
          {
            externalReference: uuidv4(),
            code: "PSR",
            attributes: [
              ...reducerState.riskAttributes
                .find((x) => x.code === "PSR")
                ?.attributes.map((i) => ({
                  ...i,
                  value: i.name === "First Debit Order Date"
                    ?  moment(debitDate).format("YYYY-MM-DD")
                    : i.value,
                })),
            ],
            valuations: [],
            productRiskReference: reducerState.riskAttributes.find(
              (x) => x.code === "PSR"
            )?.instanceId,
            price: 0.0,
            discount: 0.0,
            tax: 0.0,
            total: 0.0,
          },
          ...getAttries,
        ]
        : [
          {
            externalReference: uuidv4(),
            attributes: [
              ...reducerState.riskAttributes
                .find((x) => x.code === "MAM")
                ?.attributes.map((y) => ({
                  dataType: y.dataType,
                  name: y.name,
                  instanceId: y.instanceId,
                  value:
                    y.name === "Number of Children"
                      ? customAttributes?.numberOfChildren ?? 0
                      : y.name === "Document Type"
                        ? inputs?.documentType
                        : y.name === "If ID was selected"
                          ? inputs?.documentType === "ID"
                            ? inputs?.nationality
                            : null
                          : y.name === "If passport was selected"
                            ? inputs?.documentType === "Passport"
                              ? inputs?.nationality
                              : null
                            : y.name === "Cover Option"
                              ? customAttributes?.coverOption === "Just Me" ? "Individual"
                                : customAttributes?.coverOption === "Single Parent" ? "Individual plus 4 children"
                                  : "Family"
                              : y.name === "Namibian ID Number"
                                ? inputs?.idNumber
                                : y.name === "Country"
                                  ? inputs?.nationality
                                  : y.name === "Date of Birth"
                                    ? customAttributes?.dob
                                    : y.name === "Source of income"
                                      ? inputs.sourceOfIncome?.value
                                      : y.name === "Region"
                                        ? inputs.region?.value
                                        : y.name === 'Source of Funds for Transaction'
                                          ? inputs?.sourceOfFunds?.value 
                                        : y.name === 'Add Beneficiary'
                                          ? customAttributes?.bene_cover === 1 ? "NO" : "YES"
                                        :  y.name === "Cover Amount" ? (getAttributesMAM("Cover Amount")?.value) : y.value ?? null,
                })),
            ],
            valuations:
            customAttributes?.coverOption === "Family"
                ? executeCalculatorResponse?.productOptions?.[0]?.valuations
                  ?.values
                : executeCalculatorResponse?.productOptions?.[0]?.valuations?.values?.filter(
                  (x) => x.name !== "Spouse"
                ),
            productRiskReference: reducerState.riskAttributes.find(
              (x) => x.code === "MAM"
            )?.instanceId,
            price: premiumAmount,
            discount: 0,

            tax: 0,
            total: premiumAmount,
          },
          {
            externalReference: uuidv4(),
            code: "PSR",
            attributes: [
              ...reducerState.riskAttributes
                .find((x) => x.code === "PSR")
                ?.attributes.map((i) => ({
                  ...i,
                  value: i.name === "First Debit Order Date"
                    ?  moment(debitDate).format("YYYY-MM-DD")
                    : i.value,
                })),
            ],
            valuations: [],
            productRiskReference: reducerState.riskAttributes.find(
              (x) => x.code === "PSR"
            )?.instanceId,
            price: 0.0,
            discount: 0.0,
            tax: 0.0,
            total: 0.0,
          },
          ...getAttries,
        ];
    if (customAttributes?.spouse_cover === 1) {
      policyRisks.splice(1, 1);
    }

    try {
      setIsLoading(true);
      var payload = {
        policies: [
          {
            startDate: moment(debitDate).format("YYYY-MM-DD"),
            endDate: moment(debitDate)
              .add(1, "year")
              .subtract(1, "day")
              .format("YYYY-MM-DD"),
            PolicyStatus: "Quoted",
            currencyCode: "NAD",
            members: [
              {
                firstName: inputs?.firstName,
                lastName: inputs?.lastName,
                birthDate: inputs?.dob,
                title: inputs?.title,
                mobilePhone: inputs?.mobilePhone,
                mobilePhoneCode: `+${inputs?.mobilePhoneCode?.replace(
                  "+",
                  ""
                )}`,
                nationalityId: inputs?.idNumber,
                sourceOfFunds: inputs?.sourceOfFunds,
                occupation: inputs?.occupation,
                workCompany: inputs?.employer,
                nationality: inputs?.nationality,
                incomeTax: inputs?.incomeTax,
                marriageStatus: inputs?.maritalStatus?.value,
                gender: inputs?.mainGender,
                telePhone: inputs?.telePhone,
                address: inputs?.physicalAddress,
                address2: inputs?.address2,
                city: inputs?.city,
                province: inputs?.region.value,
                postalAddress: inputs?.postalAddress,
                postalCity: inputs?.postalTown,
                postalCountry: inputs?.postalCountry,
                postalProvince: inputs?.postalProvince,
                eMail: inputs?.eMail,
                memberType: 1,
                relationship: 0,
              },
            ],

            attributes: [],
            risks: policyRisks,
            bankAccounts: null,
            policyCollectionSchedule: {
              collectionDay: moment(debitDate)
                .format("YYYY-MM-DD")
                .split("-")[2],
              collectionDuration: 0,
              collectionFrequency: "3",
              policyFirstCollection: {
                firstCollectionAmount: firstCollectamount,
                FirstCollectionDate: moment(debitDate).format("YYYY-MM-DD"),
                IsTaxInclusive: true,
                FirstCollectionTaxAmount: "0",
                Comments: "submitted from react front end",
              },
            },
            productOptionReference:
              reducerState?.executeCalculator?.productOptions?.[0]
                ?.productOptionReference,
            status: 15,
          },
        ],
        networkId:
          process.env.REACT_APP_ENV_TYPE === "DEV"
            ? "a9d273b0-8239-e383-58d4-c5da10b5b013"
            : process.env.REACT_APP_ENV_TYPE === "UAT"
            ? "07b7c8af-0e94-f0f9-2b18-8f339ccc2e38"
            : process.env.REACT_APP_ENV_TYPE === "PROD"
            ? "5a9aa175-a18b-c0bb-ead0-fd872ad25664"
            : agentOnBoarding?.network?.instanceId, // NetworkId from agentOnboarding
        companyId: agentOnBoarding?.company?.code,
        agentId: agentOnBoarding?.agent?.code,
      };
      //checking the beneficiary yes/no condition//
      if (customAttributes?.bene_cover === 1) {
        payload.policies[0].beneficiaries = beneficiaries.reduce((acc, x) => {
          acc = [
            ...acc,
            {
              beneficiaryType: 0,
              firstName: x.firstName,
              lastName: x.lastName,
              country: x.country ?? "NAMIBIA",
              dob: x.dob,
              birthDate: x.dob,
              title: x.title,
              nationalityId: null,
              nationality: "NAMIBIA",
              eMail: x?.email,
              relationship: x.relationship ?? 0,
              gender: x.gender,
              split: x.percentage / 100 ?? 100,
            },
          ];
          if (x.guardianName) {
            acc = [
              ...acc,
              {
                beneficiaryType: 2,
                firstName: x.guardianName,
                lastName: x.guardianLastName,
                dob: x.guardianDob,
                birthDate: x.guardianDob,
                mobilePhone: x.guardianNumber,
                mobilePhoneCode: `+${x?.["Guardian Phone Number Code"]?.replace(
                  "+",
                  ""
                )}`,
              },
            ];
          }
          return acc;
        }, []);
      } else {
      }
      const res = await dispatch(CreatePolicy(payload));
      setIsLoading(false);
      //trackUserEvents
      trackUserEvents("na_complete_your_details_next_cta", {
        firstName: inputs?.firstName,
        lastName: inputs?.lastName,
        birthDate: inputs?.dob,
        title: inputs?.title,
        mobilePhone: inputs?.mobilePhone,
        mobilePhoneCode: `+${inputs?.mobilePhoneCode?.replace("+", "")}`,
        nationalityId: inputs?.idNumber,
        sourceOfFunds: inputs?.sourceOfFunds,
        occupation: inputs?.occupation,
        workCompany: inputs?.employer,
        nationality: inputs?.nationality,
        incomeTax: inputs?.incomeTax,
        marriageStatus: inputs?.maritalStatus?.value,
        gender: inputs?.mainGender,
        telePhone: inputs?.telePhone,
        address: inputs?.physicalAddress,
        address2: inputs?.address2,
        city: inputs?.city,
        province: inputs?.region.value,
        postalAddress: inputs?.postalAddress,
        postalCity: inputs?.postalTown,
        postalCountry: inputs?.postalCountry,
        postalProvince: inputs?.postalProvince,
        eMail: inputs?.eMail,
        memberType: 1,
        relationship: 0,
      });
      handleNext(5);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const beneficiariesState = useSelector(
    (state) => state?.products?.beneficiaries
  );

  const handleBackTrack = () => {
    //trackUserEvents
    trackUserEvents("na_complete_your_details_back_cta", {});
    handleBack();
  };
  useEffect(() => {
    setBeneficiaries(beneficiariesState);
  }, []);

  const handleChangeBene = (evt, index) => {
    var arr = [...beneficiaries];
    arr[index] = { ...arr[index], [evt.target.name]: evt.target.value };
    if (evt.target.name === "dob") {
      arr[index] = {
        ...arr[index],
        "Guardian Phone Number Code":
          arr[index]?.["Guardian Phone Number Code"] ?? "+264",
      };
      setBeneficiaries(arr);
      return dispatch(
        updateCustomAttributes({
          type: "BENEFICIARIES",
          payload: arr,
        })
      );
    }
    setBeneficiaries(arr);
    dispatch(
      updateCustomAttributes({
        type: "BENEFICIARIES",
        payload: arr,
      })
    );
  };

  useEffect(() => {
    setSpouseExpand(accordionState?.spouse);
    setChildExpand(accordionState?.child);
    setExtendedExpand(accordionState?.extended);
    setParentExpand(accordionState?.parent);
    setBeneExpand(accordionState?.beneficiaries);
  }, []);

  useEffect(() => {
    dispatch(
      updateAccordion({
        policy: true,
        spouse: spouseExpand,
        child: childExpand,
        extended: extendedExpand,
        parent: parentExpand,
        beneficiaries: beneExpand,
      })
    );
  }, [spouseExpand, childExpand, extendedExpand, parentExpand, beneExpand]);

  const handleAdd = () => {
    setBeneficiaries([
      ...beneficiaries,
      {
        firstName: "",
        lastName: "",
        email: "",
        number: "",
        percentage: "",
        dob: new Date(),
        postalAddress: "",
        gender: "",
        country: "",
        telephoneNumber: "",
        idNumber: "",
        "Guardian Phone Number Code": "+264",
      },
    ]);
  };

  const handleDelete = (i) => {
    if (beneficiaries?.length <= 1) return;
    var arr = [...beneficiaries];

    arr.splice(i, 1); // 2nd parameter means remove one item only

    setBeneficiaries(arr);
    dispatch(
      updateCustomAttributes({
        type: "BENEFICIARIES",
        payload: arr,
      })
    );
  };

  function range(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }

  const [isTyped, setIsTyped] = useState(false);

  useEffect(() => {
    dispatch(updateAccordion(expand));
  }, []);

  const customAttribues = useSelector(
    (state) => state?.products?.customAttribues
  );

  const options = useSelector(
    (state) =>
      state?.products?.riskAttributes
        ?.find((x) => x.code === RISKS.QUQ)
        ?.attributes?.find((y) => y.name === ATTRIBUTES.LIVES_COVERED)?.value
  );

  const bands = useSelector((state) => state?.products?.bands);

  const extendedFamilies = useSelector(
    (state) => state?.products?.extendedFamilies
  );

  const getExtended = () => {
    return extendedFamilies;
  };

  const calculateage = (date, i) => {
    var diffInYears = moment().diff(date, "years");
    if (diffInYears < 18) {
      return true;
    }
  };

  const calculateTrusteeMaxDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    return new Date(
      `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
    );
  };

  useEffect(() => {
    var divs = document.querySelectorAll("#accord-number");
    var divsArray = [].slice.call(divs);
    var displayNone = divsArray.filter(function (el) {
      return getComputedStyle(el).display === "none";
    });
    var displayShow = divsArray.filter(function (el) {
      return getComputedStyle(el).display !== "none";
    });
    var numberOfVisibleDivs = displayShow.length;
    getRows(+numberOfVisibleDivs).map((d, i) => (divs[i].innerHTML = i + 1));
  }, []);

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get("https://restcountries.com/v3.1/all");
        var countArray = res.data
          .sort((a, b) =>
            a.name.common.toLowerCase() > b.name.common.toLowerCase() ? 1 : -1
          )
          .filter((f) => f.name.common.toLowerCase() !== "namibia")
          .map((d) => ({ label: d.name.common, value: d.name.common }));
        setCountries([
          {
            label: "Namibia",
            value: "Namibia",
          },
        ]);
      }
      catch (err) {
        setCountries([{
          label: "Namibia",
          value: "Namibia",
        },])
      }
    })();
  }, []);

  useEffect(() => {
    if (customAttribues?.dob) {
      setInputs({
        ...inputs,
        dob: inputs?.dob ?? customAttribues?.dob,
      });
    }
  }, []);
  const parseString = (string, name) => {
    const arr = string.split(",");
    let obj = {};
    arr.forEach((a) => {
      const val = a.split(":");
      obj[val[0]] = val[1];
    });
    return obj;
  };

  const getSpouseAttributes = () => {
      return riskAttributes
        .find((x) => x.code === "SPO")
        ?.attributes?.filter(
          (t) =>
            t.name !== "Spouse Option ID or Passport" &&
            t.name !== "Spouse Document Type" &&
            t.name !== "Spouse ID Number or Passport no" &&
            t.name !== "Spouse Country ID or Passport issued" &&
            t.name !== "Spouse Identification Number"
        );
    // }
  };

  const getPremium = () => {
    let total = 0;
    executeCalculatorResponse?.productOptions?.[0]?.risks?.forEach((ele) => {
      total = ele.rating.total + total;
    });
    if (total <= 59) {
      return 'N$60.00'
    }
    return `N$${total
      ?.toFixed(2)
      ?.toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  useEffect(() => {
    let total = 0;
    executeCalculatorResponse?.productOptions?.[0]?.risks?.forEach((ele) => {
      total = ele.rating.total + total;
    });
    setFirstCollectAmount(total)
  },[executeCalculatorResponse])

  useEffect(() => {
    if (customAttribues) {
      dispatch(
        updateCustomAttributes({
          type: "CUSTOM_ATTRIBUTES",
          payload: {
            ...customAttribues,
            spouseCountryCode: customAttribues?.spouseCountryCode ?? "+264",
          },
        })
      );
    }
  }, []);
  return (
    <div className="mt-4">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">
              Namibia Family Protection Plan
            </h3>
            <h2 className="primary-color f-800">Complete your details</h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Family Protection Plan</p>
          <h2 className="primary-color f-800">Complete your details</h2>
        </div>
      )}
      <div className="d-flex justify-content-center align-items-center flex-column mt-5">
        <Info>
          <p className="primary-color">
            Please complete the details below for the policy holder and
            beneficiaries, click “Next” by scrolling to the bottom of the page
            to proceed to payment
          </p>
        </Info>
        <div
          style={{ width: width > 770 ? "460px" : "90%", marginTop: "20px" }}
        >
          <h5 className="primary-color f-700 text-left">
            Please enter your personal details here:
          </h5>
        </div>
        <div
          style={{ width: width > 770 ? "460px" : "90%", marginTop: "20px" }}
        >
          <Accordion
            defaultExpanded={policyExpand ?? true}
            onChange={() => setPolicyExpand(policyExpand)}
            expanded={policyExpand}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="d-flex flex-row">
                <div
                  style={{
                    borderRadius: 99,
                    marginRight: 10,
                    backgroundColor: "white",
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="primary-color f-700" id="accord-number">
                    1
                  </span>
                </div>
                <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                  Add policy holder details here
                </p>
              </div>
            </AccordionSummary>
            <AccordionDetails className="p-0 mt-4">
              <div
                style={{
                  border: "1px solid #00000030",
                  padding: 20,
                  borderRadius: 5,
                }}
              >
                <div className="form-group mt-3">
                  <label className="form-label">Title*</label>
                  <ReactSelect
                    id={`title`}
                    placeholder="title"
                    styles={customStyles}
                    value={
                      inputs?.title?.value
                        ? inputs?.title?.value
                        : { label: inputs?.title, value: inputs?.title }
                    }
                    options={[
                      {
                        label: "Adv",
                        value: "Adv",
                      },
                      {
                        label: "Dr",
                        value: "Dr",
                      },
                      {
                        label: "Miss",
                        value: "Miss",
                      },
                      {
                        label: "Mr",
                        value: "Mr",
                      },
                      {
                        label: "Mrs",
                        value: "Mrs",
                      },
                      {
                        label: "Ms",
                        value: "Ms",
                      },
                      {
                        label: "Prof",
                        value: "Prof",
                      },
                      {
                        label: "Rev",
                        value: "Rev",
                      },
                      {
                        label: "Sir",
                        value: "Sir",
                      },
                      {
                        label: "NONE",
                        value: "NONE",
                      },
                    ]}
                    onChange={(e) => {
                      handleChangePolicy({
                        target: { name: "title", value: e.value },
                      });
                    }}
                  />
                  {errors?.["title"] && (
                    <span className="text-danger errors">
                      {errors?.["title"] && errors?.["title"][0]}
                    </span>
                  )}
                </div>

                <TextInput
                  id={"firstName"}
                  label={"First name"}
                  name={"firstName"}
                  value={inputs?.firstName}
                  errors={errors?.["firstName"]}
                  onChange={(e) => {
                    if (e?.target?.value?.length <= 30) {
                      handleChangePolicy(e);
                    }
                  }}
                  required={true}
                />
                <TextInput
                  id={"lastName"}
                  label={"Last name or surname"}
                  name={"lastName"}
                  value={inputs?.lastName}
                  errors={errors?.["lastName"]}
                  onChange={(e) => {
                    if (e?.target?.value?.length <= 30) {
                      handleChangePolicy(e);
                    }
                  }}
                  required={true}
                />

                <div style={{ marginTop: 10 }} />
                <TwoButtons
                  id={`mainGender`}
                  title={"Gender"}
                  isField={true}
                  half={true}
                  errors={errors?.[`mainGender`]}
                  state={inputs?.mainGender}
                  onClick={(e) =>
                    handleChangePolicy({
                      target: { name: "mainGender", value: e },
                    })
                  }
                  options={[
                    {
                      name: "Male",
                      value: "Male",
                    },
                    {
                      name: "Female",
                      value: "Female",
                    },
                  ]}
                  required={true}
                />
                <DateInput
                  id={"dob"}
                  label={"Date of birth"}
                  name={"dob"}
                  minDate={calculateMinDate("parent")}
                  errors={errors?.["dob"]}
                  maxDate={calculateMaxDate("parent")}
                  value={inputs?.dob}
                  disabled={true}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />
                <div className="form-group mt-3">
                  <label className="form-label">
                    {/* Country where ID/Passport was issued* */}
                    Country*
                  </label>
                  <ReactSelect
                    id={`nationality`}
                    placeholder="Nationality"
                    styles={customStyles}
                    value={
                      inputs?.nationality?.value
                        ? inputs?.nationality?.value
                        : {
                            label: inputs?.nationality,
                            value: inputs?.nationality,
                          }
                    }
                    // options={countries}
                    options={[
                      {
                        label: "Namibia",
                        value: "Namibia",
                      },
                    ]}
                    onChange={(e) => {
                      handleChangePolicy({
                        target: { name: "nationality", value: e.value },
                      });
                    }}
                  />
                  {errors?.["nationality"] && (
                    <span className="text-danger errors">
                      {errors?.["nationality"] && errors?.["nationality"][0]}
                    </span>
                  )}
                </div>
                <NumberInput
                  id={"idNumber"}
                  type={"number"}
                  label={"Identification / Passport number"}
                  name={"idNumber"}
                  disabled={!inputs?.nationality}
                  value={inputs?.idNumber}
                  errors={errors?.["idNumber"]}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />

                <div className="form-group mt-3">
                  <label className="form-label">Marital status*</label>
                  <ReactSelect
                    id={`maritalStatus`}
                    placeholder="Select status"
                    value={inputs?.maritalStatus}
                    styles={customStyles}
                    options={[
                      { value: "Married", label: "Married" },
                      { value: "Single", label: "Single" },
                      { value: "Widowed", label: "Widowed" },
                      { value: "Divorced", label: "Divorced" },
                    ]}
                    onChange={(e) => {
                      handleChangePolicy({
                        target: { name: "maritalStatus", value: e },
                      });
                    }}
                  />
                  {errors?.["maritalStatus"] && (
                    <span className="text-danger errors">
                      {errors?.["maritalStatus"] &&
                        errors?.["maritalStatus"][0]}
                    </span>
                  )}
                </div>
                <div className="form-group mt-3">
                  <label className="form-label">
                    Source of funds for this transaction*
                  </label>
                  <ReactSelect
                    id={`sourceOfFunds`}
                    placeholder="Source of funds for this transaction"
                    value={inputs?.sourceOfFunds}
                    styles={customStyles}
                    options={JSON.parse(
                      getAttributes("Source of Funds for Transaction")?.settings
                    )?.items?.map((f) => ({ label: f, value: f }))}
                    onChange={(e) => {
                      handleChangePolicy({
                        target: { name: "sourceOfFunds", value: e },
                      });
                    }}
                  />
                  {errors?.["sourceOfFunds"] && (
                    <span className="text-danger errors">
                      {errors?.["sourceOfFunds"] &&
                        errors?.["sourceOfFunds"][0]}
                    </span>
                  )}
                </div>
                <TextInput
                  id={"occupation"}
                  label={"Occupation"}
                  name={"occupation"}
                  value={inputs?.occupation}
                  errors={errors?.["occupation"]}
                  onChange={(e) => {
                    if (e?.target?.value?.length <= 30) {
                      handleChangePolicy(e);
                    }
                  }}
                  required={true}
                />
                <TextInput
                  id={"employer"}
                  label={"Employer name"}
                  name={"employer"}
                  value={inputs?.employer}
                  errors={errors?.["employer"]}
                  onChange={(e) => {
                    if (e?.target?.value?.length <= 30) {
                      handleChangePolicy(e);
                    }
                  }}
                  required={false}
                />

                <div className="form-group mt-3">
                  <label className="form-label">Source of income*</label>
                  <ReactSelect
                    id={`sourceOfIncome`}
                    placeholder="Select source of income"
                    value={inputs?.sourceOfIncome}
                    styles={customStyles}
                    options={[
                      { value: "Allowance", label: "Allowance" },
                      { value: "Bursary", label: "Bursary" },
                      { value: "Company profits", label: "Company profits" },
                      {
                        value: "Company sale / sale of interest in company",
                        label: "Company sale / sale of interest in company",
                      },
                      {
                        value: "Dividends from investments",
                        label: "Dividends from investments",
                      },
                      {
                        value: "Divorce settlement",
                        label: "Divorce settlement",
                      },
                      { value: "Gift/Donation", label: "Gift/Donation" },
                      { value: "Salary", label: "Salary" },
                      {
                        value: "Income from previous employment",
                        label: "Income from previous employment",
                      },
                      { value: "Inheritance", label: "Inheritance" },
                      { value: "Loan", label: "Loan" },
                      {
                        value: "Lottery / gambling winnings",
                        label: "Lottery / gambling winnings",
                      },
                      { value: "Maintenance", label: "Maintenance" },
                      {
                        value: "Maturing investments",
                        label: "Maturing investments",
                      },
                      { value: "Pension", label: "Pension" },
                      {
                        value: "Sale asset / property",
                        label: "Sale asset / property",
                      },
                      { value: "Sale of shares", label: "Sale of shares" },
                      { value: "Savings", label: "Savings" },
                      {
                        value: "Retained earnings",
                        label: "Retained earnings",
                      },
                      { value: "Debt capital", label: "Debt capital" },
                      { value: "Equity capital", label: "Equity capital" },
                    ]}
                    onChange={(e) => {
                      handleChangePolicy({
                        target: { name: "sourceOfIncome", value: e },
                      });
                    }}
                  />
                  {errors?.["sourceOfIncome"] && (
                    <span className="text-danger errors">
                      {errors?.["sourceOfIncome"] &&
                        errors?.["sourceOfIncome"][0]}
                    </span>
                  )}
                </div>

                <div className="row">
                  {width > 1024 ? (
                    <>
                      <div className="col-4">
                        <CountryInput
                          id={"mobilePhoneCode"}
                          label={"Country code"}
                          name={"mobilePhoneCode"}
                          errors={errors?.["mobilePhoneCode"]}
                          value={inputs?.mobilePhoneCode}
                          placeholder={"+263"}
                          onChange={(e) =>
                            handleChangePolicy(e, "mobilePhoneCode")
                          }
                          required={true}
                          country={true}
                        />
                      </div>
                      <div className="col-8">
                        <NumberInput
                          id={"mobilePhone"}
                          label={"Mobile number"}
                          name={"mobilePhone"}
                          type={"number"}
                          errors={errors?.["mobilePhone"]}
                          value={
                            Number(inputs?.mobilePhone) === 0 ||
                            inputs?.mobilePhone === undefined ||
                            inputs?.mobilePhone === null
                              ? ""
                              : Number(inputs?.mobilePhone).toString()
                          }
                          onChange={(e) => {
                            if (e?.target?.value?.length <= 12) {
                              handleChangePolicy(e);
                            }
                          }}
                          required={true}
                          mobile={true}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-12">
                        <CountryInput
                          id={"mobilePhoneCode"}
                          label={"Country code"}
                          name={"mobilePhoneCode"}
                          errors={errors?.["mobilePhoneCode"]}
                          value={inputs?.mobilePhoneCode}
                          placeholder={"+264"}
                          onChange={(e) =>
                            handleChangePolicy(e, "mobilePhoneCode")
                          }
                          required={true}
                          country={true}
                        />
                      </div>
                      <div className="col-12">
                        <NumberInput
                          id={"mobilePhone"}
                          label={"Mobile number"}
                          name={"mobilePhone"}
                          type={"number"}
                          errors={errors?.["mobilePhone"]}
                          value={
                            Number(inputs?.mobilePhone) === 0 ||
                            inputs?.mobilePhone === undefined ||
                            inputs?.mobilePhone === null
                              ? ""
                              : Number(inputs?.mobilePhone).toString()
                          }
                          onChange={(e) => {
                            if (e?.target?.value?.length <= 12) {
                              handleChangePolicy(e);
                            }
                          }}
                          required={true}
                          mobile={true}
                        />
                      </div>
                    </>
                  )}
                </div>

                <TextInput
                  id={"eMail"}
                  label={"Email address"}
                  name={"eMail"}
                  errors={errors?.["eMail"]}
                  value={inputs?.eMail}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />
                <div className="form-group mt-3">
                  <label className="primary-color f-700">
                    Physical address
                  </label>
                </div>
                <TextInput
                  id={"physicalAddress"}
                  label={"Address 1"}
                  errors={errors?.["physicalAddress"]}
                  name={"physicalAddress"}
                  value={inputs?.physicalAddress}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />
                <TextInput
                  id={"address2"}
                  label={"Address 2"}
                  errors={errors?.["address2"]}
                  name={"address2"}
                  value={inputs?.address2}
                  onChange={(e) => handleChangePolicy(e)}
                  required={false}
                />
                <TextInput
                  id={"city"}
                  label={"City"}
                  errors={errors?.["city"]}
                  name={"city"}
                  value={inputs?.city}
                  onChange={(e) => handleChangePolicy(e)}
                  required={true}
                />

                <div className="form-group mt-3">
                  <label className="form-label">Region*</label>
                  <ReactSelect
                    id={`region`}
                    placeholder="Select region"
                    value={inputs?.region}
                    styles={customStyles}
                    options={JSON.parse(
                      getAttributes("Region")?.settings
                    )?.items?.map((f) => ({ label: f, value: f }))}
                    onChange={(e) => {
                      handleChangePolicy({
                        target: { name: "region", value: e },
                      });
                    }}
                  />
                  {errors?.["region"] && (
                    <span className="text-danger errors">
                      {errors?.["region"] && errors?.["region"][0]}
                    </span>
                  )}
                </div>

                <div className="form-group mt-3">
                  <label className="primary-color f-700">Postal address</label>
                </div>
                <TextInput
                  id={"postalAddress"}
                  label={"PO Box/Private Bag"}
                  errors={errors?.["postalAddress"]}
                  name={"postalAddress"}
                  value={inputs?.postalAddress}
                  onChange={(e) => handleChangePolicy(e)}
                  required={false}
                />
                <TextInput
                  id={"postalTown"}
                  label={"Postal Town"}
                  errors={errors?.["postalTown"]}
                  name={"postalTown"}
                  value={inputs?.postalTown}
                  onChange={(e) => handleChangePolicy(e)}
                  required={false}
                />
                <TextInput
                  id={"postalCountry"}
                  label={"Country"}
                  errors={errors?.["postalCountry"]}
                  name={"postalCountry"}
                  value={inputs?.postalCountry}
                  onChange={(e) => handleChangePolicy(e)}
                  required={false}
                />
              </div>
            </AccordionDetails>
          </Accordion>
          {errors && errors["policy"] && (
            <span className="errors">{errors["policy"][0]}</span>
          )}

          <>
            {customAttributes?.coverOption === "Family" && (
              <>
                <div style={{ height: "20px" }} />
                <Accordion
                  disabled={
                    options?.value === 1 ||
                    customAttributes?.spouse_cover === 1 ||
                    over60Option?.value === "Individual"
                  }
                  expanded={spouseExpand}
                  defaultExpanded={
                    customAttributes?.spouse_cover === 1
                      ? false
                      : expand.spouseExpand
                  }
                  onChange={() => setSpouseExpand(!spouseExpand)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="d-flex flex-row">
                      <div
                        style={{
                          borderRadius: 99,
                          marginRight: 10,
                          backgroundColor: "white",
                          width: 30,
                          height: 30,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className="primary-color f-700"
                          id="accord-number"
                        >
                          2
                        </span>
                      </div>
                      <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                        Add spouse details here
                      </p>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className="p-0 mt-4">
                    <div
                      style={{
                        border: "1px solid #00000030",
                        padding: 20,
                        borderRadius: 5,
                      }}
                    >
                      {getSpouseAttributes()
                        ?.sort((a, b) => a.sequence < b.sequence)
                        ?.map((y) => renderComponents(y, "spouse"))}
                    </div>
                  </AccordionDetails>
                </Accordion>
                {errors && errors["spouse"] && (
                  <span className="errors">{errors["spouse"][0]}</span>
                )}
              </>
            )}
            {customAttributes?.child_cover === 0 &&
              (customAttributes?.coverOption === "Family" ||
                customAttributes?.coverOption === "Single Parent") && (
                <>
                  {" "}
                  <div style={{ height: "20px" }} />
                  <Accordion
                    disabled={
                      options?.value === 1 ||
                      customAttributes?.child_cover === 1 ||
                      over60Option?.value === "Individual"
                    }
                    defaultExpanded={
                      customAttributes?.child_cover === 1 ? false : childExpand
                    }
                    expanded={childExpand}
                    onChange={() => setChildExpand(!childExpand)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div className="d-flex flex-row">
                        <div
                          style={{
                            borderRadius: 99,
                            marginRight: 10,
                            backgroundColor: "white",
                            width: 30,
                            height: 30,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            className="primary-color f-700"
                            id="accord-number"
                          >
                            3
                          </span>
                        </div>
                        <p
                          className="f-700 p-0 m-0"
                          style={{ color: "#3C4096" }}
                        >
                          Add child details here
                        </p>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className="p-0 mt-4">
                      {getRows(customAttribues?.numberOfChildren).map(
                        (x, i) => (
                          <div
                            style={{
                              border: "1px solid #00000030",
                              marginTop: 20,
                              padding: 20,
                              borderRadius: 5,
                            }}
                          >
                            <p className="primary-color f-700">
                              Child #{i + 1}
                            </p>
                            {riskAttributes
                              .find((x) => x.code === "CHI")
                              ?.attributes?.filter(
                                (t) =>
                                  t.name !== "Child Option ID or Passport" &&
                                  t.name !== "Child ID Number or Passport no"
                              )
                              ?.map((y) => renderComponents(y, "child", i))}
                          </div>
                        )
                      )}
                    </AccordionDetails>
                  </Accordion>
                  {errors && errors["child"] && (
                    <span className="errors">{errors["child"][0]}</span>
                  )}
                </>
              )}
            <div style={{ height: "20px" }} />
            {getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.value === "YES" &&
              (customAttributes?.coverOption === "Just Me" ||
                customAttributes?.coverOption === "Family" ||
                customAttributes?.coverOption === "Single Parent") && (
                <>
                  <Accordion
                    expanded={
                      getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.value === "NO"
                        ? false
                        : extendedExpand
                    }
                    defaultExpanded={
                      getAttributes(ATTRIBUTES.EXTENDED_FAMILY)?.value === "YES"
                        ? false
                        : extendedExpand
                    }
                    onChange={() => setExtendedExpand(!extendedExpand)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel1a-header"
                    >
                      <div className="d-flex flex-row">
                        <div
                          style={{
                            borderRadius: 99,
                            marginRight: 10,
                            backgroundColor: "white",
                            width: 30,
                            height: 30,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span
                            className="primary-color f-700"
                            id="accord-number"
                          >
                            4
                          </span>
                        </div>
                        <p
                          className="f-700 p-0 m-0"
                          style={{ color: "#3C4096" }}
                        >
                          Add extended family details here
                        </p>
                      </div>
                    </AccordionSummary>
              
                    <AccordionDetails className="p-0 mt-4">
                      {getExtended()?.map((x, i) => (
                        <div
                          style={{
                            border: "1px solid #00000030",
                            marginTop: 20,
                            padding: 20,
                            borderRadius: 5,
                          }}
                        >
                          <p className="primary-color f-700">
                            Extended Family #{i + 1}
                          </p>
                          <div className="d-flex justify-content-between align-items-center flex-row">
                            <p className="primary-color f-700">Cover amount</p>
                            <p className="primary-color f-700">
                              {extendedFamilies?.[i]?.cover}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center flex-row">
                            <p className="primary-color f-700">Date of birth</p>
                            <p className="primary-color f-700">
                              {moment(
                                new Date(extendedFamilies?.[i]?.dob)
                              ).format("DD-MM-YYYY")}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center flex-row">
                            <p className="primary-color f-700">Premium</p>
                            <p className="primary-color f-700">
                              N$
                              {parseFloat(
                                executeCalculatorResponse?.productOptions[0]
                                  ?.risks?.[i + 1]?.rating?.total
                              )
                                ?.toFixed(2)
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </p>
                          </div>
                          {riskAttributes
                            .find((x) => x.code === "EXF")
                            ?.attributes?.filter(
                              (t) =>
                                t.name !== "Extended Family Cover Amount" &&
                                t.name !== "Extended Family DOB" &&
                                t.name !==
                                  "Extended Family Country ID was issued" &&
                                t.name !== "Extended Family ID Number"
                            )
                            ?.map((y) => renderComponents(y, "extended", i, x))}
                        </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                  {errors && errors["extended"] && (
                    <span className="errors">{errors["extended"][0]}</span>
                  )}
                  <div style={{ height: "20px" }} />
                </>
              )}
          </>

          {customAttributes?.bene_cover === 1 && (
            <>
              <Accordion
                defaultExpanded={beneExpand}
                expanded={beneExpand}
                onChange={() => setBeneExpand(!beneExpand)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="pane41a-content"
                  id="panel1a-header"
                >
                  <div className="d-flex flex-row">
                    <div
                      style={{
                        borderRadius: 99,
                        marginRight: 10,
                        backgroundColor: "white",
                        width: 30,
                        height: 30,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span className="primary-color f-700" id="accord-number">
                        {moment().diff(
                          dateOfBirth?.value?.replaceAll("/", "-"),
                          "years"
                        ) < 60
                          ? "6"
                          : "5"}
                      </span>
                    </div>
                    <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                      Add beneficiaries details here
                    </p>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="p-0 mt-4">
                  {getRows(beneCount)?.map((x, i) => (
                    <div
                      style={{
                        border: "1px solid #00000030",
                        marginTop: 20,
                        padding: 20,
                        borderRadius: 5,
                      }}
                    >
                      <p className="primary-color f-700">
                        Beneficiary #{i + 1}
                      </p>
                      <div className="form-group mt-3">
                        <label className="form-label">Title*</label>
                        <ReactSelect
                          id={`title`}
                          placeholder="title"
                          styles={customStyles}
                          value={
                            beneficiaries[i]?.["title"]?.value
                              ? beneficiaries[i]?.["title"]?.value
                              : {
                                  label: beneficiaries[i]?.["title"],
                                  value: beneficiaries[i]?.["title"],
                                }
                          }
                          options={[
                            {
                              label: "Adv",
                              value: "Adv",
                            },
                            {
                              label: "Dr",
                              value: "Dr",
                            },
                            {
                              label: "Miss",
                              value: "Miss",
                            },
                            {
                              label: "Mr",
                              value: "Mr",
                            },
                            {
                              label: "Mrs",
                              value: "Mrs",
                            },
                            {
                              label: "Ms",
                              value: "Ms",
                            },
                            {
                              label: "Prof",
                              value: "Prof",
                            },
                            {
                              label: "Rev",
                              value: "Rev",
                            },
                            {
                              label: "Sir",
                              value: "Sir",
                            },
                            {
                              label: "NONE",
                              value: "NONE",
                            },
                          ]}
                          onChange={(e) => {
                            handleChangeBene(
                              {
                                target: { name: "title", value: e.value },
                              },
                              i
                            );
                          }}
                        />
                        {errors[`Beneficiaries Title ${i + 1}`] && (
                          <span className="text-danger errors">
                            {errors[`Beneficiaries Title ${i + 1}`] &&
                              errors[`Beneficiaries Title ${i + 1}`][0]}
                          </span>
                        )}
                      </div>
                      <TextInput
                        id={`First Name ${i + 1}`}
                        label={"First name"}
                        name={"firstName"}
                        placeholder={""}
                        value={beneficiaries[i]?.["firstName"]}
                        errors={errors?.[`Beneficiary First Name ${i + 1}`]}
                        onChange={(e) => {
                          if (e.target?.value?.length <= 30) {
                            handleChangeBene(e, i);
                          }
                        }}
                        required={true}
                      />
                      <TextInput
                        id={`Last Name ${i + 1}`}
                        label={"Last name"}
                        name={"lastName"}
                        placeholder={""}
                        value={beneficiaries[i]?.["lastName"]}
                        errors={errors[`Beneficiary Last Name ${i + 1}`]}
                        onChange={(e) => handleChangeBene(e, i)}
                        required={true}
                      />

                      <div style={{ marginTop: 20 }} />
                      <TwoButtons
                        id={`Gender ${i + 1}`}
                        title={"Gender"}
                        isField={true}
                        half={true}
                        required={true}
                        errors={errors?.[`Gender ${i + 1}`]}
                        state={beneficiaries[i]?.["gender"]}
                        onClick={(e) =>
                          handleChangeBene(
                            { target: { name: "gender", value: e } },
                            i
                          )
                        }
                        options={[
                          {
                            name: "Male",
                            value: "Male",
                          },
                          {
                            name: "Female",
                            value: "Female",
                          },
                        ]}
                      />
                      <DateInput
                        id={`dob ${i + 1}`}
                        label={"Date of birth"}
                        name={"dob"}
                        //minDate={calculateBeneMinDate()}
                        errors={errors[`Beneficiary Date of Birth ${i + 1}`]}
                        maxDate={calculateBeneMaxDate()}
                        value={beneficiaries[i]?.["dob"]}
                        onChange={(e) => handleChangeBene(e, i)}
                        required={false}
                      />
                      <TextInput
                        id={`Email ${i + 1}`}
                        label={"Email address"}
                        name={"email"}
                        value={beneficiaries[i]?.["email"]}
                        errors={errors[`Email ${i + 1}`]}
                        onChange={(e) => handleChangeBene(e, i)}
                        required={false}
                      />
                      <div className="form-group mt-3">
                        <label className="form-label">
                          Relationship with policy holder
                        </label>
                        <ReactSelect
                          styles={customStyles}
                          options={[
                            {
                              label: "Self",
                              value: "Self",
                            },
                            {
                              label: "Other",
                              value: "Other",
                            },
                            {
                              label: "Husband",
                              value: "Husband",
                            },
                            {
                              label: "Son",
                              value: "Son",
                            },
                            {
                              label: "Father",
                              value: "Father",
                            },
                            {
                              label: "Brother",
                              value: "Brother",
                            },
                            {
                              label: "Wife",
                              value: "Wife",
                            },
                            {
                              label: "Daughter",
                              value: "Daughter",
                            },
                            {
                              label: "Mother",
                              value: "Mother",
                            },
                            {
                              label: "Sister",
                              value: "Sister",
                            },
                            {
                              label: "None",
                              value: "None",
                            },

                            {
                              label: "Father-in-law",
                              value: "FatherInLaw",
                            },
                            {
                              label: "Mother-in-law",
                              value: "MotherInLaw",
                            },

                            {
                              label: "Aunt",
                              value: "Aunt",
                            },
                            {
                              label: "Grandfather",
                              value: "GrandFather",
                            },
                            {
                              label: "Grandmother",
                              value: "GrandMother",
                            },

                            {
                              label: "Sister-in-law",
                              value: "SisterInLaw",
                            },
                            {
                              label: "Spouse",
                              value: "Spouse",
                            },
                            {
                              label: "Uncle",
                              value: "Uncle",
                            },

                            {
                              label: "Nephew",
                              value: "Nephew",
                            },
                            {
                              label: "Niece",
                              value: "Niece",
                            },
                            {
                              label: "Cousin",
                              value: "Cousin",
                            },
                            {
                              label: "Trustee",
                              value: "Trustee",
                            },
                            {
                              label: "Step-son",
                              value: "StepSon",
                            },
                            {
                              label: "Step-daughter",
                              value: "StepDaughter",
                            },
                          ]}
                          value={
                            beneficiaries[i]?.["relationship"]
                              ? {
                                  label: beneficiaries[i]?.["relationship"],
                                  value: beneficiaries[i]?.["relationship"],
                                }
                              : {}
                          }
                          onChange={(e) =>
                            handleChangeBene(
                              {
                                target: {
                                  name: "relationship",
                                  value: e.value,
                                },
                              },
                              i
                            )
                          }
                        />
                      </div>
                      <div className="form-group mt-3">
                        <label className="form-label">
                          Percentage of benefit*
                        </label>
                        <ReactSelect
                          id={`Percentage ${i + 1}`}
                          styles={customStyles}
                          placeholder="0"
                          defaultValue={[
                            ...percentageArr.map((x) => ({
                              label: `${x.name}`,
                              value: x.value,
                            })),
                            ...range(0, 100).map((x, i) => ({
                              label: `${x}%`,
                              value: x,
                            })),
                          ].find(
                            (z) => z.value === beneficiaries[i]?.["percentage"]
                          )}
                          value={[
                            ...percentageArr.map((x) => ({
                              label: `${x.name}`,
                              value: x.value,
                            })),
                            ...range(0, 100).map((x, i) => ({
                              label: `${x}%`,
                              value: x,
                            })),
                          ].find(
                            (z) => z.value === beneficiaries[i]?.["percentage"]
                          )}
                          options={
                            isTyped
                              ? range(0, 100).map((x, i) => ({
                                  label: `${x}%`,
                                  value: x,
                                }))
                              : percentageArr.map((x) => ({
                                  label: `${x.name}`,
                                  value: x.value,
                                }))
                          }
                          onInputChange={(e) => {
                            if (e > 0) {
                              setIsTyped(true);
                            } else {
                              setIsTyped(false);
                            }
                          }}
                          onChange={(e) => {
                            handleChangeBene(
                              {
                                target: { name: "percentage", value: e.value },
                              },
                              i
                            );
                          }}
                          isSearchable={true}
                        />
                      </div>
                      {(
                        errors[`Percentage ${i + 1}`] ||
                        errors[`beneficiariesSplit`]
                      )?.length && (
                        <span
                          className="text-danger errors"
                          style={{ marginLeft: 0 }}
                        >
                          {errors[`Percentage ${i + 1}`] ||
                            (errors[`beneficiariesSplit`] &&
                              errors[`Percentage ${i + 1}`]) ||
                            errors[`beneficiariesSplit`][0]}
                        </span>
                      )}
                      {beneficiaries[i]?.["dob"] &&
                        calculateage(beneficiaries[i]?.["dob"], i) && (
                          <div
                            style={{
                              border: "1px solid #00000030",
                              marginTop: 20,
                              padding: 20,
                              borderRadius: 5,
                            }}
                          >
                            <p className="primary-color f-700">Guardian</p>
                            <TextInput
                              id={`Guardian First Name ${i + 1}`}
                              label={"First name"}
                              // errors={[]}
                              name={"guardianName"}
                              value={beneficiaries[i]?.["guardianName"]}
                              errors={errors[`Guardian First Name ${i + 1}`]}
                              onChange={(e) => {
                                if (e.target?.value?.length <= 30) {
                                  handleChangeBene(e, i);
                                }
                              }}
                              required={true}
                            />
                            <TextInput
                              id={`Guardian Last Name ${i + 1}`}
                              label={"Last name"}
                              errors={errors[`Guardian Last Name ${i + 1}`]}
                              name={"guardianLastName"}
                              value={beneficiaries[i]?.["guardianLastName"]}
                              onChange={(e) => {
                                if (e.target?.value?.length <= 30) {
                                  handleChangeBene(e, i);
                                }
                              }}
                              required={true}
                            />
                            <div className="row">
                              {width > 1024 ? (
                                <>
                                  <div className="col-4">
                                    <CountryInput
                                      id={`Guardian Phone Number Code ${i + 1}`}
                                      label={"Country code*"}
                                      width={"110%"}
                                      name={"Guardian Phone Number Code"}
                                      errors={
                                        errors?.[
                                          `Guardian Phone Number Code ${i + 1}`
                                        ]
                                      }
                                      value={
                                        beneficiaries[i]?.[
                                          "Guardian Phone Number Code"
                                        ]
                                      }
                                      placeholder={"+264"}
                                      onChange={(e) =>
                                        handleChangeBene(
                                          {
                                            target: {
                                              name: "Guardian Phone Number Code",
                                              value: e,
                                            },
                                          },
                                          i
                                        )
                                      }
                                      required={false}
                                      country={true}
                                    />
                                  </div>
                                  <div className="col-8">
                                    <NumberInput
                                      id={`Guardian Phone Number ${i + 1}`}
                                      label={"Mobile number"}
                                      name={"guardianNumber"}
                                      type={"number"}
                                      placeholder={""}
                                      value={
                                        beneficiaries[i]?.["guardianNumber"]
                                      }
                                      errors={
                                        errors[`Guardian Phone Number ${i + 1}`]
                                      }
                                      onChange={(e) => {
                                        if (e?.target?.value?.length <= 12) {
                                          handleChangeBene(e, i);
                                        }
                                      }}
                                      required={true}
                                      mobile={true}
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="col-11">
                                    <CountryInput
                                      id={`Guardian Phone Number Code ${i + 1}`}
                                      label={"Country code*"}
                                      width={"100%"}
                                      name={"Guardian Phone Number Code"}
                                      errors={
                                        errors?.[
                                          `Guardian Phone Number Code ${i + 1}`
                                        ]
                                      }
                                      value={
                                        beneficiaries[i]?.[
                                          "Guardian Phone Number Code"
                                        ]
                                      }
                                      placeholder={"+264"}
                                      onChange={(e) =>
                                        handleChangeBene(
                                          {
                                            target: {
                                              name: "Guardian Phone Number Code",
                                              value: e,
                                            },
                                          },
                                          i
                                        )
                                      }
                                      required={false}
                                      country={true}
                                    />
                                  </div>
                                  <div className="col-12">
                                    <NumberInput
                                      id={`Guardian Phone Number ${i + 1}`}
                                      label={"Mobile number"}
                                      name={"guardianNumber"}
                                      type={"number"}
                                      placeholder={""}
                                      value={
                                        beneficiaries[i]?.["guardianNumber"]
                                      }
                                      errors={
                                        errors[`Guardian Phone Number ${i + 1}`]
                                      }
                                      onChange={(e) => {
                                        if (e?.target?.value?.length <= 12) {
                                          handleChangeBene(e, i);
                                        }
                                      }}
                                      required={true}
                                      mobile={true}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                            <DateInput
                              id={`Guardian dob ${i + 1}`}
                              label={"Date of birth"}
                              name={"guardianDob"}
                              minDate={calculateMinDate("guardian")}
                              errors={errors[`Guardian dob ${i + 1}`]}
                              maxDate={calculateMaxDate("guardian")}
                              value={beneficiaries[i]?.["guardianDob"]}
                              onChange={(e) => handleChangeBene(e, i)}
                              required={true}
                            />
                          </div>
                        )}
                      <div className="d-flex justify-content-end w-100 mt-4">
                        {beneCount > 1 && (
                          <button
                            className="delete-button"
                            onClick={() => {
                              if (beneCount > 1) {
                                var arr = [...beneficiaries];
                                arr.splice(i, 1);
                                setBeneficiaries(arr);
                                dispatch(
                                  updateCustomAttributes({
                                    type: "BENEFICIARIES",
                                    payload: arr,
                                  })
                                );
                                setBeneCount(beneCount - 1);
                                dispatch(
                                  updateCustomAttributes({
                                    type: "CUSTOM_ATTRIBUTES",
                                    payload: {
                                      ...customAttribues,
                                      beneCount: beneCount - 1,
                                    },
                                  })
                                );
                              }
                            }}
                          >
                            Delete
                          </button>
                        )}
                        {getRows(beneCount)?.length - 1 === i && (
                          <button
                            className="add-button"
                            onClick={() => {
                              if (beneficiaries?.length <= 9) {
                                var arr = [...beneficiaries];
                                arr.push({
                                  "Phone Number Code": "+264",
                                  "Telephone Number Code": "+264",
                                });
                                setBeneficiaries(arr);
                                dispatch(
                                  updateCustomAttributes({
                                    type: "BENEFICIARIES",
                                    payload: arr,
                                  })
                                );
                                dispatch(
                                  updateCustomAttributes({
                                    type: "CUSTOM_ATTRIBUTES",
                                    payload: {
                                      ...customAttribues,
                                      beneCount: beneCount + 1,
                                    },
                                  })
                                );
                                setBeneCount(beneCount + 1);
                              }
                            }}
                          >
                            Add Beneficiaries
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            </>
          )}
          {errors && errors["beneficiaries"] && (
            <span className="errors">{errors["beneficiaries"][0]}</span>
          )}
        </div>
      </div>

      <div style={{ height: "50px" }} />

      <div className={style.step_1_price_summary}>
        <div className={style.step_1_price_summary_sub}>
          <h5 className="text-white f-700">Total Monthly Premium </h5>
          <h5 className="text-white f-700">{getPremium()}</h5>
        </div>
      </div>

      <NavigationButtons
        back={handleBackTrack}
        loading={isLoading}
        isBackDisabled={isLoading}
        next={() => {
          if (isLoading) {
            return;
          }
          handleSubmit();
        }}
      />
    </div>
  );
};

const schema = {
  firstName: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[A-Za-z]+$/,
      message: "^ Only alphabetic characters are allowed",
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[A-Za-z]+$/,
      message: "^ Only alphabetic characters are allowed",
    },
  },
  nationalityId: {
    presence: {
      allowEmpty: false,
    },
    format: {
      pattern: /^[\w-_.]*$/,
      message: "^ Only alphabets & numbers are allowded",
    },
  },
  dob: {
    presence: {
      allowEmpty: false,
    },
  },
  nationality: {
    presence: {
      allowEmpty: false,
    },
  },
  maritalStatus: {
    presence: {
      allowEmpty: false,
    },
  },
  mobilePhone: {
    presence: {
      allowEmpty: false,
      message: "^ Mobile number can't be blank",
    },
    format: {
      pattern: /^(\+\d{1,3}[- ]?)?\d{6,12}$/,
      message: "^ Invalid mobile number",
    },
  },
  mobilePhoneCode: {
    presence: {
      allowEmpty: false,
    },
    length: {
      maximum: 4,
    },
  },
  physicalAddress: {
    presence: {
      allowEmpty: false,
    },
  },
  city: {
    presence: {
      allowEmpty: false,
    },
  },
  region: {
    presence: {
      allowEmpty: false,
    },
  },
  mainGender: {
    presence: {
      allowEmpty: false,
    },
  },
};

const percentageArr = [
  {
    name: "10%",
    value: 10,
  },
  {
    name: "20%",
    value: 20,
  },
  {
    name: "30%",
    value: 30,
  },
  {
    name: "40%",
    value: 40,
  },
  {
    name: "50%",
    value: 50,
  },
  {
    name: "60%",
    value: 60,
  },
  {
    name: "70%",
    value: 70,
  },
  {
    name: "80%",
    value: 80,
  },
  {
    name: "90%",
    value: 90,
  },
  {
    name: "100%",
    value: 100,
  },
];

export default Step4;

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderColor: "#00000080",
  }),
}
