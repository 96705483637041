import logo from "./logo.svg";
import "./App.css";
import { Route, useLocation, useNavigate } from "react-router-dom";
import { Routes } from "react-router";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "./screens/Forms/Forms";
import Homepage from "./screens/Homepage/Home";
import "./components/common.css";
import PaymentSuccess from "./screens/Forms/Steps/PaymentSuccess";
import { useEffect, useState } from "react";
import useWindowDimensions from "./utils/useWindowDimension";
import { useDispatch, useSelector } from "react-redux";
import { getAgentDetails, login, storeSellingCode, updateLoading } from "./store/actions/auth";
import { clearRiskState, clearState } from "./store/actions/products";
import Loader from "./components/Loader";
import Errorpage from "./screens/Forms/Steps/Errorpage";
import { parseQuery, setParam } from "./utils/utils";

console.log = () => {};
console.error = () => {};
console.warn = () => {};

function App() {
  const { width } = useWindowDimensions();

  const dispatch = useDispatch();
  const companyDetails = useSelector((state) => state.auth.companyDetails);
  const location = useLocation();
  const history = useNavigate();
  const currentToken = useSelector((state) => state.auth.currentUserToken);
  const riskAttributes = useSelector((state) => state.products.riskAttributes);
  const [ishomeLoading, setIsHomeLoading] = useState(true);
  const agentCode = useSelector((state) => state.auth.agentCode);

  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    if (location.pathname !== "/payment-success") {
      setParam(history, currentToken, dispatch);
    }
  }, [currentToken]);


  useEffect(() => {
    if(parseQuery(location.search).token){
      dispatch(storeSellingCode(parseQuery(location.search).token))
    }
  },[location?.search])

  
  useEffect(() => {
    if (
      location.pathname !== "/forms"
    ) {
      dispatch(clearRiskState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Loader state form
  const isLoading = useSelector((state) => state.auth.loading);

  return (
    <Routes>
        <Route path="/forms" element={<Form />} />
        <Route path="/" element={<Homepage isHomeLoading={ishomeLoading} />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-failure" element={<Errorpage />} />

      </Routes>
  );
}

export default App;
